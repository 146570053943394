.widget_title {
  margin-bottom: 25px;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.3px;
}
.widget_categories li,
.widget_archive li {
  padding-bottom: 10px;
  list-style: none;
}
.widget_categories li a,
.widget_archive li a {
  position: relative;
}
.widget_categories li a::before,
.widget_archive li a:before {
  content: "\e649";
  font-family: "themify";
  height: auto;
  line-height: normal;
  margin-right: 10px;
  font-size: 10px;
}
.widget_categories li:last-child,
.widget_archive li:last-child {
  padding-bottom: 0;
}
.widget_categories .categories_num,
.widget_archive .archive_num {
  float: right;
  font-size: 14px;
}
.sidebar .widget + .widget {
  border-top: 1px solid #ddd;
  margin-top: 30px;
  padding-top: 30px;
}
.widget_newsletter p {
  font-size: 14px;
  line-height: normal;
}
.widget_newsletter button {
  padding: 5px 12px;
}
.widget_newsletter button i,
.widget_newsletter .btn i {
  font-size: 18px;
}
.widget_recent_post li,
.widget_course li {
  list-style: none;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;
}
.widget_course li {
  display: table;
}
.post_footer {
  display: inline-block;
  vertical-align: top;
}
.widget_recent_post li:last-child,
.widget_course li:last-child {
  margin-bottom: 0px;
}
footer .widget_recent_post .post_img img {
  border: 0;
}
.post_img,
.widget_course li .course_img {
  float: left;
  margin-right: 10px;
}
.post_img img {
  width: 80px;
}
.post_content,
.widget_course li .course_content {
  overflow: hidden;
}

.post_content h6 {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
}
.post_content .product_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  margin-bottom: 5px;
}
.post_content .product_price {
  margin-bottom: 0px;
}
.tags a {
  background-color: #f7f7f7;
  font-size: 14px;
  padding: 8px 15px;
  display: inline-block;
  margin-bottom: 5px;
  color: #333;
}
.tags a:hover {
  background-color: #ff324d;
  color: #fff;
}
.tags_style1 a {
  border-radius: 40px;
}
.widget_tweet_feed li {
  list-style: none;
  position: relative;
  font-size: 14px;
  padding-bottom: 15px;
  padding-left: 25px;
}
.widget_tweet_feed li::before {
  content: "\e74b";
  font-family: "themify";
  position: absolute;
  left: 0;
  top: 0;
  color: #292b2c;
}
.widget_tweet_feed a {
  color: #ff324d;
}
.widget_tweet_feed li:last-child {
  padding-bottom: 0px;
}
.widget_instafeed {
  margin: -2px;
  display: table;
}
.widget_instafeed li {
  list-style: none;
  float: left;
  width: 33.33%;
  padding: 2px;
  position: relative;
}
.instafeed_col4 li {
  width: 25%;
}
.widget_instafeed li a {
  display: block;
  position: relative;
  overflow: hidden;
}
.widget_instafeed li a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.5s ease-in-out;
  z-index: 1;
}
.widget_instafeed img {
  width: 100%;
}
.insta_icon {
  color: #fff;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.5s ease-in-out;
  z-index: 2;
}
.widget_instafeed li:hover a::before,
.widget_instafeed li:hover .insta_icon {
  opacity: 1;
}
.insta_img {
  position: relative;
}
.instagram_icon {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  -moz-transform: translateY(-50%) rotate(30deg) scale(1.8);
  -webkit-transform: translateY(-50%) rotate(30deg) scale(1.8);
  transform: translateY(-50%) rotate(30deg) scale(1.8);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.instagram_icon i {
  color: #fff;
  font-size: 50px;
}
.insta_img::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.insta_img:hover:before {
  opacity: 1;
  visibility: visible;
}
.insta_img:hover .instagram_icon {
  opacity: 1;
  visibility: visible;
  -moz-transform: translateY(-50%) rotate(0deg) scale(1);
  -webkit-transform: translateY(-50%) rotate(0deg) scale(1);
  transform: translateY(-50%) rotate(0deg) scale(1);
}
.follow_box {
  position: absolute;
  top: 50%;
  z-index: 9;
  text-align: center;
  right: 0;
  left: 0;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 01);
  margin: 0 auto;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 25px;
  border-radius: 80px;
}
.follow_box i {
  position: absolute;
  font-size: 80px;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0.1;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.instafeed_box {
  overflow: hidden;
}
.instafeed_box a {
  position: relative;
  display: block;
}
.instafeed_box a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  display: block;
  transition: all 0.5s ease-in-out;
}
.instafeed_box a:hover:before {
  opacity: 1;
}
.instafeed_box a img {
  transition: all 0.5s ease-in-out;
}
.instafeed_box a:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.widget-action {
  padding-top: 10px;
}
.filter-widget {
  & > ul {
    height: 170px;
    overflow: hidden;
    transition: height 0.2s ease-out;
  }
  &.open {
    & > ul {
      //   height: initial;
    }
  }
}

.static-dropdown-menu {
  position: static !important;
  float: none;
  padding: 0;
  margin: 0;
  border-left: none;
  border-right: none;
  .dropdown-item {
    background-color: rgba(0, 0, 0, 0.05);
    white-space: normal;
    font-size: 14px;
    &::before {
      font-size: 8px;
    }
    &:hover,
    &:focus {
      background-color: $blue_c;
      color: #fff;
    }
  }
}

.widget_categories {
  border: 1px solid rgba(0, 0, 0, 0.15);
  .dropdown.show {
    .dropdown-toggler{
      background-color: $blue_c;
      color: #fff;
    }
  }
}
