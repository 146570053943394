.accordion .card .card-header {
  background-color: transparent;
  padding: 0px;
  margin: 0;
}
.accordion .card-header a {
  padding: 15px 40px 15px 15px;
  display: block;
  line-height: normal;
}
.accordion .card-body p:last-child {
  margin: 0;
}
.card-body p {
  margin-bottom: 15px;
}
.accordion_style1.accordion .card {
  background-color: transparent;
  margin-bottom: 15px;
  border-radius: 0;
  border: 0;
}
.accordion_style1.accordion .card:last-child {
  margin-bottom: 0;
}
.accordion_style1 .card-body {
  padding: 15px 0 10px 0;
}
.accordion.accordion_style1 .card-header a {
  padding-left: 0;
  padding-top: 0;
  font-weight: 600;
}
.accordion_style1 .card-header a::after {
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  right: 15px;
  top: 0px;
  font-weight: 900;
  content: "\f067";
}
.accordion_style1 .card-header a[aria-expanded="false"]::after {
  content: "\f068";
}
