/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.2
*/

.owl-theme .owl-controls {
  display: none !important;
  margin-top: 10px;
  text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div {
  color: #fff;
  display: inline-block;
  zoom: 1;
  *display: inline; /*IE7 life-saver */
  margin: 5px;
  padding: 3px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #869791;
  filter: Alpha(Opacity=50); /*IE7 fix*/
  opacity: 0.5;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  filter: Alpha(Opacity=100); /*IE7 fix*/
  opacity: 1;
  text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  *display: inline; /*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span {
  display: none;
  width: 6px;
  height: 6px;
  margin: 5px 7px;
  filter: Alpha(Opacity=50); /*IE7 fix*/
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #869791;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  filter: Alpha(Opacity=100); /*IE7 fix*/
  opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers {
  height: auto;
  width: auto;
  color: #fff;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/* preloading images */
.owl-item.loading {
  min-height: 150px;
  background: url(AjaxLoader.gif) no-repeat center center;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav {
  margin-top: 10px;
}
.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.owl-theme .owl-nav {
  margin-top: 0;
}
body .owl-theme .owl-nav [class*="owl-"] {
  background-color: #fff;
  color: #222;
  font-size: 26px;
  line-height: 50px;
  transition: all 0.3s ease-in-out;
  height: 50px;
  padding: 0;
  margin: 0;
  width: 50px;
  text-align: center;
}
.owl-theme .owl-nav [class*="owl-"] i {
  line-height: normal;
}
.owl-theme .owl-dots {
  font-size: 0;
  margin-top: 15px;
}
.owl-theme .owl-dots .owl-dot span {
  background-color: transparent;
  border: 2px solid $red_c;
  cursor: pointer;
  margin: 3px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: $red_c;
}
.dot_style1.owl-theme .owl-dots .owl-dot.active span {
  width: 30px;
}
.nav_style1.owl-theme .owl-nav [class*="owl-"],
.nav_style5.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkittransform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 0;
  width: 30px;
}
.nav_style1.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: #fff;
  color: #555;
}
.nav_style1.owl-theme .owl-nav .owl-prev {
  left: -50px;
}
.nav_style1.owl-theme .owl-nav .owl-next {
  right: -50px;
}
.nav_style2.owl-theme .owl-nav [class*="owl-"] {
  background-color: transparent;
  position: absolute;
  bottom: 10px;
}
.nav_style2.owl-theme .owl-nav [class*="owl-"]:hover,
.nav_style3.owl-theme .owl-nav [class*="owl-"]:hover,
.nav_style4.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #555;
}
.nav_style2.owl-theme .owl-nav .owl-prev,
.nav_style5.owl-theme .owl-nav .owl-prev {
  left: 0px;
}
.nav_style2.owl-theme .owl-nav .owl-next,
.nav_style5.owl-theme .owl-nav .owl-next {
  right: 0px;
}
.nav_style3.owl-theme .owl-nav [class*="owl-"],
.nav_style4.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  top: -90px;
  border-radius: 0;
  right: 0;
  height: auto;
  width: auto;
  background-color: transparent;
}
.nav_style3.owl-theme .owl-nav .owl-prev {
  right: 40px;
}
.nav_style4.owl-theme .owl-nav .owl-prev {
  left: 0px;
  right: auto;
}
.nav_style4.owl-theme .owl-nav .owl-prev i::before {
  content: "\ec43";
  font-family: "Linearicons";
}
.nav_style4.owl-theme .owl-nav .owl-next i::before {
  content: "\ec44";
  font-family: "Linearicons";
}
.nav_style5.owl-theme .owl-nav .owl-prev,
.nav_style5.owl-theme .owl-nav .owl-next {
  opacity: 0;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.nav_style5.owl-theme .owl-nav .owl-prev:hover,
.nav_style5.owl-theme .owl-nav .owl-next:hover {
  background-color: $red_c;
  color: #fff;
}
.nav_style5.owl-theme:hover .owl-nav .owl-prev {
  left: 20px;
  opacity: 1;
}
.nav_style5.owl-theme:hover .owl-nav .owl-next {
  right: 20px;
  opacity: 1;
}
.dot_white.owl-theme .owl-dots .owl-dot span {
  border-color: #fff;
}
.dot_white.owl-theme .owl-dots .owl-dot.active span,
.dot_white.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #fff;
}
