.banner_section {
	position: relative;
}
.banner_content_wrap {
	position: relative;
	z-index: 1;
}
.banner_half_content {
	padding: 100px 0 80px; 
	z-index: 0;
}
.full_screen.banner_half_content {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
}
.banner_section:not(.full_screen), 
.banner_section:not(.full_screen) .carousel-item, 
.banner_section:not(.full_screen) .banner_content_wrap, 
.banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
	height: 800px;
}
.full_screen, .full_screen .carousel-item {
	height: 100vh;
	min-height: 45rem;
}
.banner_section.slide_medium, 
.banner_section.slide_medium .carousel-item, 
.banner_section.slide_medium .banner_content_wrap, 
.banner_section.slide_medium .banner_content_wrap .carousel-item,
.banner_section.shop_el_slider, 
.banner_section.shop_el_slider .carousel-item, 
.banner_section.shop_el_slider .banner_content_wrap, 
.banner_section.shop_el_slider .banner_content_wrap .carousel-item {
	height: 500px;
}
.banner_section.slide_wrap, 
.banner_section.slide_wrap .carousel-item, 
.banner_section.slide_wrap .banner_content_wrap, 
.banner_section.slide_wrap .banner_content_wrap .carousel-item {
	height: 650px;
}
.banner_section .banner_slide_content {
	left: 0;
	position: absolute;
	right: 0;
	top: 50%;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 1;
}
.carousel-item img {
	position: relative;
	z-index: -1;
	width: 100%;
}
.carousel-control-next, .carousel-control-prev {
	background-color: rgba(0,0,0,0.5);
	font-size: 18px;
	height: 50px;
	opacity: 0;
	top: 50%;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 50px;
	transition: all 0.3s ease-in-out;
}
.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
	opacity: 0;
}
.carousel:hover .carousel-control-next, .carousel:hover .carousel-control-prev {
	opacity: 1;
}
.carousel-control-next {
	right: 20px;
}
.carousel-control-prev {
	left: 20px;
}
.carousel_style1 .carousel-control-next, .carousel_style1 .carousel-control-prev {
	border-radius: 100%;
}
.carousel_style2 .carousel-control-next, .carousel_style2 .carousel-control-prev {
	font-size: 16px;
	border-radius: 4px;
	height: 40px;
	width: 40px;
}
.light_arrow .carousel-control-next, .light_arrow .carousel-control-prev {
	background-color:#fff;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
	color: #000;
}
.carousel-control-next:hover, .carousel-control-prev:hover,
.light_arrow .carousel-control-next:hover, .light_arrow .carousel-control-prev:hover{
	background-color:$red_c;
	color: #fff;
}
.indicators_style1,
.indicators_style2,
.indicators_style3 {
	margin-bottom: 15px;
}
.indicators_style1 li {
	width: 10px;
	height: 10px;
	background-color: #333;
	border: 0;
	margin: 8px;
	position: relative;
}
.indicators_style1 li::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	border: 2px solid #333;
	margin: -4px;
}
.indicators_style2 li {
	background-color: #333;
	border-radius: 100%;
	width: 10px;
	height: 10px;
	background-clip: inherit;
	border: 0;
	margin: 8px;
	position: relative;
}
.indicators_style2 li::before {
	content: "";
	position: absolute;
	border-radius: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	border: 2px solid #333;
	margin: -4px;
}
.indicators_style3 li {
	background-color: #333;
	border-radius: 100%;
	width: 10px;
	height: 10px;
	background-clip: inherit;
	border: 0;
	margin: 5px;
	position: relative;
}
.indicators_style1 li.active,
.indicators_style2 li.active {
	background-color:$red_c;
}
.indicators_style2 li.active:before,
.indicators_style1 li.active:before {
	border-color:$red_c;
}
.indicators_style4 li {
	background-color: #fff;
	border-radius: 100%;
	width: 15px;
	height: 15px;
	background-clip: inherit;
	border: 0;
	margin: 5px;
	position: relative;
}
.banner_content .carousel-item {
	padding: 100px 0;
}
.banner_content h2 {
	font-size: 60px;
	margin-bottom: 20px;
	font-weight: 600;
	text-transform: capitalize;
}
.banner_content p,
.banner_content2 p {
	margin-bottom: 30px;
}
.banner_content2 h2 {
	font-size: 70px;
	margin-bottom: 20px;
	font-weight: 600;
	text-transform: capitalize;
}
.banner_content3 h2 {
	font-size: 40px;
	margin-bottom: 20px;
	font-weight: 600;
	text-transform: capitalize;
}
.banner_shape {
	position: absolute;
	right: 0;
	top: 0;
	width: 70%;
	z-index: -1;
}
.banner_shape img {
	width: 100%;
}
.wave_shape_bottom::before {
	background-image: url("../images/wave_shape.png");
	display: block;
	height: 100%;
	content: "";
	background-position: bottom center;
	position: absolute;
	bottom: 0;
	background-size: contain;
	background-repeat: no-repeat;
	left: 0;
	right: 0;
}
.banner_content1 h2 {
	font-size: 78px;
	margin-bottom: 20px;
	font-weight: 600;
	text-transform: capitalize;
}
.background_shape {
	position: absolute;
	width: 200px;
	height: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 35%;
	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 100%);
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#1affffff',GradientType=0 );
	-moz-transform: skewX(30deg);
	-webkit-transform: skewX(30deg);
	transform: skewX(30deg);
	transform-origin: left bottom;
	pointer-events: none;
}
.background_shape:before {
	content: "";
	position: absolute;
	width: 200px;
	height: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 100%;
	background: -moz-linear-gradient(bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 100%);
	background: -webkit-linear-gradient(bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 100%);
	background: linear-gradient(to top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#1affffff',GradientType=0 );
}
.banner_center_content {
	margin-top: -70px;
}
.carousel-indicators.vertical_center_right {
	display: block;
	right: 40px;
	top: 50%;
	margin: 0;
	left: auto;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	bottom: auto;
}
.indicators_style2.vertical_center_right li {
	margin: 15px 0;
}
.banner_content_border {
	border: 10px solid$red_c;
	padding: 50px;
	position:relative;
}
.slide_banner_img {
	height: 100%;
	position: relative;
	z-index: -1;
}
.zoom-in {
	-webkit-animation: zoomin 5s;
	-moz-animation: zoomin 5s;
	-o-animation: zoomin 5s;
	animation: zoomin 5s;
}
@keyframes zoomin {
  0% {
    -moz-transform: scale(1.2) rotate(3deg);
	-webkit-transform: scale(1.2) rotate(3deg);
	transform: scale(1.2) rotate(3deg);
  }
  100% {
   -moz-transform: scale(1) rotate(0deg);
	-webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}

.header_with_topbar.transparent_header + .banner_section .banner_slide_content {
	margin-top: 45px;
}
.bg_strip {
	background-color:$red_c;
	color: #fff;
	display: inline-block;
	padding: 8px 0px;
	position: relative;
	white-space: nowrap;
	font-size: 20px;
	height: 40px;
}
.bg_strip::before {
	content: '';
	position: absolute;
	z-index: -1;
	right: 100%;
	top: 0;
	display: block;
	width: 0;
	height: 0px;
	border: 20px solid$red_c;
	border-bottom-color:$red_c;
	border-left-color: transparent;
}
.bg_strip::after {
	content: '';
	position: absolute;
	z-index: -1;
	left: 100%;
	top: 0;
	display: block;
	width: 0;
	height: 0px;
	border: 20px solid$red_c;
	border-bottom-color:$red_c;
	border-right-color: transparent;
}