html, body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
body {
	background: #ffffff none repeat scroll 0 0;
    color: #687188;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}
h1,h2,h3,h4,h5,h6 {
	color: #292b2c;
	font-family: 'Roboto';
}
p {
	color:#687188;
	line-height: 28px;
	margin-bottom: 25px;
}
a {
	color: #292b2c;
	text-decoration: none;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all .3s ease-in-out;
}
a:hover {
	color:$red_c;
	text-decoration: none;
}
a:focus {
	outline: none;
}
img {
	max-width: 100%;
}
button {
	-webkit-transition: all 0.3s ease-in-out;
	transition: all .3s ease-in-out;
}
ul,li,ol {
	margin: 0;
	padding: 0;
}
fieldset {
	border: 0 none;
	margin: 0 auto;
	padding: 0;
}
label {
	color: #6c757d;
}
input:focus,
textarea:focus {
	outline: none;
	box-shadow: none;
}
iframe {
	border: 0;
	display: block;
}
video {
	width: 100%;
}
.section {
	padding: 100px 0;
	position:relative;
}
.section.small_pt,
.footer_top.small_pt {
	padding-top: 50px;
}
.section.small_pb,
.footer_top.small_pb {
	padding-bottom: 50px;
}
.section.pb_20, .pb_20,
.footer_top.pb_20 {
	padding-bottom: 20px;
}
.section.pb_70, .pb_70,
.footer_top.pb_70  {
	padding-bottom: 70px;
}
.section.pb_85, .pb_85,
.footer_top.pb_85  {
	padding-bottom: 85px;
}
div.banner_large_pad {
	padding: 200px 0;
}
.small_padding {
    padding: 30px;
}
.medium_padding {
    padding: 50px;
}
.large_padding {
    padding: 100px;
}
.small_divider {
	height: 30px;
}
.medium_divider {
	height: 50px;
}
.large_divider {
	height: 100px;
}
.padding_eight_all {
	padding: 8%;
}
.padding_eight_lr {
	padding: 0 8%;
}
.padding_eight_tb {
	padding: 8% 0;
}
.list_none li {
	list-style:none;
}
.order_list,
.disc_list {
	padding-left: 15px;
}


.section {
	padding: 100px 0;
	position:relative;
}
.section.small_pt,
.footer_top.small_pt {
	padding-top: 50px;
}
.section.small_pb,
.footer_top.small_pb {
	padding-bottom: 50px;
}
.section.pb_20, .pb_20,
.footer_top.pb_20 {
	padding-bottom: 20px;
}
.section.pb_70, .pb_70,
.footer_top.pb_70  {
	padding-bottom: 70px;
}
.section.pb_85, .pb_85,
.footer_top.pb_85  {
	padding-bottom: 85px;
}
div.banner_large_pad {
	padding: 200px 0;
}
.small_padding {
    padding: 30px;
}
.medium_padding {
    padding: 50px;
}
.large_padding {
    padding: 100px;
}
.small_divider {
	height: 30px;
}
.medium_divider {
	height: 50px;
}
.large_divider {
	height: 100px;
}


.text_white * {
	color: #fff;
}
.text_dark * {
	color: #333;
}
.text_default {
	color:$red_c !important;
}
.bg_default {
	background-color:$red_c !important;
}
.bg_gray {
	background-color: #f7f8fb !important;
}
.bg_light_blue {
	background-color: #EBF5FF !important;
}
.bg_light_blue2 {
	background-color: #F4F9FC !important;
}
.bg_blue {
	background-color: #4382FF !important;
}
.bg_linen {
	background-color: #F9F5F0 !important;
}
.bg_redon {
	background-color: #FFF1F1 !important;
}
.bg_dark {
	background-color: #1D2224 !important;
}
.bg_dark2 {
	background-color: #131d24 !important;
}
.bg_dark3 {
	background-color: #233645 !important;
}
.bg_dark4 {
	background-color: #1B1E20 !important;
}
.bg_apricot {
	background-color: #fde0ca !important;
}

.radius_all_5,
.radius_all_5:before,
.radius_all_5:after {
	border-radius: 5px;
}
.radius_all_10,
.radius_all_10:before,
.radius_all_10:after {
	border-radius: 10px;
}
.radius_lb_10,
.radius_lb_10:after,
.radius_lb_10:before {
	border-radius: 10px 0 0 10px;
}
.radius_rb_10,
.radius_rb_10:before,
.radius_rb_10:after  {
	border-radius: 0 10px 10px 0;
}
.radius_lbrb_10,
.radius_lbrb_10:before,
.radius_lbrb_10:after {
	border-radius: 0 0 10px 10px;
}
.radius_ltrt_10,
.radius_ltrt_10:before,
.radius_ltrt_10:after {
	border-radius: 10px 10px 0 0;
}
.scrollup {
	background-color: #171717;
	bottom: 20px;
	color: #ffffff;
	font-size: 24px;
	height: 40px;
	line-height: 40px;
	position: fixed;
	right: 20px;
	text-align: center;
	width: 40px;
	z-index: 99;
}
.scrollup:hover {
	background-color:$red_c;
	color:#fff;
}
.scrollup:focus {
	color:#fff;
}
.scrollup_style1 {
	background-color:$red_c;
}
.scrollup.scrollup_style1:hover {
	background-color: #333;
}
.background_bg {
	background-position: center center;
	background-size: cover;
}
.bg_size_auto {
    background-size: auto !important;
}
.bg_size_contain {
	background-size: contain !important;
}
.bg_norepeat {
    background-repeat: no-repeat !important;
}
.position_top_center {
    background-position: top center !important;
}
.position_center_bottom {
    background-position: center bottom !important;
}
.position_right_center {
    background-position: right center !important;
}
.position_right_bottom {
    background-position: right bottom !important;
}
.fixed_bg {
    background-attachment: fixed !important;
}
[class*=overlay_bg_] {
	position: relative;
}
[class*=overlay_bg_]::before {
	background-color: #000;
	bottom: 0;
	content: "";
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 0;
}
[class*=overlay_bg_default_]::before {
	background-color:$red_c;
}
[class*=overlay_bg_blue_]::before {
	background-color: #031B4E;
}
[class*=overlay_bg_white_]::before {
	background-color: #fff;
}
.overlay_bg_90::before,
.overlay_bg_default_90::before,
.overlay_bg_blue_90::before,
.overlay_bg_white_90::before {
	opacity: 0.9;
}
.overlay_bg_80::before,
.overlay_bg_default_80::before,
.overlay_bg_blue_80::before,
.overlay_bg_white_80::before {
	opacity: 0.8;
}
.overlay_bg_70::before,
.overlay_bg_default_70::before,
.overlay_bg_blue_70::before,
.overlay_bg_white_70::before {
	opacity: 0.7;
}
.overlay_bg_60::before,
.overlay_bg_default_60::before,
.overlay_bg_blue_60::before,
.overlay_bg_white_60::before {
	opacity: 0.6;
}
.overlay_bg_50::before,
.overlay_bg_default_50::before,
.overlay_bg_blue_50::before,
.overlay_bg_white_50::before {
	opacity: 0.5;
}
.overlay_bg_40::before,
.overlay_bg_default_40::before,
.overlay_bg_blue_40::before,
.overlay_bg_white_40::before {
	opacity: 0.4;
}
.overlay_bg_30::before,
.overlay_bg_default_30::before,
.overlay_bg_blue_30::before,
.overlay_bg_white_30::before {
	opacity: 0.3;
}
.overlay_bg_20::before,
.overlay_bg_default_20::before,
.overlay_bg_blue_20::before,
.overlay_bg_white_20::before {
	opacity: 0.2;
}
.overlay_bg_10::before,
.overlay_bg_default_10::before,
.overlay_bg_blue_10::before,
.overlay_bg_white_10::before {
	opacity: 0.1;
}
.ripple {
	background-color:$red_c;
	border-radius: 100%;
	color: #fff;
	position: relative;
	display: inline-block;
	margin-right: 25px;
	line-height: 50px;
	font-size: 22px !important;
	vertical-align: middle;
	margin-left: -15px;
	height: 50px;
	width: 50px;
	text-align: center;
}
.ripple i {
	margin-right: 0;
	margin-left: 3px;
	font-size: 22px;
}
.ripple::before,.ripple::after {
	 -webkit-animation: ripple 1.8s infinite ;
    -moz-animation: ripple 1.8s infinite ;
    -o-animation: ripple 1.8s infinite ;
    -ms-transition:ripple 1.8s infinite ;
	animation: ripple 1.8s infinite ;
	background-color:$red_c;
	border-radius: 100%;
	margin: -20px;
	bottom: 0px;
	content: "";
	display: block;
	left: 0px;
	opacity: 0;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index:-1;
}
.ripple::before {
	-webkit-animation-delay: 0.4s;
	-moz-animation-delay: 0.4s;
	-o-animation-delay: 0.4s;
	animation-delay: 0.4s;
}
.ripple::after {
	-webkit-animation-delay: 1s;
	-moz-animation-delay: 1s;
	-o-animation-delay: 1s;
	animation-delay: 1s;
}
@-webkit-keyframes ripple{
    0%{
        opacity:0;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    50%{
        opacity:0.5;
    }
	100%{
        opacity:0;
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
@keyframes ripple{
    0%{
        opacity:0;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    50%{
        opacity:0.5;
    }
	100%{
        opacity:0;
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
.btn-ripple-white .ripple::before,
.btn-ripple-white .ripple::after,
.btn-ripple-white .ripple {
	background-color: #fff;
}
.btn-ripple-white .ripple {
	color:$red_c;
}

.bg_transparent_20 {
	background-color: rgba(0,0,0,0.2);
}
.box_shadow1 {
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.border-top-tran {
	border-top: 1px solid rgba(255,255,255,0.1);
}
.border_bottom_tran {
	border-bottom: 1px solid rgba(255,255,255,0.1);
}

select:focus{
	outline: none;
}