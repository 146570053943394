@charset "UTF-8";
/***
====================================================================
	Fonts
====================================================================
 ***/
/***
====================================================================
    Colors
====================================================================
 ***/
/***
====================================================================
    transition
====================================================================
 ***/
/***
====================================================================
    clearfix
====================================================================
 ***/
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/*
--------------------------------------------------------------------
We can then extend the .clearfix class wherever necessary.
--------------------------------------------------------------------
.wrap {
    ...
    @extend .clearfix;
}
.main-header {
    ...
    @extend .clearfix;
}*/
/***
====================================================================
    Spacer
====================================================================
 ***/
.spacer10 {
  height: 10px;
}

.spacer20 {
  height: 20px;
}

.spacer30 {
  height: 30px;
}

.spacer40 {
  height: 40px;
}

.spacer50 {
  height: 50px;
}

.spacer60 {
  height: 60px;
}

.spacer70 {
  height: 70px;
}

.spacer80 {
  height: 80px;
}

.spacer90 {
  height: 90px;
}

.spacer100 {
  height: 100px;
}

/***
====================================================================
    media queries
====================================================================
 ***/
/*
media query example

@include breakpoint($min768) {
        position: fixed;
    }
*/
/***
====================================================================
    Mixins for Rem Font Sizing
====================================================================
 ***/
/*
@include font-size(16); -- 16px 1.6rem
*/
/***
====================================================================
    transition
====================================================================
 ***/
/***
====================================================================
    Shadows
====================================================================
 ***/
/***
====================================================================
    Bootstrap them overrides
====================================================================
 ***/
html, body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

body {
  background: #ffffff none repeat scroll 0 0;
  color: #687188;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  color: #292b2c;
  font-family: "Roboto";
}

p {
  color: #687188;
  line-height: 28px;
  margin-bottom: 25px;
}

a {
  color: #292b2c;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: #DB0C15;
  text-decoration: none;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
}

button {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

ul, li, ol {
  margin: 0;
  padding: 0;
}

fieldset {
  border: 0 none;
  margin: 0 auto;
  padding: 0;
}

label {
  color: #6c757d;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
}

iframe {
  border: 0;
  display: block;
}

video {
  width: 100%;
}

.section {
  padding: 100px 0;
  position: relative;
}

.section.small_pt,
.footer_top.small_pt {
  padding-top: 50px;
}

.section.small_pb,
.footer_top.small_pb {
  padding-bottom: 50px;
}

.section.pb_20, .pb_20,
.footer_top.pb_20 {
  padding-bottom: 20px;
}

.section.pb_70, .pb_70,
.footer_top.pb_70 {
  padding-bottom: 70px;
}

.section.pb_85, .pb_85,
.footer_top.pb_85 {
  padding-bottom: 85px;
}

div.banner_large_pad {
  padding: 200px 0;
}

.small_padding {
  padding: 30px;
}

.medium_padding {
  padding: 50px;
}

.large_padding {
  padding: 100px;
}

.small_divider {
  height: 30px;
}

.medium_divider {
  height: 50px;
}

.large_divider {
  height: 100px;
}

.padding_eight_all {
  padding: 8%;
}

.padding_eight_lr {
  padding: 0 8%;
}

.padding_eight_tb {
  padding: 8% 0;
}

.list_none li {
  list-style: none;
}

.order_list,
.disc_list {
  padding-left: 15px;
}

.section {
  padding: 100px 0;
  position: relative;
}

.section.small_pt,
.footer_top.small_pt {
  padding-top: 50px;
}

.section.small_pb,
.footer_top.small_pb {
  padding-bottom: 50px;
}

.section.pb_20, .pb_20,
.footer_top.pb_20 {
  padding-bottom: 20px;
}

.section.pb_70, .pb_70,
.footer_top.pb_70 {
  padding-bottom: 70px;
}

.section.pb_85, .pb_85,
.footer_top.pb_85 {
  padding-bottom: 85px;
}

div.banner_large_pad {
  padding: 200px 0;
}

.small_padding {
  padding: 30px;
}

.medium_padding {
  padding: 50px;
}

.large_padding {
  padding: 100px;
}

.small_divider {
  height: 30px;
}

.medium_divider {
  height: 50px;
}

.large_divider {
  height: 100px;
}

.text_white * {
  color: #fff;
}

.text_dark * {
  color: #333;
}

.text_default {
  color: #DB0C15 !important;
}

.bg_default {
  background-color: #DB0C15 !important;
}

.bg_gray {
  background-color: #f7f8fb !important;
}

.bg_light_blue {
  background-color: #EBF5FF !important;
}

.bg_light_blue2 {
  background-color: #F4F9FC !important;
}

.bg_blue {
  background-color: #4382FF !important;
}

.bg_linen {
  background-color: #F9F5F0 !important;
}

.bg_redon {
  background-color: #FFF1F1 !important;
}

.bg_dark {
  background-color: #1D2224 !important;
}

.bg_dark2 {
  background-color: #131d24 !important;
}

.bg_dark3 {
  background-color: #233645 !important;
}

.bg_dark4 {
  background-color: #1B1E20 !important;
}

.bg_apricot {
  background-color: #fde0ca !important;
}

.radius_all_5,
.radius_all_5:before,
.radius_all_5:after {
  border-radius: 5px;
}

.radius_all_10,
.radius_all_10:before,
.radius_all_10:after {
  border-radius: 10px;
}

.radius_lb_10,
.radius_lb_10:after,
.radius_lb_10:before {
  border-radius: 10px 0 0 10px;
}

.radius_rb_10,
.radius_rb_10:before,
.radius_rb_10:after {
  border-radius: 0 10px 10px 0;
}

.radius_lbrb_10,
.radius_lbrb_10:before,
.radius_lbrb_10:after {
  border-radius: 0 0 10px 10px;
}

.radius_ltrt_10,
.radius_ltrt_10:before,
.radius_ltrt_10:after {
  border-radius: 10px 10px 0 0;
}

.scrollup {
  background-color: #171717;
  bottom: 20px;
  color: #ffffff;
  font-size: 24px;
  height: 40px;
  line-height: 40px;
  position: fixed;
  right: 20px;
  text-align: center;
  width: 40px;
  z-index: 99;
}

.scrollup:hover {
  background-color: #DB0C15;
  color: #fff;
}

.scrollup:focus {
  color: #fff;
}

.scrollup_style1 {
  background-color: #DB0C15;
}

.scrollup.scrollup_style1:hover {
  background-color: #333;
}

.background_bg {
  background-position: center center;
  background-size: cover;
}

.bg_size_auto {
  background-size: auto !important;
}

.bg_size_contain {
  background-size: contain !important;
}

.bg_norepeat {
  background-repeat: no-repeat !important;
}

.position_top_center {
  background-position: top center !important;
}

.position_center_bottom {
  background-position: center bottom !important;
}

.position_right_center {
  background-position: right center !important;
}

.position_right_bottom {
  background-position: right bottom !important;
}

.fixed_bg {
  background-attachment: fixed !important;
}

[class*=overlay_bg_] {
  position: relative;
}

[class*=overlay_bg_]::before {
  background-color: #000;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

[class*=overlay_bg_default_]::before {
  background-color: #DB0C15;
}

[class*=overlay_bg_blue_]::before {
  background-color: #031B4E;
}

[class*=overlay_bg_white_]::before {
  background-color: #fff;
}

.overlay_bg_90::before,
.overlay_bg_default_90::before,
.overlay_bg_blue_90::before,
.overlay_bg_white_90::before {
  opacity: 0.9;
}

.overlay_bg_80::before,
.overlay_bg_default_80::before,
.overlay_bg_blue_80::before,
.overlay_bg_white_80::before {
  opacity: 0.8;
}

.overlay_bg_70::before,
.overlay_bg_default_70::before,
.overlay_bg_blue_70::before,
.overlay_bg_white_70::before {
  opacity: 0.7;
}

.overlay_bg_60::before,
.overlay_bg_default_60::before,
.overlay_bg_blue_60::before,
.overlay_bg_white_60::before {
  opacity: 0.6;
}

.overlay_bg_50::before,
.overlay_bg_default_50::before,
.overlay_bg_blue_50::before,
.overlay_bg_white_50::before {
  opacity: 0.5;
}

.overlay_bg_40::before,
.overlay_bg_default_40::before,
.overlay_bg_blue_40::before,
.overlay_bg_white_40::before {
  opacity: 0.4;
}

.overlay_bg_30::before,
.overlay_bg_default_30::before,
.overlay_bg_blue_30::before,
.overlay_bg_white_30::before {
  opacity: 0.3;
}

.overlay_bg_20::before,
.overlay_bg_default_20::before,
.overlay_bg_blue_20::before,
.overlay_bg_white_20::before {
  opacity: 0.2;
}

.overlay_bg_10::before,
.overlay_bg_default_10::before,
.overlay_bg_blue_10::before,
.overlay_bg_white_10::before {
  opacity: 0.1;
}

.ripple {
  background-color: #DB0C15;
  border-radius: 100%;
  color: #fff;
  position: relative;
  display: inline-block;
  margin-right: 25px;
  line-height: 50px;
  font-size: 22px !important;
  vertical-align: middle;
  margin-left: -15px;
  height: 50px;
  width: 50px;
  text-align: center;
}

.ripple i {
  margin-right: 0;
  margin-left: 3px;
  font-size: 22px;
}

.ripple::before, .ripple::after {
  -webkit-animation: ripple 1.8s infinite;
  -moz-animation: ripple 1.8s infinite;
  -o-animation: ripple 1.8s infinite;
  -ms-transition: ripple 1.8s infinite;
  animation: ripple 1.8s infinite;
  background-color: #DB0C15;
  border-radius: 100%;
  margin: -20px;
  bottom: 0px;
  content: "";
  display: block;
  left: 0px;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: -1;
}

.ripple::before {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.ripple::after {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes ripple {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ripple {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.btn-ripple-white .ripple::before,
.btn-ripple-white .ripple::after,
.btn-ripple-white .ripple {
  background-color: #fff;
}

.btn-ripple-white .ripple {
  color: #DB0C15;
}

.bg_transparent_20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.box_shadow1 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.border-top-tran {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.border_bottom_tran {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

select:focus {
  outline: none;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.2
*/
.owl-theme .owl-controls {
  display: none !important;
  margin-top: 10px;
  text-align: center;
}

/* Styling Next and Prev buttons */
.owl-theme .owl-controls .owl-buttons div {
  color: #fff;
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
  margin: 5px;
  padding: 3px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #869791;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  opacity: 0.5;
}

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  filter: Alpha(Opacity=100);
  /*IE7 fix*/
  opacity: 1;
  text-decoration: none;
}

/* Styling Pagination*/
.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
}

.owl-theme .owl-controls .owl-page span {
  display: none;
  width: 6px;
  height: 6px;
  margin: 5px 7px;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #869791;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  filter: Alpha(Opacity=100);
  /*IE7 fix*/
  opacity: 1;
}

/* If PaginationNumbers is true */
.owl-theme .owl-controls .owl-page span.owl-numbers {
  height: auto;
  width: auto;
  color: #fff;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/* preloading images */
.owl-item.loading {
  min-height: 150px;
  background: url(AjaxLoader.gif) no-repeat center center;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*=owl-] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.owl-theme .owl-nav {
  margin-top: 0;
}

body .owl-theme .owl-nav [class*=owl-] {
  background-color: #fff;
  color: #222;
  font-size: 26px;
  line-height: 50px;
  transition: all 0.3s ease-in-out;
  height: 50px;
  padding: 0;
  margin: 0;
  width: 50px;
  text-align: center;
}

.owl-theme .owl-nav [class*=owl-] i {
  line-height: normal;
}

.owl-theme .owl-dots {
  font-size: 0;
  margin-top: 15px;
}

.owl-theme .owl-dots .owl-dot span {
  background-color: transparent;
  border: 2px solid #DB0C15;
  cursor: pointer;
  margin: 3px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #DB0C15;
}

.dot_style1.owl-theme .owl-dots .owl-dot.active span {
  width: 30px;
}

.nav_style1.owl-theme .owl-nav [class*=owl-],
.nav_style5.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkittransform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 0;
  width: 30px;
}

.nav_style1.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #fff;
  color: #555;
}

.nav_style1.owl-theme .owl-nav .owl-prev {
  left: -50px;
}

.nav_style1.owl-theme .owl-nav .owl-next {
  right: -50px;
}

.nav_style2.owl-theme .owl-nav [class*=owl-] {
  background-color: transparent;
  position: absolute;
  bottom: 10px;
}

.nav_style2.owl-theme .owl-nav [class*=owl-]:hover,
.nav_style3.owl-theme .owl-nav [class*=owl-]:hover,
.nav_style4.owl-theme .owl-nav [class*=owl-]:hover {
  color: #555;
}

.nav_style2.owl-theme .owl-nav .owl-prev,
.nav_style5.owl-theme .owl-nav .owl-prev {
  left: 0px;
}

.nav_style2.owl-theme .owl-nav .owl-next,
.nav_style5.owl-theme .owl-nav .owl-next {
  right: 0px;
}

.nav_style3.owl-theme .owl-nav [class*=owl-],
.nav_style4.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  top: -90px;
  border-radius: 0;
  right: 0;
  height: auto;
  width: auto;
  background-color: transparent;
}

.nav_style3.owl-theme .owl-nav .owl-prev {
  right: 40px;
}

.nav_style4.owl-theme .owl-nav .owl-prev {
  left: 0px;
  right: auto;
}

.nav_style4.owl-theme .owl-nav .owl-prev i::before {
  content: "";
  font-family: "Linearicons";
}

.nav_style4.owl-theme .owl-nav .owl-next i::before {
  content: "";
  font-family: "Linearicons";
}

.nav_style5.owl-theme .owl-nav .owl-prev,
.nav_style5.owl-theme .owl-nav .owl-next {
  opacity: 0;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.nav_style5.owl-theme .owl-nav .owl-prev:hover,
.nav_style5.owl-theme .owl-nav .owl-next:hover {
  background-color: #DB0C15;
  color: #fff;
}

.nav_style5.owl-theme:hover .owl-nav .owl-prev {
  left: 20px;
  opacity: 1;
}

.nav_style5.owl-theme:hover .owl-nav .owl-next {
  right: 20px;
  opacity: 1;
}

.dot_white.owl-theme .owl-dots .owl-dot span {
  border-color: #fff;
}

.dot_white.owl-theme .owl-dots .owl-dot.active span,
.dot_white.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #fff;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
         * Remove all paddings around the image on small screen
         */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/*Loader Css*/
.preloader {
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.lds-ellipsis {
  margin: 0 auto;
  position: relative;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 64px;
  text-align: center;
  z-index: 9999;
}

.lds-ellipsis span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #FF324D;
  -webkit-animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
}

.lds-ellipsis span:nth-child(1) {
  -webkit-animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
}

.lds-ellipsis span:nth-child(2) {
  -webkit-animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
}

@-webkit-keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.custom-container {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  background: url("../../img/loading.gif") center center no-repeat;
  text-align: center;
  margin-top: -12px;
  margin-left: -12px;
  z-index: 1044;
  text-indent: -9999px;
  background-color: #000;
  padding: 25px;
  border-radius: 5px;
}

.btn:focus, .btn:hover, .btn.active {
  box-shadow: none;
  outline: medium none;
}

button:focus {
  outline: none;
}

.border-2 {
  border-width: 2px !important;
}

.btn {
  border-width: 1px;
  cursor: pointer;
  line-height: normal;
  padding: 12px 35px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
}

.btn.active:focus, .btn:active:focus {
  box-shadow: none !important;
}

.btn-fill-out {
  background-color: transparent;
  border: 1px solid #DB0C15;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-fill-out::before,
.btn-fill-out::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #DB0C15;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  width: 51%;
}

.btn-fill-out::after {
  right: 0;
  left: auto;
}

.btn-fill-out:hover:before,
.btn-fill-out:hover:after {
  width: 0;
}

.btn-fill-out:hover {
  color: #DB0C15 !important;
}

.btn-fill-line {
  background-color: transparent;
  border: 1px solid #333;
  overflow: hidden;
  position: relative;
  color: #fff !important;
  transition: all 0.8s ease 0s;
  z-index: 1;
}

.btn-fill-line:before, .btn-fill-line:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #333;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  width: 50%;
}

.btn-fill-line::after {
  right: 0;
  left: auto;
}

.btn-fill-line:hover:before,
.btn-fill-line:hover:after {
  width: 0;
}

.btn-fill-line:hover {
  color: #333 !important;
}

.btn-border-fill {
  border: 1px solid #DB0C15;
  color: #DB0C15;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-border-fill::before,
.btn-border-fill::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #DB0C15;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  width: 0;
}

.btn-border-fill::after {
  right: 0;
  left: auto;
}

.btn-border-fill:hover:before,
.btn-border-fill:hover:after {
  width: 50%;
}

.btn-border-fill:hover {
  color: #fff !important;
}

.btn-white {
  background-color: transparent;
  border: 1px solid #fff;
  color: #292b2c !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-white::before,
.btn-white::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: -1;
  transition: all 0.5s ease-in-out;
  width: 50%;
}

.btn-white::after {
  right: 0;
  left: auto;
}

.btn-white:hover:before,
.btn-white:hover:after {
  width: 0;
}

.btn-white:hover {
  color: #fff !important;
}

.btn-fill-out-dark {
  background-color: transparent;
  border: 1px solid #333;
  color: #fff !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-fill-out-dark:hover {
  color: #333 !important;
}

.btn-fill-out-dark::before {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
  content: "";
  background-color: #333;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

.btn-line-fill {
  border: 1px solid #333;
  overflow: hidden;
  position: relative;
  color: #333 !important;
  transition: all 0.8s ease 0s;
  z-index: 1;
}

.btn-line-fill:hover {
  color: #fff !important;
}

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: 450px;
    height: 450px;
  }
}
@keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: 450px;
    height: 450px;
  }
}
.btn-line-fill:before, .btn-line-fill:after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #333;
  border-radius: 50%;
  z-index: -1;
}

.btn-line-fill:before {
  left: -20px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn-line-fill:after {
  right: -20px;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.btn-line-fill:hover:before {
  -webkit-animation: criss-cross-left 0.7s both;
  animation: criss-cross-left 0.7s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.btn-line-fill:hover:after {
  -webkit-animation: criss-cross-right 0.7s both;
  animation: criss-cross-right 0.7s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.btn-line-white {
  border: 1px solid #fff;
  color: #fff !important;
}

.btn-line-white:hover {
  color: #333 !important;
}

.btn-line-fill.btn-line-white:before, .btn-line-fill.btn-line-white:after {
  background-color: #fff;
}

.btn-tran-light {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.btn-tran-light:hover {
  background-color: #fff;
}

.btn-radius {
  border-radius: 40px;
}

.btn + .btn {
  margin-left: 10px;
}

.btn i {
  font-size: 16px;
  margin-right: 5px;
  vertical-align: middle;
  line-height: 1;
}

.btn span {
  vertical-align: middle;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 8px 25px;
}

.btn-group-lg > .btn, .btn-lg {
  padding: 16px 45px;
}

.btn-xs {
  padding: 4px 20px;
  font-size: 12px;
}

.btn-md {
  padding: 12px 35px;
  font-size: 16px;
}

.btn.btn-xs i {
  font-size: 10px;
}

.btn.btn-sm i {
  font-size: 12px;
}

.btn.btn-lg i {
  font-size: 22px;
}

.btn-ripple {
  padding-right: 0 !important;
  border: 0;
}

.btn-link {
  position: relative;
  z-index: 2;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: none;
}

.btn-link::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 1px;
  background-color: #DB0C15;
  z-index: -1;
}

.divider {
  position: relative;
  overflow: hidden;
  height: 4px;
  z-index: 9;
}

.divider.center_icon,
.divider.left_icon,
.divider.right_icon,
.divider.text_divider {
  height: auto;
}

.divider.left_icon i {
  padding-left: 0;
  padding-right: 20px;
}

.divider.center_icon {
  text-align: center;
}

.divider.right_icon i {
  padding-right: 0;
  padding-left: 20px;
}

.divider.right_icon {
  text-align: right;
}

.divider.text_divider {
  text-align: center;
}

.divider.text_divider span {
  background-color: #fff;
  position: relative;
  z-index: 1;
  font-size: 30px;
  padding: 0 30px;
}

.divider.divider_style1 .divider_icon {
  padding-right: 10px;
  background-color: #fff;
  display: inline-block;
  position: relative;
  z-index: 9;
}

.divider.divider_style1.center_icon .divider_icon {
  padding-left: 10px;
}

.divider.divider_style1.right_icon .divider_icon {
  padding-right: 0px;
  padding-left: 10px;
}

.divider.divider_style1 i {
  background-color: #FF324D;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: block;
  padding: 0;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

.divider:before,
.divider:after {
  content: "";
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0px;
  height: 0;
  border-top: 1px solid #dddddd;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.divider::before {
  margin-top: -1px;
}

.divider::after {
  margin-top: 1px;
}

.divider i {
  background-color: #fff;
  color: #d9d9d9;
  position: relative;
  z-index: 1;
  font-size: 30px;
  padding: 0 40px;
}

.heading_s1 {
  margin-bottom: 25px;
}

.heading_s1 h1,
.heading_s1 h2,
.heading_s1 h3,
.heading_s1 h4,
.heading_s1 h5,
.heading_s1 h6 {
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
}

.heading_s2 {
  position: relative;
}

.heading_s2 h1,
.heading_s2 h2,
.heading_s2 h3,
.heading_s2 h4,
.heading_s2 h5,
.heading_s2 h6,
.heading_s3 h1,
.heading_s3 h2,
.heading_s3 h3,
.heading_s3 h4,
.heading_s3 h5,
.heading_s3 h6 {
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
}

.heading_s2 h1,
.heading_s2 h2,
.heading_s2 h3,
.heading_s2 h4,
.heading_s2 h5,
.heading_s2 h6 {
  position: relative;
  z-index: 9;
}

.heading_s2 .sub_heading {
  font-size: 100px;
  font-weight: bold;
  padding: 0;
  margin-bottom: -15px;
  z-index: 0;
  position: relative;
  line-height: 100px;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4d000000", endColorstr="#1affffff",GradientType=0 );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading_s2.heading_light .sub_heading {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.01) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.01) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.01) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4dffffff", endColorstr="#1a000000",GradientType=0 );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.heading_s3 {
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}

.heading_s3::before {
  content: "";
  position: absolute;
  left: 0;
  width: 50px;
  height: 1px;
  background-color: #ff324d;
  bottom: 0;
  right: 0;
}

.heading_light.heading_s3::before {
  background-color: #fff;
}

.heading_s3.text-center::before {
  margin: 0 auto;
}

.heading_s3.text-right::before {
  left: auto;
  right: 0;
}

.heading_s4 {
  margin-bottom: 15px;
}

.heading_s4 h1,
.heading_s4 h2,
.heading_s4 h3,
.heading_s4 h4,
.heading_s4 h5,
.heading_s4 h6 {
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
  line-height: normal;
}

.heading_s4 .heading_icon i {
  color: #20e076;
  font-size: 30px;
  position: relative;
  z-index: 3;
  width: 50px;
  display: block;
  height: 50px;
  line-height: 50px;
}

.heading_icon {
  display: inline-block;
  position: relative;
  z-index: 2;
  padding: 0 30px;
  text-align: center;
}

.heading_icon::after {
  content: "";
  position: absolute;
  right: 0;
  width: 30px;
  top: 50%;
  border-top: 1px solid #999;
  z-index: -1;
  border-bottom: 1px solid #999;
  height: 5px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.heading_icon::before {
  content: "";
  position: absolute;
  left: 0;
  width: 30px;
  top: 50%;
  border-top: 1px solid #999;
  z-index: -1;
  border-bottom: 1px solid #999;
  height: 5px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.heading_light .heading_icon i {
  color: #fff !important;
}

.heading_uppercase h1,
.heading_uppercase h2,
.heading_uppercase h3,
.heading_uppercase h4,
.heading_uppercase h5,
.heading_uppercase h6 {
  text-transform: uppercase;
}

.heading_light * {
  color: #fff;
}

.sub_heading {
  color: #ff324d;
  margin-bottom: 10px;
  display: block;
}

.highlight_text {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  margin-bottom: 15px;
  display: block;
}

.leads {
  margin-bottom: 30px;
}

.heading_bg {
  display: block;
  background-color: #242A67;
  color: #fff;
  font-weight: 500;
  padding: 5px 25px;
}
.heading_bg h1, .heading_bg h2, .heading_bg h3, .heading_bg h4, .heading_bg h5, .heading_bg h6 {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}

a.heading_bg__link {
  display: block;
  background-color: #242A67;
  color: #fff;
  font-weight: 500;
  padding: 5px 25px;
}
a.heading_bg__link:hover, a.heading_bg__link:focus {
  background-color: #DB0C15;
}
a.heading_bg__link h1, a.heading_bg__link h2, a.heading_bg__link h3, a.heading_bg__link h4, a.heading_bg__link h5, a.heading_bg__link h6 {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}

.custom-container {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 1680px) {
  .custom-container {
    max-width: 1650px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

/*===================================*
  02.START HEADER STYLE
*===================================*/
.navbar-brand {
  padding: 10px 0;
  vertical-align: top;
  margin: 0;
}

.dark_skin .logo_light,
.dark_skin .logo_default,
.light_skin .logo_default,
.light_skin .logo_dark,
.logo_light,
.logo_dark {
  display: none;
}

.dark_skin .logo_dark,
.light_skin .logo_light {
  display: block;
}

.light_skin .navbar-nav a,
.light_skin .navbar-toggler,
.light_skin .navbar a.pr_search_trigger {
  color: #ffffff;
}

.middle-header {
  padding: 10px 0;
  position: relative;
}

.navbar {
  padding: 0;
}

.header_wrap {
  transition: all 0.5s ease 0s;
}

.header_wrap:not([class*=bg_]):not([class*=bg-]) {
  background-color: #fff;
}

.header_wrap:not([class*=bg_]):not([class*=bg-]).transparent_header {
  background-color: transparent;
}

.header_wrap:not([class*=bg_]):not([class*=bg-]).transparent_header.nav-fixed {
  background-color: #fff;
}

.header_wrap:not([class*=bg_]):not([class*=bg-]).transparent_header.light_skin.nav-fixed,
.header_wrap:not([class*=bg_]):not([class*=bg-]).transparent_header.nav-fixed .light_skin {
  background-color: #1d2224;
}

.header_wrap:not([class*=bg_]):not([class*=bg-]).transparent_header.sticky_dark_skin.nav-fixed,
.light_skin .sidetoggle_icon::after,
.light_skin .sidetoggle_icon::before,
.light_skin .toggle_center_line {
  background-color: #fff;
}

.navbar .navbar-nav li {
  position: relative;
  list-style: none;
  transition: all 0.3s ease 0s;
}

.navbar-nav .dropdown-menu {
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  min-width: 14rem;
}

.navbar-nav .dropdown-menu {
  background-color: #252a2c;
}

.light_skin .navbar-nav .dropdown-menu .mega-menu .dropdown-menu,
.light_skin .navbar-nav .dropdown-menu .dropdown-menu {
  background-color: #303537;
}

.dark_skin .navbar-nav .dropdown-menu {
  background-color: #fff;
  box-shadow: 0 13px 42px 11px rgba(0, 0, 0, 0.05);
}

.dark_skin .mega-menu-col,
.dark_skin .cart_list li {
  border-color: #ddd;
}

.dark_skin .navbar .navbar-nav .dropdown-menu li a.active,
.dark_skin .navbar .navbar-nav .dropdown-menu li a:hover,
.dark_skin .navbar .navbar-nav .dropdown-menu > ul > li:hover > a,
.dark_skin .navbar .navbar-nav .dropdown-menu > ul > .mega-menu-col ul > li:hover > a {
  color: #DB0C15;
}

.dark_skin .navbar .navbar-nav li > .dropdown-item,
.dark_skin .navbar .navbar-nav .dropdown-header,
.dark_skin .cart_quantity,
.dark_skin .cart_total {
  color: #333333;
}

.dropdown-toggle::after,
.dropdown-toggler::after {
  border: 0 none;
  font-size: 8px;
  margin-left: 5px;
  vertical-align: middle;
  content: "";
  font-family: "themify";
}

.dropdown-toggler::after {
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.dropdown-menu .dropdown-toggler::after {
  position: absolute;
  right: 15px;
  top: 10px;
}

.navbar .navbar-nav > li > .nav-link {
  font-weight: 500;
  padding: 22px 10px;
  text-transform: capitalize;
  font-size: 14px;
}

.navbar .navbar-nav > li > a.active,
.navbar .navbar-nav > li:hover > a,
.light_skin.transparent_header.nav-fixed .navbar .navbar-nav > li > a.active,
.light_skin.transparent_header.nav-fixed .navbar .navbar-nav > li:hover > a,
.transparent_header.nav-fixed .light_skin .navbar .navbar-nav > li > a.active,
.transparent_header.nav-fixed .light_skin .navbar .navbar-nav > li:hover > a {
  color: #DB0C15;
}

.light_skin .navbar .navbar-nav .dropdown-item,
.light_skin .navbar .navbar-nav .dropdown-header {
  color: #fff;
}

.light_skin .item_remove {
  color: #fff !important;
}

.light_skin .mega-menu-col {
  border-right: 1px solid #3f3f3f;
}

.navbar .navbar-nav .dropdown-item {
  padding: 8px 20px 8px 20px;
  color: #333;
  font-size: 14px;
  text-transform: capitalize;
}

.navbar .navbar-nav .dropdown-item.dropdown-toggler {
  padding-right: 30px;
}

.navbar .navbar-nav .dropdown-header {
  color: #333;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.navbar-nav.attr-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-nav.attr-nav li .nav-link {
  padding: 20px 10px;
  position: relative;
  white-space: nowrap;
}

.navbar .attr-nav li.nav-btn {
  margin-left: 10px;
}

.navbar-nav.attr-nav li .nav-link i {
  font-size: 20px;
}

.hover_menu_style2 .navbar .navbar-nav.attr-nav > li > .nav-link {
  margin: 0;
  padding: 20px 10px;
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

.navbar .navbar-nav.attr-nav .dropdown-menu li a.active,
.navbar .navbar-nav.attr-nav .dropdown-menu li a:hover,
.navbar .navbar-nav.attr-nav .dropdown-menu > ul > li:hover > a {
  background-color: rgba(0, 0, 0, 0);
}

.navbar .navbar-nav .dropdown-menu li a.active,
.navbar .navbar-nav .dropdown-menu li a:hover,
.navbar .navbar-nav .dropdown-menu > ul > li:hover > a,
.navbar .navbar-nav .dropdown-menu > ul > .mega-menu-col ul > li:hover > a,
.sticky_dark_skin.nav-fixed .navbar .navbar-nav .dropdown-item:hover {
  color: #DB0C15;
}

.sticky_dark_skin.nav-fixed .logo_light {
  display: none;
}

.sticky_dark_skin.nav-fixed .logo_dark {
  display: block;
}

.sticky_dark_skin.nav-fixed .navbar-nav li > a,
.sticky_dark_skin.nav-fixed .navbar-toggler,
.sticky_dark_skin.nav-fixed .navbar .navbar-nav .dropdown-item,
.sticky_dark_skin.nav-fixed .navbar .navbar-nav .dropdown-header,
.sticky_dark_skin.nav-fixed .cart_quantity,
.sticky_dark_skin.nav-fixed .cart_total {
  color: #333;
}

.sticky_dark_skin.nav-fixed .navbar .navbar-nav .dropdown-item.active {
  color: #DB0C15;
}

.sticky_dark_skin.nav-fixed .item_remove {
  color: #333 !important;
}

.sticky_dark_skin.nav-fixed .navbar-nav .dropdown-menu,
.sticky_dark_skin.nav-fixed .navbar-nav .dropdown-menu .dropdown-menu {
  background-color: #fff;
  box-shadow: 0 13px 42px 11px rgba(0, 0, 0, 0.05);
}

.sticky_dark_skin.nav-fixed .mega-menu-col,
.sticky_dark_skin.nav-fixed .cart_list li {
  border-color: #ddd;
}

.sticky_dark_skin.nav-fixed.header_wrap[class*=bg_],
.sticky_dark_skin.nav-fixed.header_wrap[class*=bg-] {
  background-color: #fff !important;
}

.sticky_light_skin.nav-fixed .logo_dark {
  display: none;
}

.sticky_light_skin.nav-fixed .logo_light {
  display: block;
}

.sticky_light_skin.nav-fixed.header_wrap:not([class*=bg_]):not([class*=bg-]).transparent_header,
.sticky_light_skin.nav-fixed.header_wrap:not([class*=bg_]):not([class*=bg-]) {
  background-color: #1d2224;
}

.sticky_light_skin.nav-fixed.header_wrap[class*=bg_],
.sticky_light_skin.nav-fixed.header_wrap[class*=bg-] {
  background-color: #1d2224 !important;
}

.sticky_light_skin.nav-fixed .navbar-nav a,
.sticky_light_skin.nav-fixed .navbar-toggler,
.sticky_light_skin.nav-fixed .navbar .navbar-nav .dropdown-item,
.sticky_light_skin.nav-fixed .navbar .navbar-nav .dropdown-header,
.sticky_light_skin.nav-fixed .cart_quantity,
.sticky_light_skin.nav-fixed .cart_total {
  color: #fff;
}

.sticky_light_skin.nav-fixed .item_remove {
  color: #fff !important;
}

.header_wrap:not([class*=bg_]):not([class*=bg-]).sticky_light_skin.nav-fixed .navbar-nav .dropdown-menu {
  background-color: #252a2c;
  border-color: #252a2c;
}

.header_wrap:not([class*=bg_]):not([class*=bg-]).sticky_light_skin.nav-fixed .navbar-nav .dropdown-menu .mega-menu .dropdown-menu,
.header_wrap:not([class*=bg_]):not([class*=bg-]).sticky_light_skin.nav-fixed .navbar-nav .dropdown-menu .dropdown-menu {
  background-color: #303537;
}

.sticky_light_skin.nav-fixed .mega-menu-col {
  border-right: 1px solid #3f3f3f;
}

.sticky_light_skin.nav-fixed .cart_list li {
  border-color: #3f3f3f;
}

.sticky_dark_skin.nav-fixed .btn-tran-light {
  background-color: #333;
  color: #fff !important;
}

.sticky_dark_skin.nav-fixed .btn-tran-light:hover {
  background-color: transparent;
  color: #333 !important;
  border-color: #333;
}

.search_overlay {
  content: "";
  background-color: #000;
  height: 100%;
  top: 0;
  position: fixed;
  text-align: center;
  opacity: 0.5;
  right: 0;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  width: 0;
  z-index: 99;
}

.search_overlay.open {
  left: 0;
  right: auto;
  width: 100%;
}

.search-overlay.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.search_trigger.open i::before {
  content: "";
  font-family: "Ionicons";
}

.search_wrap {
  position: fixed;
  left: 0;
  right: 0;
  max-width: 800px;
  margin: 0 auto;
  padding: 80px 0;
  z-index: 9999;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-align: center;
  align-items: center;
}

.search_wrap.open {
  opacity: 1;
  visibility: visible;
  -webkit-animation: slideInLeft 1s both;
  animation: slideInLeft 1s both;
}

.search_wrap form {
  position: relative;
  width: 100%;
}

.search_wrap .form-control:focus {
  color: #fff;
}

.search_icon {
  font-size: 24px;
  position: absolute;
  right: 5px;
  top: 5px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  color: #fff;
}

.search_wrap .form-control {
  background-color: transparent;
  border-bottom: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 10px 0;
  color: #fff;
}

.search_wrap .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.search_wrap .form-control::-moz-placeholder {
  color: #ffffff;
}

.search_wrap .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.search_wrap .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.search_wrap .form-control::placeholder {
  color: #ffffff;
}

.close-search {
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 25%;
}

.navbar .attr-nav li .search_trigger i {
  font-size: 20px;
  vertical-align: middle;
  line-height: normal;
}

.navbar .attr-nav li.cart_wrap .nav-link i {
  font-size: 20px;
}

.navbar .attr-nav li.cart_wrap .nav-link {
  padding: 35px 5px;
}

.navbar .attr-nav li .nav-link.sidetoggle i {
  font-size: 28px;
}

.pr_search_icon {
  float: right;
}

.pr_search_trigger.show i::before {
  content: "";
}

.pr_search_trigger {
  padding: 17px 10px;
  font-size: 20px;
}

.header_wrap.fixed-top {
  position: relative;
  padding-right: 0 !important;
}

.header_wrap.transparent_header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1030;
}

.header_wrap.nav-fixed {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1041;
  -webkit-animation: slideInDown 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-animation: slideInDown 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  -o-animation: slideInDown 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  animation: slideInDown 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-animation-fill-mode: none;
  -moz-animation-fill-mode: none;
  -o-animation-fill-mode: none;
  animation-fill-mode: none;
}

.nav-fixed.border_bottom_tran {
  border: 0;
}

.header_wrap.fixed-top.transparent_header.nav-fixed.no-sticky {
  position: absolute;
}

.header_wrap.fixed-top.nav-fixed.no-sticky {
  position: relative;
}

.header_wrap:not([class*=bg_]):not([class*=bg-]).transparent_header.nav-fixed.no-sticky {
  background-color: transparent;
  box-shadow: none;
}

.mega-menu {
  display: table;
  padding: 15px 0;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mega-menu ul {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.navbar .navbar-nav li.dropdown-mega-menu {
  position: static;
}

.navbar .navbar-nav li.dropdown-mega-menu .dropdown-menu {
  right: 0;
  padding-left: 0;
  padding-right: 0;
}

.mega-menu-col {
  border-right: 1px solid #ddd;
  padding: 0;
}

.mega-menu-col:last-child {
  border-right: 0 !important;
}

.dropdown-menu li a i {
  font-size: 14px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}

.navbar .navbar-nav > li > .nav-link.cart_trigger i {
  font-size: 20px;
}

.cart_list li {
  list-style: outside none none;
}

.cart_count,
.wishlist_count {
  position: relative;
  top: -3px;
  left: 0;
  font-size: 11px;
  background-color: #DB0C15;
  border-radius: 50px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  min-width: 16px;
  text-align: center;
  padding: 0 5px;
  display: inline-block;
  vertical-align: top;
  margin-left: -5px;
  margin-right: -5px;
}

.cart_trigger .amount {
  margin-left: 8px;
  font-weight: 600;
  vertical-align: top;
  margin-right: -10px;
}

.navbar-nav .dropdown-menu.cart_box {
  width: 320px;
  position: absolute !important;
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important;
  -webkit-transform-origin: -webkit-calc(100% - 30px) 0;
  transform-origin: calc(100% - 30px) 0;
  display: block;
  transition: all 0.25s ease-in-out;
  padding: 0;
  top: 100% !important;
  left: auto !important;
  right: 0;
}

.navbar-nav .dropdown-menu.cart_box.show {
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
}

.cart_list li {
  border-bottom: 1px solid #3f3f3f;
  padding: 15px;
  width: 100%;
}

.item_remove {
  float: right;
  margin-left: 5px;
  color: #333 !important;
}

.cart_list img {
  border: 1px solid #ddd;
  background-color: #fff;
  float: left;
  margin-right: 10px;
  max-width: 80px;
}

.cart_list a {
  font-size: 14px;
  vertical-align: top;
  padding: 0 !important;
  text-transform: capitalize;
  font-weight: 600;
}

.cart_quantity {
  color: #fff;
  display: table;
  margin-top: 5px;
  font-weight: 500;
}

.cart_total .cart_amount {
  float: right;
  color: #f32b56;
}

.cart_box .cart_list {
  width: 100%;
  padding: 0 !important;
  max-height: 242px;
  overflow-y: auto;
}

.cart_list li {
  display: inline-block;
  width: 100%;
}

.dropdown-menu .cart_list li a i {
  width: auto;
}

.cart_total {
  color: #ffffff;
  margin: 0;
  padding: 10px 15px;
  font-weight: 600;
  text-align: right;
}

.cart_total strong {
  float: left;
  font-weight: 600;
}

.cart_buttons {
  margin: 0;
  padding: 10px 15px 20px;
  text-align: center;
}

.cart_buttons .view-cart,
.cart_buttons .checkout {
  padding: 8px 20px !important;
}

.top-header {
  border-bottom: 1px solid #eee;
}

.top-header.light_skin {
  border-color: rgba(255, 255, 255, 0.2);
}

.top-header {
  padding: 10px 0;
  transition: all 0.5s ease-in-out;
}

.top-header[class*=bg_],
.top-header[class*=bg-] {
  border: 0;
}

.top-header span {
  font-size: 14px;
  vertical-align: middle;
}

.header_wrap .social_icons li {
  padding-bottom: 0;
}

.social_icons,
.contact_detail {
  font-size: 0;
}

.contact_detail > li:last-child,
.header_list > li:last-child {
  padding-right: 0;
}

.social_icons li {
  display: inline-block;
  padding: 0px 5px 5px 0;
}

.social_icons li a {
  font-size: 18px;
  color: #687188;
  height: 36px;
  width: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}

.social_icons li a:hover,
.header_wrap .social_icons li a:hover {
  color: #DB0C15;
}

.social_icons.social_small li a {
  height: 25px;
  width: 25px;
  line-height: 26px;
  font-size: 16px;
}

.social_white .social_icons li a,
.social_white.social_icons li a,
.header_wrap .social_white .social_icons li a,
.header_wrap .social_white.social_icons li a {
  color: #fff;
  border-color: #fff;
}

.social_white .social_icons li a:hover,
.social_white.social_icons li a:hover {
  color: #DB0C15;
}

.border_social .social_icons li a:hover,
.border_social.social_icons li a:hover {
  background-color: #DB0C15;
  border-color: #DB0C15;
  color: #fff;
}

.border_social.social_white .social_icons li a:hover,
.social_white.border_social.social_icons li a:hover {
  color: #fff;
}

.border_social li a {
  border: 1px solid #687188;
  line-height: 35px;
}

.social_icons li:last-child a {
  margin-right: 0px;
}

.radius_social li a {
  border-radius: 5px;
}

.rounded_social li a {
  border-radius: 50px;
}

.social_icons.social_style1 li a {
  background-color: #fff;
  color: #DB0C15;
}

.social_icons.social_style1 li a:hover {
  background-color: #DB0C15;
  color: #fff !important;
}

.social_style2 li a {
  background-color: #fff;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.3);
}

.social_style3 li a {
  background-color: #f6f8f9;
  color: #b2b2b7;
  font-size: 20px;
  height: 50px;
  width: 50px;
  line-height: 50px;
}

.social_style4 li a {
  background-color: #fff;
  color: #DB0C15;
}

.social_style4 li a:hover {
  background-color: #DB0C15;
  color: #fff;
}

.social_style4 li a:hover {
  background-color: #DB0C15;
  color: #fff !important;
}

.vertical_social li {
  display: block;
}

.header_wrap .social_icons li a {
  color: #333;
}

.contact_detail i {
  margin-right: 10px;
  vertical-align: middle;
  font-size: 16px;
}

.contact_detail span {
  vertical-align: middle;
}

.contact_detail > li,
.header_list > li {
  color: #333;
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 15px 2px 0;
}

.header_list > li i {
  margin-right: 6px;
  vertical-align: middle;
}

.icon_list > li {
  color: #333;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 10px 2px 0;
}

.icon_list > li > i {
  font-size: 16px;
}

.icon_list > li a {
  color: #bfbfbf;
}

.header_list > li .dropdown-item:hover,
.custome_dropdown .ddChild li:hover,
.contact_detail > li a:hover {
  color: #f32b56;
}

.header_dropdown .dropdown-menu {
  border-radius: 0;
  padding: 0;
  margin-top: 0px;
  border: 0;
  transform: none !important;
  top: 100% !important;
  will-change: auto !important;
}

.header_dropdown .dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
  right: 0;
  left: auto !important;
}

.header_dropdown .dropdown-item {
  padding: 5px 10px;
  border-bottom: 1px solid #e6e6e6;
  text-transform: capitalize;
}

.contact_detail li:first-child {
  margin-left: 0px;
}

.contact_detail li a span {
  vertical-align: middle;
}

.top-header.light_skin .contact_detail li,
.top-header.light_skin .contact_detail li a,
.top-header.light_skin .widget_text span {
  color: #fff;
}

.top-header.dark_skin .contact_detail li {
  color: #000;
}

.nav-fixed .top-header,
.nav-fixed .middle-header {
  display: none !important;
}

.navbar-toggler {
  float: right;
  margin: 13px 0 0 3px;
  font-size: 28px;
  color: #333;
  transition: all 0.5s ease 0s;
  border-radius: 0;
  height: 35px;
  width: 35px;
  padding: 0;
  line-height: 36px;
  transition: none;
}

.navbar-toggler[aria-expanded=true] span::before {
  content: "";
  font-size: 16px;
}

.navbar-toggler[aria-expanded=true] {
  line-height: 32px;
  font-size: 20px;
}

header .attr-nav + .social_icons {
  border-left: 1px solid #333;
  margin-left: 5px;
  padding-left: 5px;
}

header .attr-nav + .social_icons li {
  padding: 0;
}

header.light_skin .attr-nav + .social_icons {
  border-color: #fff;
}

header.light_skin.nav-fixed .attr-nav + .social_icons {
  border-color: #333;
}

header.light_skin.nav-fixed .social_icons li a {
  color: #000;
}

header.light_skin.nav-fixed .social_icons li a:hover {
  color: #0e93d8;
}

.search_box {
  position: relative;
}

.search_box input {
  padding-right: 30px;
}

.search_box button {
  border: 0;
  padding: 0 10px;
  background-color: transparent;
  font-size: 22px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.top-header.light_skin .header_list li a,
.top-header.light_skin .ddArrow::before,
.top-header.light_skin .ddcommon .ddTitle .ddlabel,
.light_skin.top-header span,
.light_skin .icon_list > li a {
  color: #fff;
}

.top-header.light_skin .header_list > li::before {
  background-color: #fff;
}

.top-header .custome_dropdown .ddChild {
  background-color: #fff;
  border: 0 !important;
  min-width: 10rem;
  left: -10px;
}

.top-header.light_skin .header_list li a:hover,
.top-header.light_skin .contact_detail li a:hover,
.top-header.light_skin .header_list li a:hover span {
  color: #DB0C15;
}

.top-header__item {
  font-size: 14px;
}
.top-header__item span {
  color: #fff;
}
.top-header__item a {
  color: #fff;
}
.top-header__item a:hover, .top-header__item a:focus {
  color: #DB0C15;
}

.main_menu_uppercase .navbar-nav > li > .nav-link {
  text-transform: uppercase;
}

.main_menu_weight_100 .navbar .navbar-nav > li > .nav-link {
  font-weight: 100;
}

.main_menu_weight_200 .navbar .navbar-nav > li > .nav-link {
  font-weight: 200;
}

.main_menu_weight_300 .navbar .navbar-nav > li > .nav-link {
  font-weight: 300;
}

.main_menu_weight_400 .navbar .navbar-nav > li > .nav-link {
  font-weight: 400;
}

.main_menu_weight_500 .navbar .navbar-nav > li > .nav-link {
  font-weight: 500;
}

.main_menu_weight_600 .navbar .navbar-nav > li > .nav-link {
  font-weight: 600;
}

.main_menu_weight_700 .navbar .navbar-nav > li > .nav-link {
  font-weight: 700;
}

.main_menu_weight_800 .navbar .navbar-nav > li > .nav-link {
  font-weight: 800;
}

.main_menu_weight_900 .navbar .navbar-nav > li > .nav-link {
  font-weight: 900;
}

.main_menu_size_16 .navbar .navbar-nav > li > .nav-link,
.dd_menu_size_16 .navbar .navbar-nav .dropdown-item,
.dd_menu_size_16 .cart_list a {
  font-size: 16px;
}

.header_banner_wrap {
  padding: 15px;
  height: 100%;
}

.header-banner2 {
  margin: 0 15px;
}

.header-banner,
.header-banner2 {
  display: block;
  position: relative;
  margin-bottom: 15px;
}

.header-banner img {
  width: 100%;
}

.banne_info {
  position: absolute;
  right: 0;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 15px;
}

.banne_info a {
  text-transform: capitalize;
  position: relative;
  padding-bottom: 3px;
  color: #292b2c !important;
}

.banne_info a:hover {
  color: #DB0C15 !important;
}

.banne_info a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  width: 50%;
  background-color: #292b2c;
  transition: all 0.5s ease-in-out;
}

.banne_info a:hover::before {
  width: 100%;
}

.header_banner {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
}

.header_banner_wrap .header_banner {
  -ms-flex-align: center;
  align-items: center;
}

.header_banner_content {
  position: relative;
}

.header_banner_text {
  color: #fff;
}

.header_banner_title {
  font-weight: bold;
  color: #fff;
  margin-bottom: 15px;
}

.banner_img {
  position: relative;
}

.shop_bn_content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 1;
}

.shop_bn_content2 {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.shop_bn_content * {
  color: #fff;
}

.shop_title {
  font-weight: bold;
  margin-bottom: 10px;
}

.shop_banner {
  position: relative;
  display: table;
  margin: 0 auto;
}

.shop_banner2 {
  margin-bottom: 30px;
  height: 235px;
  overflow: hidden;
}

.el_banner1 {
  background-color: #fdbb99;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.el_img {
  text-align: center;
  width: 100%;
}

.shop_banner2 a {
  position: relative;
  z-index: 9;
  padding: 15px;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.el_title {
  width: 100%;
}

.el_banner1::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -80px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 250px;
  height: 250px;
  border-radius: 100%;
  z-index: -1;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.el_banner2::before {
  content: "";
  position: absolute;
  right: -50px;
  bottom: -50px;
  background-color: rgba(255, 255, 255, 0.15);
  width: 250px;
  height: 250px;
  border-radius: 100%;
  z-index: -1;
}

.el_banner2 .el_title h6 {
  font-weight: 600;
  font-size: 18px;
}

.el_banner1 .el_title span {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.el_banner1 .el_img img {
  max-width: 180px;
  margin-top: 20px;
  width: 100%;
}

.el_banner2 {
  background-color: #53c0e9;
  text-align: right;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.shop_banner2:last-child {
  margin-bottom: 0;
}

.el_banner2 .el_img img {
  max-width: 165px;
  width: 100%;
}

.sidebar_menu {
  padding: 50px 30px 30px;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: #fff;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.04);
  bottom: 0;
  width: 400px;
  overflow-y: auto;
  right: -400px;
  visibility: hidden;
  height: 100vh;
  transition: all 0.5s ease;
  z-index: 99999;
}

.sidebar_menu.active {
  right: 0;
  visibility: visible;
}

.side_panel_close {
  position: absolute;
  right: 30px;
  top: 30px;
}

.side_panel_close i {
  font-size: 24px;
}

.sidebar_menu .widget {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}

.sidebar_left .sidebar_menu,
.sidebar_left_push .sidebar_menu {
  right: auto;
  left: -400px;
}

.sidebar_left .sidebar_menu.active,
.sidebar_left_push .sidebar_menu.active {
  left: 0;
}

.sidebar_left_push.sidetoggle_active {
  overflow-y: hidden;
  left: 400px;
}

.sidebar_left_push {
  overflow-x: hidden;
  position: relative;
  transition: all 0.5s ease;
  left: 0;
}

.sidebar_left_push.sidetoggle_active .header_wrap.nav-fixed {
  left: 400px;
  right: -400px;
}

.sidebar_right_push.sidetoggle_active {
  overflow-y: hidden;
  right: 400px;
}

.sidebar_right_push {
  overflow-x: hidden;
  position: relative;
  transition: all 0.5s ease;
  right: 0;
}

.sidebar_right_push.sidetoggle_active .header_wrap.nav-fixed {
  left: -400px;
  right: 400px;
}

.sidebar_dark {
  background-color: #222;
}

.sidebar_dark .sidemenu_close,
.sidebar_dark p {
  color: #fff;
}

.header-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  top: 0;
  transition: all 0.5s ease-in-out;
  cursor: url("../images/close.png"), pointer;
}

body.active {
  overflow: hidden;
  padding-right: 17px;
}

.sidetoggle_icon {
  width: 22px;
  display: block;
  margin: 10px 0;
  position: relative;
  transition: all 0.5s ease-in-out;
  height: 1px;
}

.sidetoggle_icon::before {
  content: "";
  background-color: #333;
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  top: -7px;
  right: 0;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.sidetoggle_icon::after {
  content: "";
  background-color: #333;
  display: block;
  height: 1px;
  width: 15px;
  position: absolute;
  bottom: -7px;
  right: 0;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.sidetoggle.open .sidetoggle_icon::before {
  top: 0;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-property: top, transform;
  transition-delay: 0s, 0.3s;
}

.sidetoggle.open .sidetoggle_icon::after {
  bottom: 0;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition-delay: 0s, 0.3s;
  transition-property: bottom, transform;
  width: 100%;
}

.toggle_center_line {
  background-color: #333;
  height: 1px;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transition: all 0.6s ease-in-out;
}

.sidetoggle.open .toggle_center_line {
  opacity: 0;
}

.sidetoggle_icon:hover:after {
  width: 22px;
}

.widget_text p:last-child {
  margin-bottom: 0;
}

.widget_contact_info .contact_wrap li:last-child {
  margin-bottom: 0;
}

.hover_menu_style1 .navbar-collapse .navbar-nav > li > a {
  position: relative;
  padding: 30px 0px;
  margin: 0 10px;
}

.hover_menu_style1.nav-fixed .navbar .navbar-collapse .navbar-nav > li > .nav-link {
  padding: 20px 0px;
}

.hover_menu_style1 .navbar-collapse .navbar-nav > li > a::before {
  display: block;
  position: absolute;
  bottom: 20px;
  right: 0px;
  height: 2px;
  width: 0;
  z-index: 0;
  content: "";
  background-color: #DB0C15;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

.hover_menu_style1.nav-fixed .navbar-collapse .navbar-nav > li > a::before {
  bottom: 10px;
}

.hover_menu_style1 .navbar-collapse .navbar-nav > li:hover > a::before,
.hover_menu_style1 .navbar-collapse .navbar-nav > li > a.active::before {
  left: 0;
  right: auto;
  width: 100%;
}

input.text {
  display: none;
}

.dd.ddcommon {
  cursor: pointer;
  padding-right: 10px;
  position: relative;
  width: auto !important;
  outline: none;
}

.ddcommon .ddTitleText img {
  border-radius: 100%;
  height: 16px;
  width: 16px;
  margin-right: 6px;
}

.ddcommon .ddTitleText {
  padding: 5px 7px 5px 10px;
  display: inline-block;
  text-transform: uppercase;
}

.ddcommon .ddlabel {
  text-transform: capitalize;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  vertical-align: middle;
}

.ddcommon .ddChild li .ddlabel {
  color: #333;
}

.ddArrow::before {
  content: "";
  color: #878787;
  font-family: ionicons;
  position: absolute;
  right: 0;
  top: 6px;
}

.ddcommon .ddChild {
  background-color: #fff;
  height: auto !important;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  display: block !important;
  opacity: 0;
  visibility: hidden !important;
  left: 0;
}

.ddcommon.borderRadiusTp .ddChild {
  opacity: 1;
  visibility: visible !important;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.ddcommon .ddChild li {
  cursor: pointer;
  line-height: normal;
  list-style: outside none none;
  padding: 5px 10px 5px 10px;
  position: relative;
}

.lng_dropdown .ddcommon .ddChild li {
  padding-left: 33px;
}

.ddcommon .ddChild li img {
  position: absolute;
  left: 10px;
  border-radius: 100%;
  max-width: 16px;
  top: 12px;
}

.nav_block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.categories_wrap {
  position: relative;
}

.categories_btn i {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 10px;
}

.categories_menu i {
  margin-right: 0;
  float: right;
}

.categories_btn.categories_menu {
  padding: 12px 15px;
  border-radius: 4px;
}

.categories_btn span {
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 500;
}

.categories_btn {
  background-color: #DB0C15;
  border: 1px solid #DB0C15;
  padding: 20px 15px;
  color: #fff;
  text-align: left;
  width: 100%;
}

#navCatContent li {
  list-style: none;
}

#navCatContent {
  background-color: #fff;
  position: absolute;
  padding: 5px 0px 0;
  top: 100%;
  width: 100%;
  left: 0;
  right: 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.nav_cat {
  display: block;
  height: auto !important;
}

.front-page .nav_cat.collapse:not(.show) {
  display: block;
}

#navCatContent ul {
  width: 100%;
}

#navCatContent li a {
  text-transform: capitalize;
  font-size: 14px;
}

#navCatContent li a i {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 10px;
  line-height: 30px;
}

#navCatContent li a span {
  vertical-align: middle;
}

#navCatContent li a:hover {
  color: #DB0C15;
}

#navCatContent li a.dropdown-toggler::after {
  position: absolute;
  right: 15px;
  top: 15px;
}

#navCatContent ul li .dropdown-menu {
  left: 100%;
  top: 0;
  margin: 0;
  border: 0;
  min-width: 800px;
  width: 100%;
  right: 0;
  box-shadow: 10px 16px 49px 0px rgba(38, 42, 46, 0.05);
  border-radius: 0;
  padding: 0;
}

#navCatContent ul li .dropdown-menu .dropdown-header {
  color: #333;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: bold;
}

#navCatContent .mega-menu-col {
  border: 0;
}

.mega-menu-col .dropdown-item {
  white-space: normal;
}

.more_categories {
  padding: 12px 15px;
  display: block;
  font-size: 16px;
  color: #DB0C15;
  border-top: 1px solid #ddd;
  margin-top: 5px;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.more_categories::before {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  font-family: Linearicons;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.more_categories.show::before {
  content: "";
}

.product_search_form {
  position: relative;
  max-width: 600px;
  width: 100%;
}

.product_search_form input {
  height: 50px;
  padding-right: 60px !important;
}

.search_form_btn .form-control {
  padding-right: 120px !important;
}

.search_btn {
  position: absolute;
  right: 1px;
  background-color: #fff;
  border: 0;
  padding: 0px 15px;
  font-size: 20px;
  top: 1px;
  bottom: 1px;
  z-index: 9;
}

.search_btn2 {
  position: absolute;
  right: 5px;
  background-color: #DB0C15;
  border: 0;
  border-radius: 100%;
  font-size: 14px;
  top: 5px;
  color: #fff;
  z-index: 9;
  width: 40px;
  height: 40px;
  z-index: 9;
}

.search_btn3 {
  background-color: #DB0C15;
  color: #fff;
  border: 0;
  padding: 10px 30px;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9;
}

.search_btn3:hover {
  background-color: #333;
}

.search_btn:hover {
  color: #DB0C15;
}

.search_btn i {
  vertical-align: middle;
}

.product_search_form select {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product_search_form.rounded_input select {
  border-radius: 30px 0 0 30px;
  padding: 8px 35px 8px 15px;
}

.product_search_form.rounded_input input {
  border-radius: 0 30px 30px 0 !important;
}

.product_search_form.radius_input {
  border-radius: 4px;
  overflow: hidden;
}

.product_search_form.radius_input select {
  border-radius: 4px 0 0 4px;
}

.product_search_form.radius_input input {
  border-radius: 0 4px 4px 0 !important;
}

.header_offer {
  margin-right: 12px;
  padding-right: 12px;
  border-right: 1px solid #ddd;
}

.contact_phone span,
.contact_phone i {
  vertical-align: middle;
}

.contact_phone i {
  font-size: 30px;
  margin-right: 10px;
  color: #DB0C15;
}

.contact_phone span {
  color: #16181b;
}

.banner_content_inner {
  padding: 50px;
}

.header_topbar_info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.download_wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 992px) {
  .cart_dropdown:hover > .dropdown-menu {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    margin-top: 0px !important;
  }
}

@media (min-width: 992px) {
  .cart_dropdown:hover > .dropdown-menu.cart_box {
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
  }
}

.form-control {
  color: #000;
  box-shadow: none;
  height: 50px;
  padding: 8px 15px;
}

.form-control:focus,
.custom-file-input:focus ~ .custom-file-label {
  color: #000;
  box-shadow: none;
  height: 50px;
}

textarea.form-control,
textarea.form-control:focus {
  height: auto;
  padding: 12px 15px;
}

.form-control-sm,
.form-control-sm:focus,
.custom_select select.form-control-sm,
.custom_select select.form-control-sm:focus {
  height: 45px;
}

select.not_chosen,
select.first_null option:first-child,
select.not_chosen:focus,
select.form-control.not_chosen,
select.form-control.first_null option:first-child,
select.not_chosen.form-control:focus {
  color: #6c757d;
}

select option {
  color: #000;
}

.custom_select {
  position: relative;
}

.custom_select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #ced4da;
  padding: 8px 35px 8px 15px;
  width: 100%;
  height: 50px;
}

.custom_select::before {
  content: "";
  font-family: "themify";
  position: absolute;
  display: block;
  color: #848484;
  top: 50%;
  right: 15px;
  font-weight: 900;
  pointer-events: none;
  font-size: 12px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.custom-file-label::after {
  height: 48px;
  border: 0;
  line-height: 40px;
  border-radius: 0;
}

.custom-file-label,
.custom-file,
.custom-file-input {
  height: 50px;
  line-height: 38px;
}

.rounded_input .form-control,
.rounded_input input {
  border-radius: 30px;
  padding: 10px 20px;
}

.rounded_input .custom_select select {
  padding-right: 40px;
  font-size: 13px;
}

.rounded_input .custom_select::before {
  right: 20px;
}

.rounded_input textarea.form-control,
.rounded_input textarea.form-control:focus {
  padding: 15px 20px;
}

.input_group {
  position: relative;
}

.input_icon {
  position: absolute;
  right: 20px;
  top: 14px;
  pointer-events: none;
  color: #848484;
}

.rounded_input .input_icon {
  top: 12px;
}

.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
  color: #687188;
  padding: 0;
  vertical-align: middle;
}

.custome-checkbox .form-check-input {
  display: none;
}

.custome-checkbox .form-check-label span {
  vertical-align: middle;
}

.custome-checkbox .form-check-label::before {
  content: "";
  border: 2px solid #ced4da;
  height: 17px;
  width: 17px;
  margin: 0px 8px 0 0;
  display: inline-block;
  vertical-align: middle;
}

.custome-checkbox input[type=checkbox]:checked + .form-check-label::after {
  opacity: 1;
}

.custome-checkbox input[type=checkbox] + .form-check-label::after {
  content: "";
  width: 11px;
  position: absolute;
  top: 50%;
  left: 3px;
  opacity: 0;
  height: 6px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -moz-transform: translateY(-65%) rotate(-45deg);
  -webkit-transform: translateY(-65%) rotate(-45deg);
  transform: translateY(-65%) rotate(-45deg);
}

.custome-checkbox input[type=checkbox]:checked + .form-check-label::before {
  background-color: #DB0C15;
  border-color: #DB0C15;
}

.custome-radio .form-check-input,
.custome-checkbox .form-check-input {
  display: none;
}

.custome-radio .form-check-label::before {
  content: "";
  border: 1px solid #908f8f;
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 8px;
}

.custome-radio input[type=radio] + .form-check-label::after {
  content: "";
  background-color: #DB0C15;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  left: 3px;
  opacity: 0;
}

.custome-radio .form-check-label,
.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
}

.custome-radio input[type=radio]:checked + .form-check-label::before {
  border-color: #DB0C15;
}

.custome-radio input[type=radio]:checked + .form-check-label::after {
  opacity: 1;
}

.custome-checkbox input[disabled] + .form-check-label,
.custome-radio input[disabled] + .form-check-label {
  color: #d0d0d0;
}

.custome-checkbox input[disabled] + .form-check-label::before,
.custome-radio input[disabled] + .form-check-label::before {
  border-color: #e8e8e8;
}

.banner_section {
  position: relative;
}

.banner_content_wrap {
  position: relative;
  z-index: 1;
}

.banner_half_content {
  padding: 100px 0 80px;
  z-index: 0;
}

.full_screen.banner_half_content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.banner_section:not(.full_screen),
.banner_section:not(.full_screen) .carousel-item,
.banner_section:not(.full_screen) .banner_content_wrap,
.banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
  height: 800px;
}

.full_screen, .full_screen .carousel-item {
  height: 100vh;
  min-height: 45rem;
}

.banner_section.slide_medium,
.banner_section.slide_medium .carousel-item,
.banner_section.slide_medium .banner_content_wrap,
.banner_section.slide_medium .banner_content_wrap .carousel-item,
.banner_section.shop_el_slider,
.banner_section.shop_el_slider .carousel-item,
.banner_section.shop_el_slider .banner_content_wrap,
.banner_section.shop_el_slider .banner_content_wrap .carousel-item {
  height: 500px;
}

.banner_section.slide_wrap,
.banner_section.slide_wrap .carousel-item,
.banner_section.slide_wrap .banner_content_wrap,
.banner_section.slide_wrap .banner_content_wrap .carousel-item {
  height: 650px;
}

.banner_section .banner_slide_content {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.carousel-item img {
  position: relative;
  z-index: -1;
  width: 100%;
}

.carousel-control-next, .carousel-control-prev {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  height: 50px;
  opacity: 0;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50px;
  transition: all 0.3s ease-in-out;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  opacity: 0;
}

.carousel:hover .carousel-control-next, .carousel:hover .carousel-control-prev {
  opacity: 1;
}

.carousel-control-next {
  right: 20px;
}

.carousel-control-prev {
  left: 20px;
}

.carousel_style1 .carousel-control-next, .carousel_style1 .carousel-control-prev {
  border-radius: 100%;
}

.carousel_style2 .carousel-control-next, .carousel_style2 .carousel-control-prev {
  font-size: 16px;
  border-radius: 4px;
  height: 40px;
  width: 40px;
}

.light_arrow .carousel-control-next, .light_arrow .carousel-control-prev {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #000;
}

.carousel-control-next:hover, .carousel-control-prev:hover,
.light_arrow .carousel-control-next:hover, .light_arrow .carousel-control-prev:hover {
  background-color: #DB0C15;
  color: #fff;
}

.indicators_style1,
.indicators_style2,
.indicators_style3 {
  margin-bottom: 15px;
}

.indicators_style1 li {
  width: 10px;
  height: 10px;
  background-color: #333;
  border: 0;
  margin: 8px;
  position: relative;
}

.indicators_style1 li::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border: 2px solid #333;
  margin: -4px;
}

.indicators_style2 li {
  background-color: #333;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-clip: inherit;
  border: 0;
  margin: 8px;
  position: relative;
}

.indicators_style2 li::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border: 2px solid #333;
  margin: -4px;
}

.indicators_style3 li {
  background-color: #333;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-clip: inherit;
  border: 0;
  margin: 5px;
  position: relative;
}

.indicators_style1 li.active,
.indicators_style2 li.active {
  background-color: #DB0C15;
}

.indicators_style2 li.active:before,
.indicators_style1 li.active:before {
  border-color: #DB0C15;
}

.indicators_style4 li {
  background-color: #fff;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  background-clip: inherit;
  border: 0;
  margin: 5px;
  position: relative;
}

.banner_content .carousel-item {
  padding: 100px 0;
}

.banner_content h2 {
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.banner_content p,
.banner_content2 p {
  margin-bottom: 30px;
}

.banner_content2 h2 {
  font-size: 70px;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.banner_content3 h2 {
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.banner_shape {
  position: absolute;
  right: 0;
  top: 0;
  width: 70%;
  z-index: -1;
}

.banner_shape img {
  width: 100%;
}

.wave_shape_bottom::before {
  background-image: url("../images/wave_shape.png");
  display: block;
  height: 100%;
  content: "";
  background-position: bottom center;
  position: absolute;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  right: 0;
}

.banner_content1 h2 {
  font-size: 78px;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.background_shape {
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 35%;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#1affffff",GradientType=0 );
  -moz-transform: skewX(30deg);
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
  transform-origin: left bottom;
  pointer-events: none;
}

.background_shape:before {
  content: "";
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 100%;
  background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#1affffff",GradientType=0 );
}

.banner_center_content {
  margin-top: -70px;
}

.carousel-indicators.vertical_center_right {
  display: block;
  right: 40px;
  top: 50%;
  margin: 0;
  left: auto;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  bottom: auto;
}

.indicators_style2.vertical_center_right li {
  margin: 15px 0;
}

.banner_content_border {
  border: 10px solid #DB0C15;
  padding: 50px;
  position: relative;
}

.slide_banner_img {
  height: 100%;
  position: relative;
  z-index: -1;
}

.zoom-in {
  -webkit-animation: zoomin 5s;
  -moz-animation: zoomin 5s;
  -o-animation: zoomin 5s;
  animation: zoomin 5s;
}

@keyframes zoomin {
  0% {
    -moz-transform: scale(1.2) rotate(3deg);
    -webkit-transform: scale(1.2) rotate(3deg);
    transform: scale(1.2) rotate(3deg);
  }
  100% {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}
.header_with_topbar.transparent_header + .banner_section .banner_slide_content {
  margin-top: 45px;
}

.bg_strip {
  background-color: #DB0C15;
  color: #fff;
  display: inline-block;
  padding: 8px 0px;
  position: relative;
  white-space: nowrap;
  font-size: 20px;
  height: 40px;
}

.bg_strip::before {
  content: "";
  position: absolute;
  z-index: -1;
  right: 100%;
  top: 0;
  display: block;
  width: 0;
  height: 0px;
  border: 20px solid #DB0C15;
  border-bottom-color: #DB0C15;
  border-left-color: transparent;
}

.bg_strip::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 100%;
  top: 0;
  display: block;
  width: 0;
  height: 0px;
  border: 20px solid #DB0C15;
  border-bottom-color: #DB0C15;
  border-right-color: transparent;
}

.pagination .page-item a {
  color: #0E93D8;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  padding: 0px;
  z-index: 1;
}

.page-link:focus {
  box-shadow: none;
}

.pagination_style1 li,
.pagination_style2 li,
.pagination_style3 li,
.pagination_style4 li {
  margin-right: 5px;
}

.pagination_style1 .page-item a {
  border: 2px solid #dee2e6;
  border-radius: 0 !important;
  color: #687188;
  margin-left: 0px;
  line-height: 36px;
}

.pagination_style1 .page-item a i {
  line-height: 36px;
}

.pagination_style1 .page-item.disabled .page-link,
.pagination_style3 .page-item.disabled .page-link {
  color: #c6c6c6;
  pointer-events: none;
  background-color: #fff;
  border-color: #f4f4f4;
}

.pagination_style1 .page-item.active .page-link,
.pagination_style1 .page-item .page-link:hover {
  background-color: #FF324D;
  border-color: #FF324D;
  color: #fff;
}

.widget_title {
  margin-bottom: 25px;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.widget_categories li,
.widget_archive li {
  padding-bottom: 10px;
  list-style: none;
}

.widget_categories li a,
.widget_archive li a {
  position: relative;
}

.widget_categories li a::before,
.widget_archive li a:before {
  content: "";
  font-family: "themify";
  height: auto;
  line-height: normal;
  margin-right: 10px;
  font-size: 10px;
}

.widget_categories li:last-child,
.widget_archive li:last-child {
  padding-bottom: 0;
}

.widget_categories .categories_num,
.widget_archive .archive_num {
  float: right;
  font-size: 14px;
}

.sidebar .widget + .widget {
  border-top: 1px solid #ddd;
  margin-top: 30px;
  padding-top: 30px;
}

.widget_newsletter p {
  font-size: 14px;
  line-height: normal;
}

.widget_newsletter button {
  padding: 5px 12px;
}

.widget_newsletter button i,
.widget_newsletter .btn i {
  font-size: 18px;
}

.widget_recent_post li,
.widget_course li {
  list-style: none;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;
}

.widget_course li {
  display: table;
}

.post_footer {
  display: inline-block;
  vertical-align: top;
}

.widget_recent_post li:last-child,
.widget_course li:last-child {
  margin-bottom: 0px;
}

footer .widget_recent_post .post_img img {
  border: 0;
}

.post_img,
.widget_course li .course_img {
  float: left;
  margin-right: 10px;
}

.post_img img {
  width: 80px;
}

.post_content,
.widget_course li .course_content {
  overflow: hidden;
}

.post_content h6 {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
}

.post_content .product_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  margin-bottom: 5px;
}

.post_content .product_price {
  margin-bottom: 0px;
}

.tags a {
  background-color: #f7f7f7;
  font-size: 14px;
  padding: 8px 15px;
  display: inline-block;
  margin-bottom: 5px;
  color: #333;
}

.tags a:hover {
  background-color: #ff324d;
  color: #fff;
}

.tags_style1 a {
  border-radius: 40px;
}

.widget_tweet_feed li {
  list-style: none;
  position: relative;
  font-size: 14px;
  padding-bottom: 15px;
  padding-left: 25px;
}

.widget_tweet_feed li::before {
  content: "";
  font-family: "themify";
  position: absolute;
  left: 0;
  top: 0;
  color: #292b2c;
}

.widget_tweet_feed a {
  color: #ff324d;
}

.widget_tweet_feed li:last-child {
  padding-bottom: 0px;
}

.widget_instafeed {
  margin: -2px;
  display: table;
}

.widget_instafeed li {
  list-style: none;
  float: left;
  width: 33.33%;
  padding: 2px;
  position: relative;
}

.instafeed_col4 li {
  width: 25%;
}

.widget_instafeed li a {
  display: block;
  position: relative;
  overflow: hidden;
}

.widget_instafeed li a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.widget_instafeed img {
  width: 100%;
}

.insta_icon {
  color: #fff;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.5s ease-in-out;
  z-index: 2;
}

.widget_instafeed li:hover a::before,
.widget_instafeed li:hover .insta_icon {
  opacity: 1;
}

.insta_img {
  position: relative;
}

.instagram_icon {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  -moz-transform: translateY(-50%) rotate(30deg) scale(1.8);
  -webkit-transform: translateY(-50%) rotate(30deg) scale(1.8);
  transform: translateY(-50%) rotate(30deg) scale(1.8);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.instagram_icon i {
  color: #fff;
  font-size: 50px;
}

.insta_img::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.insta_img:hover:before {
  opacity: 1;
  visibility: visible;
}

.insta_img:hover .instagram_icon {
  opacity: 1;
  visibility: visible;
  -moz-transform: translateY(-50%) rotate(0deg) scale(1);
  -webkit-transform: translateY(-50%) rotate(0deg) scale(1);
  transform: translateY(-50%) rotate(0deg) scale(1);
}

.follow_box {
  position: absolute;
  top: 50%;
  z-index: 9;
  text-align: center;
  right: 0;
  left: 0;
  max-width: 400px;
  background-color: white;
  margin: 0 auto;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 25px;
  border-radius: 80px;
}

.follow_box i {
  position: absolute;
  font-size: 80px;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0.1;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.instafeed_box {
  overflow: hidden;
}

.instafeed_box a {
  position: relative;
  display: block;
}

.instafeed_box a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  display: block;
  transition: all 0.5s ease-in-out;
}

.instafeed_box a:hover:before {
  opacity: 1;
}

.instafeed_box a img {
  transition: all 0.5s ease-in-out;
}

.instafeed_box a:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.widget-action {
  padding-top: 10px;
}

.filter-widget > ul {
  height: 170px;
  overflow: hidden;
  transition: height 0.2s ease-out;
}
.static-dropdown-menu {
  position: static !important;
  float: none;
  padding: 0;
  margin: 0;
  border-left: none;
  border-right: none;
}
.static-dropdown-menu .dropdown-item {
  background-color: rgba(0, 0, 0, 0.05);
  white-space: normal;
  font-size: 14px;
}
.static-dropdown-menu .dropdown-item::before {
  font-size: 8px;
}
.static-dropdown-menu .dropdown-item:hover, .static-dropdown-menu .dropdown-item:focus {
  background-color: #242A67;
  color: #fff;
}

.widget_categories {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.widget_categories .dropdown.show .dropdown-toggler {
  background-color: #242A67;
  color: #fff;
}

.nav-tabs li.nav-item a {
  background-color: transparent;
  border: 0;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  padding: 5px 20px;
}

.tab-content {
  margin-top: 25px;
}

.tab-style1 .nav-tabs,
.tab-style2 .nav-tabs {
  border-bottom: 0;
}

.tab-style1 .nav-tabs li.nav-item a.active, .tab-style1 .nav-tabs li.nav-item a.active:hover,
.tab-style2 .nav-tabs li.nav-item a.active, .tab-style2 .nav-tabs li.nav-item a.active:hover {
  color: #FF324D;
}

.tab-style2 .nav-tabs li.nav-item a {
  padding: 0px 15px;
}

.tab-style2 .nav-tabs li.nav-item:last-child a {
  padding-right: 0;
}

.tab-style3 .nav-tabs li.nav-item a {
  background-color: transparent;
  display: block;
  padding: 0.5rem 1rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  text-align: center;
  text-transform: uppercase;
}

.tab-style3 .nav-tabs .nav-item a.active {
  border-bottom-color: #FF324D;
  color: #FF324D;
}

.tab_slider > .tab-pane {
  display: block;
  height: 0;
}

.tab_slider .tab-pane.active {
  height: auto;
  display: block;
  -webkit-animation-name: moveUp;
  animation-name: moveUp;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes moveUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes moveUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.heading_tab_header {
  padding-bottom: 0;
  border-bottom: 1px solid #ddd;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.heading_tab_header [class*=heading_] {
  float: left;
}

/*===================================*
  23.START SHOP DESIGN
*===================================*/
.shorting_icon {
  font-size: 18px;
  margin-right: 10px;
  height: 45px;
  width: 45px;
  float: left;
  text-align: center;
  line-height: 45px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.shorting_icon .ti-layout-list-thumb {
  font-size: 24px;
  line-height: 45px;
}

.shorting_icon.active {
  background-color: #DB0C15;
  color: #fff;
  border-color: #DB0C15;
}

.result_count {
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}

.product_header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.product_header_right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.products_view {
  display: inline-block;
  vertical-align: middle;
}

.product,
.product_wrap {
  background-color: #fff;
  position: relative;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.product_box {
  margin-bottom: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.product_box .product_info {
  position: relative;
}

.product_box .add-to-cart {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  background-color: #fff;
  padding: 10px 15px 15px 15px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}

.product_box:hover .add-to-cart {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.product_box .pr_action_btn li a {
  border-radius: 100%;
  width: 45px;
  height: 45px;
  line-height: 45px;
}

.product_wrap .pr_action_btn li a {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.product_wrap .pr_action_btn li a i {
  line-height: 35px;
  font-size: 16px;
}

.carousel_slider .product,
.carousel_slider .product_wrap {
  margin: 5px;
}

.product_img img {
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.product_img .product_hover_img {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
}

.product:hover .product_img .product_hover_img,
.product_box:hover .product_img .product_hover_img,
.product_wrap:hover .product_img .product_hover_img {
  opacity: 1;
  z-index: 0;
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.pr_desc {
  display: none;
}

.pr_flash {
  background-color: #ff9f00;
  position: absolute;
  left: 10px;
  top: 10px;
  text-transform: uppercase;
  color: #fff;
  padding: 2px 8px;
  font-size: 13px;
  z-index: 1;
}

.product_info {
  padding: 15px;
}

.box-product_info {
  height: 130px;
}

.shop_container:not(.list) .grig-list-product_info {
  height: 130px;
}

.price {
  color: #DB0C15;
  font-weight: 600;
}

.product_price del {
  font-size: 14px;
  margin-right: 5px;
  margin-left: 3px;
}

.product-price del {
  margin-left: 5px;
  color: #919191;
}

.on_sale {
  color: #388e3c;
  font-size: 14px;
}

.product_price {
  margin-bottom: 5px;
}

.product .rating_wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.product.text-center .rating_wrap {
  -ms-flex-pack: center;
  justify-content: center;
}

.rating_num {
  font-size: 14px;
  margin-left: 5px;
  vertical-align: middle;
  display: inline-block;
}

.rating_wrap .rating {
  overflow: hidden;
  position: relative;
  height: 20px;
  font-size: 12px;
  width: 70px;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  vertical-align: middle;
}

.rating::before {
  font-size: 12px;
  content: "";
  top: 0;
  position: absolute;
  left: 0;
  float: left;
  color: #f6bc3e;
}

.product_rate {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
  color: #f6bc3e;
}

.product_rate::before {
  font-size: 12px;
  content: "";
  top: 0;
  position: absolute;
  left: 0;
  font-weight: 900;
}

.product_action_box {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  right: 0;
  text-align: center;
  padding-top: 0;
  transition: all 0.5s ease-in-out;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.pr_action_btn {
  display: table;
  margin: 0 auto;
}

.product_img {
  position: relative;
  overflow: hidden;
}

.product_img::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.product:hover .product_img:before,
.product_box:hover .product_img:before {
  opacity: 1;
}

.pr_action_btn li {
  position: relative;
  margin: 0px 5px;
  display: inline-block;
}

.product_wrap .pr_action_btn li {
  display: block;
  margin: 10px 0;
}

.product_wrap .pr_action_btn {
  margin: 0 0 0 10px;
}

.product_action_box li {
  -webkit-transform: translate(0, 15px);
  -ms-transform: translate(0, 15px);
  -o-transform: translate(0, 15px);
  transform: translate(0, 15px);
  opacity: 0;
}

.product_action_box li:nth-child(1) {
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.product_action_box li:nth-child(2) {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.product_action_box li:nth-child(3) {
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.product_action_box li:nth-child(4) {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.product:hover .product_action_box li,
.product_box:hover .product_action_box li,
.product_wrap:hover .product_action_box li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

.pr_action_btn li a {
  background-color: #fff;
  font-size: 0;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  width: 37px;
  height: 37px;
  padding: 0;
  display: block;
  text-align: center;
}

.pr_action_btn li a i {
  vertical-align: middle;
  line-height: 37px;
  font-size: 18px;
}

.pr_action_btn li a:hover {
  background-color: #DB0C15;
  color: #fff;
}

.product .product_title,
.product_box .product_title,
.product_wrap .product_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
}

.product_color_switch span {
  height: 15px;
  width: 15px;
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  position: relative;
  cursor: pointer;
  border-radius: 100%;
}

.product .product_color_switch span {
  -webkit-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
  opacity: 0;
}

.product_color_switch span.active::before {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: -3px;
  border-radius: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.product .pr_switch_wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 7px 15px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.product:hover .pr_switch_wrap {
  opacity: 1;
  visibility: visible;
}

.product:hover .product_color_switch span {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}

.product_color_switch span:first-child {
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.product_color_switch span:nth-child(2) {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.product_color_switch span:nth-child(3) {
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.product_color_switch span:nth-child(4) {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.product_color_switch span:nth-child(5) {
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}

.product_color_switch span:nth-child(6) {
  -webkit-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.product_color_switch span:nth-child(7) {
  -webkit-transition: all 0.75s ease 0s;
  -o-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
}

.product_list .product,
.product_list .product_wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  margin-bottom: 30px;
}

.product_list .product_img {
  max-width: 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  width: 100%;
}

.product_list .product_info {
  padding: 0px 0 0 15px;
  text-align: left;
  max-width: 70%;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
}

.product_list .product_img::before {
  content: normal;
}

.mfp-ajax-holder .mfp-content .ajax_quick_view {
  max-width: 980px;
  background-color: #fff;
  margin: 6px auto;
  padding: 30px;
  position: relative;
}

.mfp-close {
  width: 30px;
  height: 30px;
  line-height: normal;
}

.product-image {
  position: relative;
}

.pr_detail .pr_desc {
  display: inline-block;
}

.pr_detail .pr_desc p {
  margin-bottom: 15px;
}

.pr_detail .rating_wrap {
  float: right;
  margin-top: 7px;
}

.pr_detail .product_price {
  float: left;
}

.pr_detail .price {
  vertical-align: middle;
  font-size: 26px;
}

.cart-product-quantity {
  margin: 7px 10px 7px 0;
  display: table;
}

.quantity {
  display: table;
}

.quantity .minus,
.quantity .plus {
  background-color: #eee;
  display: block;
  float: left;
  border-radius: 50px;
  cursor: pointer;
  border: 0;
  padding: 0;
  width: 34px;
  height: 34px;
  line-height: 36px;
  text-align: center;
  font-size: 20px;
  margin: 4px;
}

.quantity .minus {
  padding-left: 4px;
}

.quantity .qty {
  float: left;
  width: 55px;
  height: 36px;
  border: 1px solid #ddd;
  background-color: transparent;
  text-align: center;
  padding: 0;
  margin: 3px;
}

.cart_extra {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.zoomContainer {
  z-index: 99;
}

.zoom_image .zoomContainer {
  z-index: 1043;
}

.zoom_gallery_image .zoomContainer {
  z-index: 0;
}

.switch_lable {
  float: left;
  margin-right: 10px;
}

.pr_detail .pr_switch_wrap {
  margin-bottom: 10px;
}

.product_size_switch span {
  cursor: pointer;
  text-transform: uppercase;
  width: 32px;
  display: inline-block;
  border: 2px solid #ddd;
  text-align: center;
  height: 32px;
  line-height: 28px;
  font-size: 14px;
  margin-bottom: 3px;
}

.product_size_switch span.active {
  border-color: #DB0C15;
  background-color: #DB0C15;
  color: #fff;
}

.list_brand li {
  list-style: none;
  margin-bottom: 10px;
  height: 25px;
}

.list_brand li:last-child {
  margin-bottom: 0;
}

.list_brand .custome-checkbox .form-check-label {
  color: #292b2c;
}

.cart_btn .add_wishlist,
.cart_btn .add_compare {
  font-size: 20px;
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;
}

.product_gallery_item a {
  border: 1px solid #ddd;
  display: block;
  padding: 5px;
}

.product-meta li {
  list-style: none;
  margin-top: 10px;
}

.slick-vertical .slick-slide {
  border: 0;
}

.slick-list {
  padding: 0 !important;
}

.product_gallery_item.slick-slider .slick-slide {
  margin-right: 5px;
  margin-left: 5px;
}

.product-image .slick-list {
  margin-right: -5px;
  margin-left: -5px;
}

.product_gallery_item a.active {
  border-color: #DB0C15;
}

.vertical_gallery #pr_item_gallery {
  width: 15%;
  float: left;
  margin-right: 10px;
}

.vertical_gallery .slick-list {
  margin-right: 0;
  margin-left: 0;
}

.vertical_gallery .product_gallery_item.slick-slider .slick-slide {
  margin-bottom: 12px;
  margin-right: 0;
  margin-left: 0;
}

.vertical_gallery .slick-prev,
.vertical_gallery .slick-next {
  height: 40px;
  width: 100%;
}

.vertical_gallery .slick-prev {
  border-radius: 0 0 5px 5px;
  left: 0;
  right: 0;
  top: -40px;
  margin: 0 auto;
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.vertical_gallery .slick-next {
  border-radius: 5px 5px 0 0;
  right: 0;
  left: 0;
  bottom: -40px;
  top: auto;
  margin: 0 auto;
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.vertical_gallery .slick-prev:before {
  content: "";
}

.vertical_gallery .slick-next:before {
  content: "";
}

.vertical_gallery .slick_slider:hover .slick-prev {
  top: 0;
}

.vertical_gallery .slick_slider:hover .slick-next {
  bottom: 0px;
  right: 0;
}

.product_img_box {
  padding: 8px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}

.product_img_zoom .product_img_box__icon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
  font-size: 18px;
  background-color: #fafafa;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
}

.product_share {
  margin-top: 15px;
}

.product_share > span {
  margin-right: 10px;
}

.product_share > span,
.product_share .social_icons {
  vertical-align: middle;
  display: inline-block;
}

.product_share .social_icons li {
  padding-bottom: 0;
}

.product_sort_info {
  margin-bottom: 15px;
}

.product_sort_info li {
  font-size: 14px;
  color: #292b2c;
  padding-bottom: 10px;
  list-style: none;
  padding-left: 22px;
  position: relative;
}

.product_sort_info li i {
  color: #DB0C15;
  position: absolute;
  font-size: 16px;
  left: 0;
  top: 3px;
}

.filter_price .ui-widget.ui-widget-content {
  border: 0;
  border-radius: 0;
  background-color: #ddd;
  height: 4px;
  margin-bottom: 20px;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.filter_price .ui-slider .ui-slider-range {
  background-color: #DB0C15;
  border-radius: 0;
}

.filter_price .ui-slider .ui-slider-handle {
  cursor: pointer;
  background-color: #fff;
  border-radius: 100%;
  border: 0;
  height: 18px;
  top: -8px;
  width: 18px;
  margin: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.price_range {
  color: #292b2c;
}

#flt_price {
  margin-left: 5px;
  font-weight: 600;
}

.shop_container {
  position: relative;
}

.loading_pr {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9;
}

.shop_container .loading_pr {
  top: -7px;
}

.shop_container .mfp-preloader {
  top: 100px;
}

.shop_container:not(.list) .list_product_action_box,
.shop_container.list .product_action_box {
  display: none;
}

.shop_container.list .product_img::before {
  content: normal;
}

.shop_container.list [class*=col-] {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.shop_container.list .grid_item {
  position: static !important;
  transform: none !important;
}

.shop_container.list .product {
  display: inline-block;
  width: 100%;
}

.shop_container.list .product_img {
  float: left;
  width: 100%;
  max-width: 280px;
}

.shop_container.list .product .product_title {
  font-size: 20px;
  margin-bottom: 15px;
}

.shop_container.list .product_info {
  overflow: hidden;
  text-align: left;
  padding: 15px 30px;
}

.shop_container.list .product_price {
  float: left;
}

.shop_container.list .product .rating_wrap {
  display: block;
  float: right;
}

.shop_container.list .pr_desc {
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  min-height: 58px;
  max-height: 84px;
}

.shop_container.list .pr_desc p {
  margin-bottom: 0px;
}

.shop_container.list .product_action_box,
.shop_container.list .product .pr_switch_wrap,
.shop_container.list .product .pr_switch_wrap {
  position: static;
}

.shop_container.list .product .pr_switch_wrap {
  padding: 0;
  opacity: 1;
  visibility: visible;
  margin-bottom: 10px;
}

.shop_container.list .product .product_color_switch span {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.shop_container.list .product_action_box li {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  opacity: 1;
  filter: alpha(opacity=1);
}

.shop_container.list .pr_action_btn {
  display: block;
  margin: 0;
}

.shop_container.list .pr_action_btn li {
  margin: 0 5px 0 0;
  display: inline-block;
  vertical-align: middle;
}

.shop_container.list .pr_action_btn li a {
  box-shadow: none;
}

.shop_container.list .pr_action_btn li a:hover {
  background-color: transparent;
  color: #DB0C15;
}

.shop_container.list .list_product_action_box .pr_action_btn li.add-to-cart a {
  font-size: 16px;
  width: auto;
  height: auto;
  padding: 12px 30px;
  background-color: #DB0C15;
  border: 1px solid #DB0C15;
  color: #fff;
  border-radius: 4px;
}

.shop_container.list .pr_action_btn li.add-to-cart a:hover {
  background-color: transparent;
  color: #DB0C15;
}

.shop_container.list .pr_action_btn li.add-to-cart a i {
  line-height: normal;
  margin-right: 5px;
}

#Additional-info table td:first-child {
  width: 25%;
}

.product_tab_title span {
  font-weight: 600;
}

.tab-content.shop_info_tab {
  margin-top: 40px;
}

.comment_list {
  margin-bottom: 20px;
}

.comments li {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.comment_img {
  float: left;
}

.comment_img img {
  border-radius: 100%;
  max-width: 100px;
}

.customer_meta {
  margin-bottom: 5px;
}

.comment_block .rating_wrap {
  float: right;
}

.description p:last-child {
  margin: 0;
}

.review_author {
  display: block;
  color: #292b2c;
  font-weight: 500;
}

.comment-date {
  font-style: italic;
}

.star_rating > span {
  color: #f6bc3e;
}

.star_rating span {
  cursor: pointer;
  display: inline-block;
}

.star_rating span i {
  font-size: 20px;
}

.star_rating > span.selected i::before {
  font-weight: 900;
}

.releted_product_slider .item {
  margin: 10px 0px 0px;
}

.releted_product_slider .product {
  margin-bottom: 15px;
}

.mfp-ajax-holder .compare_box {
  width: 90%;
  margin: 6px auto;
  background-color: #fff;
  position: relative;
  padding: 30px;
}

.compare_box .table {
  margin: 0;
  display: block;
}

.compare_box table tr td {
  vertical-align: middle;
  width: 27%;
}

.compare_box table tr td.product_name {
  text-transform: capitalize;
}

.compare_box table tr td.row_title {
  width: 270px;
  text-transform: uppercase;
  font-weight: 600;
}

.row_remove a,
.row_remove a:hover {
  color: #ff0000;
}

.in-stock {
  color: #388e3c;
}

.out-stock {
  color: #dc3545;
}

.product_name a {
  font-weight: 600;
}

.row_color .product_color_switch span {
  pointer-events: none;
}

.product-thumbnail img {
  max-width: 100px;
}

.shop_cart_table th,
.shop_cart_table td,
.wishlist_table th,
.wishlist_table td {
  vertical-align: middle;
  text-align: center;
}

.shop_cart_table th.product-name,
.shop_cart_table td.product-name,
.wishlist_table th.product-name,
.wishlist_table td.product-name {
  text-align: left;
  text-transform: capitalize;
}

.shop_cart_table .quantity {
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-remove a i {
  height: 30px;
  width: 30px;
  color: #292b2c;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  font-size: 14px;
}

.product-remove a i:hover {
  background-color: #f00;
  color: #fff;
}

.shop_cart_table td.product-price,
.shop_cart_table td.product-subtotal {
  font-weight: 600;
}

.shop_cart_table .table {
  margin: 0;
}

.toggle_info {
  padding: 20px;
  background-color: #f7f7f7;
}

.toggle_info span {
  padding-left: 25px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.toggle_info i {
  margin-right: 10px;
  color: #DB0C15;
  position: absolute;
  left: 0;
  top: 5px;
}

.login_form .panel-body,
.coupon_form .panel-body {
  border: 1px solid #ddd;
  padding: 30px;
  margin-top: 30px;
}

label.label_info {
  color: #292b2c !important;
  font-weight: 600;
}

.ship_detail {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}

.product-qty {
  font-weight: 600;
}

.product-subtotal {
  font-weight: 600;
}

.order_review {
  background-color: #f7f8fb;
  padding: 30px;
}

.order_table thead th {
  border-bottom-width: 1px;
  font-weight: 600;
}

.order_table tfoot th {
  font-weight: 600;
}

.order_table tbody td {
  border: 0;
}

.payment_method {
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
}

.address_option .custome-radio,
.payment_option .custome-radio {
  margin-bottom: 10px;
}

.address-text,
.payment-text {
  display: none;
  margin-top: 5px;
  margin-bottom: 0;
}

.address_option .custome-radio:first-child .address-text,
.payment_option .custome-radio:first-child .payment-text {
  display: block;
}

.address_option .custome-radio .form-check-label,
.payment_option .custome-radio .form-check-label {
  color: #292b2c;
  font-weight: 600;
}

.wishlist_table .table td {
  border-bottom: 1px solid #dee2e6;
}

.wishlist_table .badge {
  line-height: normal;
  vertical-align: middle;
  padding: 2px 10px;
}

#load-more {
  transition: none;
}

.grid_item_hide .product,
.grid_item_hide .blog_post {
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}

.grid_item_hide {
  opacity: 0;
  visibility: hidden;
  height: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  transform: scale(0);
}

.loading {
  background: url("../images/loading.gif") center center no-repeat;
  font-size: 0 !important;
  background-color: #000 !important;
  border: 0;
  height: 51px;
  width: 50px;
  padding: 0;
}

.loading::after,
.loading::before {
  content: normal;
}

.load_more_wrap {
  margin-top: 20px;
}

.load_more_wrap span {
  display: inline-block;
  margin: 0;
  line-height: 1;
  font-size: 14px;
}

.order_complete i {
  color: #DB0C15;
  font-size: 80px;
  margin-bottom: 20px;
}

.empty_icon {
  background-color: #dfffed;
  display: inline-block;
  height: 100px;
  width: 100px;
  line-height: 100px;
  border-radius: 100%;
  margin-bottom: 25px;
}

.card-body .comments {
  margin-top: 5px;
}

/*===================================*
  23.END SHOP DESIGN
*===================================*/
/*===================================*
  15.START BREADCRUMB STYLE
*===================================*/
.breadcrumb_section {
  padding: 20px 0;
  width: 100%;
}

.breadcrumb_section.page-title-mini {
  padding: 20px 0;
}

.page-title-mini .page-title h1 {
  font-size: 24px;
  font-weight: 500;
  color: #242A67;
}

.page-title-mini .breadcrumb li {
  font-size: 14px;
}

.header_wrap.transparent_header + .breadcrumb_section {
  padding-top: 200px;
}

.header_wrap.transparent_header.header_with_topbar + .breadcrumb_section {
  padding-top: 250px;
}

.page-title h1 {
  margin: 0;
  text-transform: capitalize;
  font-weight: bold;
  line-height: normal;
}

.page_title_light *, .page_title_light .breadcrumb-item, .page_title_light .breadcrumb-item::before {
  color: #fff;
}

.breadcrumb {
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.breadcrumb-item {
  align-items: baseline;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  font-family: "themify";
  vertical-align: middle;
  font-size: 10px;
}

.page-title + .breadcrumb {
  margin-top: 15px;
}

.page-title + span {
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.page_title_video {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
}

.page_title_video video {
  object-fit: cover;
  width: 100%;
}

.breadcrumb-item a i {
  font-size: 26px;
  line-height: 1;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -5px;
  display: inline-block;
}

.page-title-video {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -3;
}

.page-title-video video {
  object-fit: cover;
  width: 100%;
}

/*===================================*
  15.END BREADCRUMB STYLE
*===================================*/
/*===================================*
  11.START FOOTER STYLE
*===================================*/
footer {
  overflow: hidden;
  background-image: url("../../img/footer-bg-1.jpg");
}

.footer_dark {
  background-color: #202325;
  position: relative;
}

.footer_top {
  padding: 100px 0 70px;
}

.footer_logo {
  margin-bottom: 20px;
}

footer p {
  font-size: 14px;
}

footer .widget {
  margin-bottom: 30px;
}

footer .widget_title {
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 700;
}

.widget_links li {
  list-style: none;
  padding-bottom: 10px;
}

.widget_links li:last-child {
  padding-bottom: 0;
}

.widget_links li a {
  font-size: 14px;
  color: #687188;
  font-weight: 300;
}

.contact_info > li {
  margin-bottom: 15px;
  list-style: none;
}

.contact_info > li:last-child {
  margin-bottom: 0px;
}

.contact_info li i {
  display: inline-block;
  margin-right: 10px;
  margin-top: 4px;
  font-size: 18px;
  vertical-align: top;
  color: #fff;
}

.contact_info li span {
  float: left;
  margin-right: 10px;
  max-width: 70px;
  width: 100%;
  color: #292b2c;
}

.contact_info span + * {
  overflow: hidden;
  color: #687188;
  margin: 0;
  font-size: 14px;
  line-height: 26px;
}

.contact_info i + * {
  overflow: hidden;
  font-size: 14px;
  color: #687188;
  line-height: 26px;
  margin: 0;
  vertical-align: middle;
  max-width: 78%;
  display: inline-block;
}

.contact_info_light li i {
  border-color: #fff;
  color: #fff;
}

.contact_info_light li span,
.contact_info_light li a {
  color: #fff;
}

.contact_info_style2 i + * {
  font-size: 18px;
}

.contact_info_style2 li i {
  margin-right: 8px;
  margin-top: 2px;
  font-size: 22px;
  color: #FF324D;
}

.bottom_footer {
  padding: 30px 0;
  position: relative;
}

.footer_link li {
  display: inline-block;
  position: relative;
}

.footer_link li a {
  font-size: 14px;
  padding: 0 5px;
  color: #687188;
}

.footer_dark h1, .footer_dark h2, .footer_dark h3, .footer_dark h4, .footer_dark h5, .footer_dark h6, .footer_dark p, .footer_dark a, .footer_dark .widget_links li a, .footer_dark .footer_link li a, .footer_dark .post_content a {
  color: #fff;
}

.footer_dark a:hover, .footer_dark .widget_links li a:hover, .widget_links li a:hover, .footer_dark .footer_link li a:hover, .footer_link li a:hover {
  color: #FF324D;
}

.widget_contact_form {
  background-color: #fff;
  padding: 20px 30px 30px;
  margin-top: -190px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bottom_footer .social_icons li {
  padding-bottom: 0;
}

.footer_payment li,
.app_list li {
  display: inline-block;
  list-style: none;
}

.footer_call {
  border-radius: 40px;
  padding: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.footer_call * {
  margin: 0;
  font-size: 22px;
}

.footer_call i {
  margin-right: 10px;
}

.bottom-line {
  padding: 10px 0;
  background-color: #171b41;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

.signature a {
  color: #fff;
  background-image: url(../../img/apaone-logo-white.png);
  background-repeat: no-repeat;
  padding-left: 20px;
  background-position: 0;
  text-decoration: none;
}

.sale-banner__text {
  background-color: rgba(36, 42, 103, 0.8);
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  position: absolute;
  left: 0;
  height: 70%;
  top: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.35s ease-in-out;
}

.borderd-sale-banner {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.12);
}
.borderd-sale-banner a {
  position: relative;
  display: block;
}
.borderd-sale-banner a:hover .sale-banner__text, .borderd-sale-banner a:focus .sale-banner__text {
  width: 80%;
}
.borderd-sale-banner a img {
  border: 20px solid #fff;
}

/*===================================*
  10.START NEWLETTER STYLE
*===================================*/
.newsletter_small {
  padding: 70px 0;
}

.newsletter_form form,
.newsletter_form2 form {
  position: relative;
}

.rounded-input {
  border-radius: 50px;
}

.newsletter_form input,
.newsletter_form input:focus,
.newsletter_form2 input,
.newsletter_form2 input:focus {
  border: 0;
}

.newsletter_form input.form-control-sm {
  height: 50px;
  padding: 10px 140px 10px 20px;
}

.newsletter_form button {
  position: absolute;
  right: 0px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.newsletter_form2 input,
.newsletter_form2 input:focus {
  height: 59px;
  padding: 10px 140px 10px 20px;
}

.newsletter_form2 button {
  position: absolute;
  right: 5px;
  top: 4px;
}

.newsletter_box {
  padding: 70px 50px;
  background-color: #fff;
  position: relative;
}

.newsletter_box::before {
  content: "";
  position: absolute;
  left: 10px;
  right: 0px;
  bottom: 0px;
  top: 10px;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 0;
}

.newsletter_wrap {
  position: relative;
  margin: 30px 0;
}

.newsletter_wrap::before {
  content: "";
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: -15px;
  top: -15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: -1;
  background-color: #fff;
}

.newsletter_wrap::after {
  content: "";
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: -30px;
  top: -30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: -2;
  background-color: #fff;
}

.newsletter_form .btn-send {
  background-color: #FF324D;
  color: #fff;
  font-size: 20px;
  right: 5px;
  border: 0;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
}

.newsletter_form .btn-send2 {
  background-color: transparent;
  color: #FF324D;
  font-size: 20px;
  right: 5px;
  width: 50px;
  height: 40px;
  line-height: 40px;
  border-left: 1px solid #ddd;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  padding: 0 0 0 5px;
}

.input_tran_white input, .input_tran_white input:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  box-shadow: none;
}

.input_tran_white input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.input_tran_white input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}

.input_tran_white input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.input_tran_white input::-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.input_tran_white input::placeholder {
  color: #ffffff;
  opacity: 1;
}

.newsletter_text {
  position: relative;
  padding-left: 80px;
}

.newsletter_text p:last-child {
  margin: 0;
}

.newsletter_text::before {
  content: "";
  position: absolute;
  left: 0;
  color: #fff;
  font-family: linearicons;
  font-size: 65px;
  line-height: normal;
  opacity: 0.4;
}

.login_wrap {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.login_register_wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.login_footer {
  margin-bottom: 20px;
  margin-top: 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.login_footer a {
  color: #687188;
}

.different_login {
  text-align: center;
  position: relative;
  margin: 20px 0;
}

.different_login span {
  background-color: #fff;
  padding: 0 15px;
  position: relative;
  text-transform: uppercase;
}

.different_login::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid #ddd;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-login li {
  margin: 0px 5px 0;
  display: inline-block;
}

.btn-login li a {
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  display: block;
}

.btn-login li a i {
  margin-right: 10px;
  font-size: 18px;
  margin-left: 0;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:hover {
  color: #fff;
  background: #344e86;
  border-color: #344e86;
}

.btn-google {
  background: #d85040;
  border-color: #d85040;
}

.btn-google:hover {
  color: #fff;
  background: #d33d2b;
  border-color: #d33d2b;
}

.dashboard_menu {
  box-shadow: 0 0px 4px 0 #e9e9e9;
}

.dashboard_menu .nav-tabs li.nav-item a {
  text-align: left;
  padding: 12px 20px;
  border-radius: 0;
  border-bottom: 1px solid #efefef;
  color: #2b2f4c;
}

.dashboard_menu .nav-tabs li.nav-item a.active {
  background-color: #DB0C15;
  color: #fff;
}

.dashboard_menu .nav-tabs li.nav-item a i {
  margin-right: 8px;
  vertical-align: middle;
}

.dashboard_content {
  margin: 0;
}

.dashboard_content .card-header {
  background-color: transparent;
  border-color: #f0f0f0;
}

.dashboard_content .card-header * {
  margin: 0;
}

.dashboard_content .card {
  border-radius: 0;
  border: 0;
  box-shadow: 0 0px 4px 0 #e9e9e9;
}

.dashboard_content .card-body p:last-child {
  margin: 0;
}

.dashboard_content .table {
  margin: 0;
}

.dashboard_content .table thead th {
  border: 0;
  padding-top: 0;
  font-weight: 600;
}

.dashboard_content .table tbody tr:last-child td {
  padding-bottom: 0;
}

.dashboard_content .table tbody tr td {
  vertical-align: middle;
  white-space: nowrap;
}

.dashboard_content label {
  color: #333;
}

.dashboard_content label .required {
  color: #ff0000;
}

.contact_wrap li {
  list-style: none;
}

.contact_wrap li {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  margin-bottom: 25px;
  -ms-flex-align: center;
  align-items: center;
}

.contact_icon {
  margin-right: 20px;
}

.contact_icon i {
  font-size: 34px;
  color: #DB0C15;
}

.contact_text span {
  color: #333;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
}

.contact_text p:last-child {
  margin-bottom: 0;
}

.contact_text a {
  color: #687188;
  line-height: 28px;
}

.contact_text a:hover {
  color: #DB0C15;
}

.contact_style1 {
  text-align: center;
  padding: 20px;
  border: 2px solid #eee;
  margin-bottom: 30px;
}

.contact_style1 .contact_text span {
  margin-bottom: 5px;
}

.contact_style1 .contact_icon {
  margin-right: 0;
  margin-bottom: 10px;
}

.contact_style2 {
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  border-radius: 10px;
}

.contact_style2 .contact_icon {
  background-color: #DFFFED;
  margin: 0 0 5px;
  height: 90px;
  width: 90px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  border-radius: 100%;
  position: relative;
  z-index: 1;
}

.contact_style2 .contact_icon i {
  line-height: 90px;
}

.contact_style3 {
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  border-radius: 10px;
}

.contact_style3 .contact_icon {
  border: 1px solid #DB0C15;
  margin: 0 0 5px;
  height: 80px;
  width: 80px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  border-radius: 100%;
  position: relative;
  z-index: 1;
}

.contact_style3 .contact_icon i {
  line-height: 78px;
  transition: all 0.5s ease-in-out;
}

.contact_style3 .contact_icon::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #DB0C15;
  transition: all 0.5s ease-in-out;
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  border-radius: 100%;
  z-index: -1;
}

.contact_style3:hover .contact_icon::before {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.contact_style3:hover .contact_icon i {
  color: #fff;
}

.contact_box {
  position: relative;
  padding: 50px 0px;
  display: table;
  width: 100%;
}

.contact_form {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-width: 500px;
  background-color: #fff;
  padding: 30px;
  float: right;
  margin-right: 30px;
  position: relative;
  z-index: 9;
}

.contact_box .map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.contact_box .map iframe {
  height: 100%;
}

.map iframe {
  height: 460px;
  width: 100%;
  border: 0;
  display: block;
}

.contact_map {
  height: 400px;
}

.contact_map2 {
  height: 100%;
}

.map1 {
  height: 460px;
}

blockquote {
  font-size: 20px;
  line-height: 34px;
}

blockquote p {
  line-height: 34px;
}

blockquote p:last-child {
  margin: 0;
}

.blockquote_style1 {
  padding-left: 20px;
  border-left: 3px solid #ddd;
  margin: 30px 0;
}

.blockquote_style1 p {
  color: #292B2C;
}

.blockquote_style2 {
  background-color: #FF324D;
  padding: 20px;
  position: relative;
}

.blockquote_style2 * {
  color: #fff;
  font-size: 16px;
  line-height: 28px;
}

.blockquote_style2::before {
  content: "";
  position: absolute;
  left: 20px;
  top: 10px;
  color: #fff;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 70px;
  opacity: 0.3;
  line-height: normal;
}

.blockquote_style3 {
  background-color: #FAFAFA;
  padding: 20px;
  border-left: 2px solid #FF324D;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 28px;
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.blockquote_style3::before {
  content: "";
  position: absolute;
  left: 20px;
  top: 10px;
  color: #000;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 70px;
  opacity: 0.05;
  line-height: normal;
  pointer-events: none;
}

.blockquote_style3 p {
  line-height: 28px;
}

.blockquote_style4 {
  text-align: center;
  padding: 20px;
  background-color: #f7f7f7;
  margin-bottom: 20px;
  font-size: 18px;
  position: relative;
}

.blockquote_style4::before {
  content: "";
  position: absolute;
  left: 50px;
  top: 10px;
  color: #000;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 70px;
  opacity: 0.05;
  line-height: normal;
}

.blockquote_style4,
.blockquote_style4 p {
  color: #222;
}

.blog_post_footer {
  padding-bottom: 30px;
  padding-top: 15px;
}

.mdtp__wrapper {
  bottom: auto;
  top: 50%;
  -moz-transform: translateX(-50%) translateY(-50%) scale(1);
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.modal {
  padding-right: 0 !important;
}

.page_soon {
  font-size: 10px;
  color: #fff;
  padding: 0px 5px;
  border-radius: 20px;
  background-color: #28A745;
  text-transform: uppercase;
  font-weight: 600;
}

.alert .close {
  font-weight: normal;
  margin-left: 10px;
}

.alert_style1 {
  border-radius: 0;
  border-left: 3px solid;
  padding: 20px;
  position: relative;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
}

.alert > i {
  margin-right: 5px;
  font-size: 18px;
  vertical-align: middle;
}

.alert_style2 {
  border-radius: 20px;
  padding: 20px 20px;
  background-color: #fff;
  border-left: 3px solid;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-top: 0;
  border-bottom: 0;
  border-right: 3px solid;
}

.button_group .btn {
  margin-bottom: 20px;
}

.trading_img {
  margin-top: -40px;
}

.term_conditions h6 {
  margin-bottom: 15px;
}

.term_conditions ul,
.term_conditions ol {
  padding-left: 15px;
}

.term_conditions p,
.term_conditions li {
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 28px;
}

.accordion .card .card-header {
  background-color: transparent;
  padding: 0px;
  margin: 0;
}

.accordion .card-header a {
  padding: 15px 40px 15px 15px;
  display: block;
  line-height: normal;
}

.accordion .card-body p:last-child {
  margin: 0;
}

.card-body p {
  margin-bottom: 15px;
}

.accordion_style1.accordion .card {
  background-color: transparent;
  margin-bottom: 15px;
  border-radius: 0;
  border: 0;
}

.accordion_style1.accordion .card:last-child {
  margin-bottom: 0;
}

.accordion_style1 .card-body {
  padding: 15px 0 10px 0;
}

.accordion.accordion_style1 .card-header a {
  padding-left: 0;
  padding-top: 0;
  font-weight: 600;
}

.accordion_style1 .card-header a::after {
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  right: 15px;
  top: 0px;
  font-weight: 900;
  content: "";
}

.accordion_style1 .card-header a[aria-expanded=false]::after {
  content: "";
}

/* -------------------------------------------------
  (min-width: 1680px)
---------------------------------------------------*/
@media only screen and (min-width: 1680px) {
  .custom-container {
    max-width: 1650px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
/* -------------------------------------------------
      (max-width: 1250px)
    ---------------------------------------------------*/
@media only screen and (max-width: 1250px) {
  .nav_style1.owl-theme .owl-nav .owl-next {
    right: -40px;
  }

  .nav_style1.owl-theme .owl-nav .owl-prev {
    left: -40px;
  }
}
/* -------------------------------------------------
      (min-width: 991px) and (max-width: 1199px)
    ---------------------------------------------------*/
@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .nav_cat > ul > li > a,
.nav_cat > ul > li > ul > li > a {
    font-size: 14px;
    padding: 5px 10px;
  }

  .countdown_style4 .countdown_box .countdown-wrap {
    padding: 5px;
  }

  .deal_wrap .countdown_time .cd_text {
    font-size: 12px;
  }
}
/* -------------------------------------------------
      (min-width: 767px) and (max-width: 1199px)
    ---------------------------------------------------*/
@media only screen and (min-width: 767px) and (max-width: 1199px) {
  .shopping_info .icon_box_style2 {
    text-align: center;
    display: block;
  }

  .shopping_info .icon_box_style2 .icon {
    float: none;
    margin: 0 auto 10px;
  }
}
/* -------------------------------------------------
      (min-width: 767px) and (max-width: 991px)
    ---------------------------------------------------*/
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .fb_info {
    top: 5px;
    left: 5px;
  }

  .fb_info2 {
    left: 15px;
  }
}
/* -------------------------------------------------
      max-width: 1199px
    ---------------------------------------------------*/
@media only screen and (max-width: 1199px) {
  .h1,
h1 {
    font-size: 34px;
  }

  .h2,
h2 {
    font-size: 30px;
  }

  .h3,
h3 {
    font-size: 26px;
  }

  .h4,
h4 {
    font-size: 22px;
  }

  .h5,
h5 {
    font-size: 18px;
  }

  .section {
    padding: 80px 0;
  }

  .section.small_pt,
.footer_top.small_pt {
    padding-top: 40px;
  }

  .section.small_pb,
.footer_top.small_pb {
    padding-bottom: 40px;
  }

  .section.pb_70,
.pb_70 {
    padding-bottom: 50px;
  }

  .section.pb_85,
.pb_85 {
    padding-bottom: 65px;
  }

  .section.pb_20,
.pb_20,
.footer_top.pb_20 {
    padding-bottom: 10px;
  }

  .large_divider {
    height: 80px;
  }

  .medium_divider {
    height: 40px;
  }

  .small_divider {
    height: 20px;
  }

  .shop_title {
    font-size: 32px;
  }

  .banner_half_content {
    padding: 100px 0 80px;
  }

  .banner_section:not(.full_screen),
.banner_section:not(.full_screen) .carousel-item,
.banner_section:not(.full_screen) .banner_content_wrap,
.banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
    height: 600px;
  }

  .banner_section.slide_medium,
.banner_section.slide_medium .carousel-item,
.banner_section.slide_medium .banner_content_wrap,
.banner_section.slide_medium .banner_content_wrap .carousel-item,
.banner_section.shop_el_slider,
.banner_section.shop_el_slider .carousel-item,
.banner_section.shop_el_slider .banner_content_wrap,
.banner_section.shop_el_slider .banner_content_wrap .carousel-item {
    height: 400px;
  }

  .banner_content h2 {
    font-size: 52px;
  }

  .banner_content1 h2 {
    font-size: 64px;
  }

  .banner_content2 h2 {
    font-size: 60px;
  }

  .header_wrap.transparent_header + .breadcrumb_section {
    padding-top: 150px;
  }

  .header_wrap.transparent_header.header_with_topbar + .breadcrumb_section {
    padding-top: 200px;
  }

  .breadcrumb_section {
    padding: 100px 0;
  }

  .footer_top {
    padding: 80px 0 50px;
  }

  .blog_meta li {
    margin-right: 10px;
  }

  .blog_meta a {
    font-size: 14px;
  }

  .blog_title {
    line-height: 26px;
  }

  .newsletter_small {
    padding: 60px 0;
  }

  .pr_content {
    padding: 0 30px;
  }

  .widget_contact_form {
    margin-top: -160px;
  }

  .icon_box_style4 {
    padding: 30px;
  }

  .compare_box table {
    width: 1050px;
  }

  .shop_bn_content {
    padding: 30px 25px;
  }

  .shop_bn_content .btn {
    padding: 8px 25px;
  }

  .tab-content.shop_info_tab {
    margin-top: 30px;
  }

  .single_post .blog_title {
    font-size: 26px;
  }

  .icon_box_style1 {
    padding: 0 20px;
  }

  .product_search_form {
    max-width: 480px;
  }

  #navCatContent {
    padding: 0;
  }

  #navCatContent li a i {
    font-size: 20px;
    line-height: 28px;
  }

  .more_categories {
    padding: 10px 15px;
    margin-top: 0;
  }

  .nav_style1.owl-theme .owl-nav .owl-next {
    right: 0;
  }

  .nav_style1.owl-theme .owl-nav .owl-prev {
    left: 0px;
  }

  .icon_box.icon_box_style3 {
    padding: 15px;
  }

  .icon_box_style3 .icon {
    margin-right: 15px;
  }

  .icon_box_style3 .icon i {
    font-size: 32px;
  }

  .furniture_banner .single_bn_title1 {
    font-size: 70px;
  }

  .shop_container.list .pr_desc {
    min-height: 56px;
    max-height: 56px;
  }

  .add-to-cart .btn {
    padding: 10px 20px;
  }

  .shop_banner2 {
    height: 185px;
  }

  .el_banner2 .el_img img {
    max-width: 100px;
  }

  .el_banner1 .el_title h6 {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .el_banner1 .el_title span {
    font-size: 20px;
  }

  .el_banner2 .el_title h6 {
    font-size: 14px;
    margin-bottom: 0;
  }

  .el_banner2 .el_title span {
    font-size: 12px;
  }

  .deal_wrap .product_img {
    max-width: 200px;
  }

  .deal_content {
    padding: 20px 20px 20px 0;
  }

  #navCatContent ul li .dropdown-menu {
    min-width: 750px;
  }

  .mfp-ajax-holder .compare_box {
    width: 100%;
    padding: 30px 20px;
  }
}
/* -------------------------------------------------
      min-width: 992px
    ---------------------------------------------------*/
@media only screen and (min-width: 992px) {
  .navbar-toggler {
    display: none;
  }

  .navbar .navbar-nav li > .dropdown-menu,
#navCatContent ul > li .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    margin-top: 20px;
    pointer-events: none;
  }

  .navbar .navbar-nav li:hover > .dropdown-menu,
#navCatContent ul > li:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    margin-top: 0px;
    pointer-events: auto;
  }

  .cart_dropdown:hover > .dropdown-menu {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    margin-top: 0px !important;
  }

  .cart_dropdown:hover > .dropdown-menu.cart_box {
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
  }

  .navbar .navbar-nav li:hover > .dropdown-menu .mega-menu ul li .dropdown-menu {
    margin-top: 10px;
  }

  .navbar .navbar-nav .dropdown-menu .dropdown-menu {
    left: 100%;
    top: 0;
  }

  .navbar .navbar-nav .dropdown-menu.dropdown-reverse .dropdown-menu {
    left: auto;
    right: 100%;
    margin-left: 0;
  }

  .navbar .navbar-nav .dropdown-menu.dropdown-reverse li:hover > .dropdown-item::after {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .dropdown-menu.dropdown-reverse {
    left: auto;
    right: 0;
  }

  .dd_dark_skin .navbar .navbar-nav .dropdown-item,
.dd_dark_skin .navbar .navbar-nav .dropdown-header,
.sticky_dd_dark_skin.header_wrap.nav-fixed .navbar-nav .dropdown-menu li a,
.sticky_dd_dark_skin.header_wrap.nav-fixed .navbar-nav .dropdown-header,
.dd_dark_skin .cart_list a,
.dd_dark_skin .cart_quantity,
.dd_dark_skin .cart_total,
.sticky_dd_dark_skin.header_wrap.nav-fixed .cart_list a,
.sticky_dd_dark_skin.header_wrap.nav-fixed .cart_quantity,
.sticky_dd_dark_skin.header_wrap.nav-fixed .cart_total {
    color: #333;
  }

  .dd_dark_skin .item_remove,
.sticky_dd_dark_skin.header_wrap.nav-fixed .item_remove {
    color: #333 !important;
  }

  .dd_dark_skin .navbar-nav .dropdown-menu,
.dd_dark_skin .navbar-nav .dropdown-menu .dropdown-menu,
.dd_dark_skin .navbar-nav .dropdown-menu .mega-menu .dropdown-menu,
.sticky_dd_dark_skin.header_wrap.nav-fixed .navbar-nav .dropdown-menu,
.header_wrap:not([class*=bg_]):not([class*=bg-]).sticky_dd_dark_skin.nav-fixed .navbar-nav .dropdown-menu,
.header_wrap:not([class*=bg_]):not([class*=bg-]).sticky_dd_dark_skin.nav-fixed .navbar-nav .dropdown-menu .dropdown-menu,
.header_wrap:not([class*=bg_]):not([class*=bg-]).sticky_dd_dark_skin.nav-fixed .navbar-nav .dropdown-menu .mega-menu .dropdown-menu {
    background-color: #fff;
    box-shadow: 0 13px 42px 11px rgba(0, 0, 0, 0.05);
  }

  .dd_dark_skin .mega-menu-col,
.sticky_dd_dark_skin.nav-fixed .mega-menu-col,
.sticky_dd_dark_skin.nav-fixed .navbar-nav .mega-menu-col,
.dd_dark_skin .cart_list li,
.sticky_dd_dark_skin.nav-fixed .cart_list li {
    border-color: #ddd;
  }

  .dd_light_skin .navbar .navbar-nav li > .dropdown-item,
.dd_light_skin .navbar .navbar-nav .dropdown-header,
.sticky_dd_light_skin.header_wrap.nav-fixed .navbar-nav .dropdown-menu li a,
.sticky_dd_light_skin.header_wrap.nav-fixed .navbar-nav .dropdown-header,
.dd_light_skin .cart_list a,
.dd_light_skin .cart_quantity,
.dd_light_skin .cart_total,
.sticky_dd_light_skin.header_wrap.nav-fixed .cart_list a,
.sticky_dd_light_skin.header_wrap.nav-fixed .cart_quantity,
.sticky_dd_light_skin,
.sticky_dd_light_skin.header_wrap.nav-fixed .cart_total {
    color: #fff;
  }

  .dd_light_skin .item_remove,
.sticky_dd_light_skin.header_wrap.nav-fixed .item_remove {
    color: #fff !important;
  }

  .dd_light_skin .cart_list li,
.sticky_dd_light_skin.nav-fixed .cart_list li {
    border-color: #3f3f3f;
  }

  .dd_light_skin .navbar-nav .dropdown-menu,
.sticky_dd_light_skin.nav-fixed .navbar-nav .dropdown-menu {
    background-color: #252a2c;
    border-color: #252a2c;
  }

  .dd_light_skin .navbar-nav .dropdown-menu .mega-menu .dropdown-menu,
.dd_light_skin .navbar-nav .dropdown-menu .dropdown-menu,
.sticky_dd_light_skin.nav-fixed .navbar-nav .dropdown-menu .mega-menu .dropdown-menu {
    background-color: #303537;
  }

  .dd_light_skin .mega-menu-col,
.sticky_dd_light_skin.nav-fixed .mega-menu-col,
.sticky_dd_light_skin.nav-fixed .navbar-nav .mega-menu-col {
    border-right: 1px solid #3f3f3f;
  }

  .header_banner_title,
.header_banner_text,
.header_banner_content a,
.header_banner_content .shop_subtitle,
.header_banner_content .shop_title {
    opacity: 0;
    -moz-transform: translateY(25%);
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
    -webkit-transition: ease transform 200ms, ease opacity 200ms;
    transition: ease transform 200ms, ease opacity 200ms;
  }

  .header_banner_title,
.header_banner_content .shop_subtitle {
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
  }

  .header_banner_text,
.header_banner_content .shop_title {
    -webkit-transition-delay: 500ms;
    transition-delay: 500ms;
  }

  .header_banner_content a {
    -webkit-transition-delay: 600ms;
    transition-delay: 600ms;
  }

  .header_banner_content a:hover {
    transition: all 0.5s ease-in-out;
  }

  .header_banner_content .banner_img img {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    -webkit-transition-delay: 500ms;
    transition-delay: 500ms;
    transition: all 0.8s ease-in-out;
    position: relative;
    z-index: -1;
  }

  .navbar .navbar-nav li:hover > .dropdown-menu .header_banner_title,
.navbar .navbar-nav li:hover > .dropdown-menu .header_banner_text,
.navbar .navbar-nav li:hover > .dropdown-menu .header_banner_content a,
.navbar .navbar-nav li:hover > .dropdown-menu .header_banner_content .shop_title,
.navbar .navbar-nav li:hover > .dropdown-menu .header_banner_content .shop_subtitle {
    opacity: 1;
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }

  .navbar .navbar-nav li:hover > .dropdown-menu .header_banner_content .banner_img img {
    -webkit-filter: blur(0);
    filter: blur(0);
  }

  .banner_content_inner {
    padding: 30px;
  }

  .nav_cat ul li .dropdown-menu {
    min-width: 700px;
  }

  .pr_search_icon {
    display: none;
  }
}
/* -------------------------------------------------
      max-width: 991px
    ---------------------------------------------------*/
@media only screen and (max-width: 991px) {
  .md-height-300 {
    height: 300px !important;
  }

  .heading_s1,
.heading_s4 {
    margin-bottom: 20px;
  }

  .header_wrap nav.navbar {
    display: table;
    width: 100%;
  }

  .header_wrap .navbar .navbar-nav.attr-nav {
    display: block;
    float: right;
  }

  .header_wrap .navbar-collapse {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 999;
  }

  .navbar .navbar-collapse .navbar-nav {
    max-height: 80vh;
    overflow-y: auto;
  }

  .header_wrap .navbar-collapse.mobile_side_menu {
    position: fixed;
    right: -280px;
    display: block;
    top: 0;
    bottom: 0;
    width: 270px;
    left: auto;
    height: auto !important;
    transition: all 0.3s ease-in-out;
  }

  .header_wrap .navbar-collapse.mobile_side_menu.show {
    right: 0;
  }

  .header_wrap .navbar-collapse.mobile_side_menu .navbar-nav {
    height: 100%;
    max-height: 100vh;
  }

  .navbar .navbar-nav > li > .nav-link,
.nav-fixed .navbar .navbar-nav > li > .nav-link {
    padding: 10px 10px;
  }

  .navbar .navbar-nav.attr-nav li {
    float: left;
  }

  .navbar .navbar-nav.attr-nav li .nav-link {
    padding: 20px 10px;
  }

  .navbar .navbar-nav.attr-nav li .nav-link.search_trigger,
.navbar .navbar-nav.attr-nav li .nav-link.cart_trigger {
    padding: 16px 8px;
  }

  .cart_count,
.wishlist_count {
    top: 0;
  }

  .navbar .navbar-collapse .navbar-nav:not([class*=bg_]):not([class*=bg-]) {
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }

  .light_skin.transparent_header .navbar .navbar-collapse .navbar-nav:not([class*=bg_]):not([class*=bg-]),
.light_skin .navbar .navbar-collapse .navbar-nav:not([class*=bg_]):not([class*=bg-]) {
    background-color: #252a2c;
  }

  .sticky_dark_skin.nav-fixed.header_wrap .navbar .navbar-collapse .navbar-nav:not([class*=bg_]):not([class*=bg-]),
.dark_skin.header_wrap .navbar .navbar-collapse .navbar-nav:not([class*=bg_]):not([class*=bg-]) {
    background-color: #fff;
    box-shadow: 0 13px 42px 11px rgba(0, 0, 0, 0.05);
    border: 1px solid #e6e6e6;
  }

  .navbar-nav .navbar-collapse .dropdown-menu,
.header_wrap:not([class*=bg_]):not([class*=bg-]).sticky_light_skin.nav-fixed .navbar-nav .navbar-collapse .dropdown-menu,
.sticky_dark_skin.nav-fixed .navbar-nav .navbar-collapse .dropdown-menu,
.light_skin .navbar-nav .dropdown-menu .mega-menu .dropdown-menu,
.dark_skin:not([class*=bg_]):not([class*=bg-]) .navbar-nav .navbar-collapse .dropdown-menu,
.header_wrap:not([class*=bg_]):not([class*=bg-]).sticky_light_skin.nav-fixed .navbar-nav .dropdown-menu .mega-menu .dropdown-menu,
.header_wrap:not([class*=bg_]):not([class*=bg-]).sticky_light_skin.nav-fixed .navbar-nav .navbar-collapse .dropdown-menu .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
    border: 0;
  }

  .dark_skin:not([class*=bg_]):not([class*=bg-]).sticky_light_skin.nav-fixed .navbar-collapse .navbar-nav {
    background-color: #252a2c;
    border-color: #252a2c;
  }

  .light_skin .mega-menu {
    padding: 0;
  }

  .hover_menu_style1 .navbar-collapse .navbar-nav > li > a,
.hover_menu_style1.nav-fixed .navbar .navbar-collapse .navbar-nav > li > .nav-link {
    padding: 10px;
    margin: 0;
  }

  .hover_menu_style1 .navbar-collapse .navbar-nav > li > a::before {
    content: normal;
  }

  .header_banner,
.header_banner_wrap {
    display: none;
  }

  .dropdown-menu .dropdown-toggler::after {
    right: 0px;
  }

  .menu_banners {
    display: none;
  }

  .search_wrap {
    max-width: 100%;
    margin: 0 30px;
  }

  .dropdown-toggle::after {
    float: right;
  }

  .navbar-nav > li > .dropdown-menu,
.navbar .navbar-nav li.dropdown-mega-menu .dropdown-menu {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mega-menu-col {
    padding: 0px;
    border-right: 0 !important;
  }

  .dropdown-toggler::after {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .navbar .attr-nav li.nav-btn {
    margin-top: 8px;
  }

  .navbar .navbar-nav.attr-nav li .nav-link.search_trigger [class*=linearicons-],
.navbar .navbar-nav.attr-nav li .nav-link.cart_trigger [class*=linearicons-] {
    margin-top: 5px;
    display: inline-block;
  }

  .navbar .navbar-nav .dropdown-item {
    padding: 8px 10px 8px 10px;
  }

  .navbar .navbar-nav .dropdown-header {
    padding: 10px;
  }

  .sidetoggle_icon::after {
    bottom: -8px;
  }

  .sidetoggle_icon::before {
    top: -6px;
  }

  .nav_cat.collapse:not(.show) {
    display: block;
  }

  .banner_section:not(.full_screen),
.banner_section:not(.full_screen) .carousel-item,
.banner_section:not(.full_screen) .banner_content_wrap,
.banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
    height: 600px;
  }

  .banner_section:not(.full_screen),
.banner_section:not(.full_screen) .carousel-item,
.banner_section:not(.full_screen) .banner_content_wrap,
.banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
    height: 500px;
  }

  .banner_section.full_screen.banner_half_content,
.banner_section:not(.full_screen).banner_half_content,
.banner_section:not(.full_screen).banner_half_content .banner_content_wrap {
    height: auto;
  }

  .banner_section.slide_medium,
.banner_section.slide_medium .carousel-item,
.banner_section.slide_medium .banner_content_wrap,
.banner_section.slide_medium .banner_content_wrap .carousel-item {
    height: 350px;
  }

  .banner_content h2 {
    font-size: 44px;
  }

  .banner_content1 h2 {
    font-size: 54px;
  }

  .banner_content2 h2 {
    font-size: 50px;
  }

  .banner_center_content {
    margin-top: 0;
  }

  .full_screen,
.full_screen .carousel-item {
    min-height: 35rem;
  }

  div.banner_large_pad {
    padding: 200px 0 100px;
  }

  .banner_content_border {
    padding: 30px;
  }

  .header_wrap.transparent_header + .breadcrumb_section {
    padding-top: 100px;
  }

  .breadcrumb_section {
    padding: 80px 0;
  }

  .highlight_text {
    font-size: 18px;
  }

  .grid_col4 > li {
    width: 33.33%;
  }

  .banner_shape {
    width: 100%;
  }

  .single_banner_info {
    padding: 15px;
    width: 55%;
  }

  .single_bn_title {
    font-size: 22px;
  }

  .price_tage h2 {
    font-size: 34px;
  }

  .single_bn_title1 {
    font-size: 18px;
  }

  .widget_contact_form {
    margin-top: 0;
  }

  .background_shape,
.background_shape::before {
    width: 150px;
  }

  .page-title + span {
    font-size: 15px;
  }

  .padding_eight_all {
    padding: 6%;
  }

  .cs_title {
    line-height: 40px;
    margin-bottom: 15px;
  }

  .cs_logo {
    margin-bottom: 20px;
  }

  .cs_content .countdown_time,
.cs_content .newsletter_form {
    margin-bottom: 20px;
  }

  .contact_info_style2 li i {
    margin-right: 6px;
    font-size: 20px;
  }

  .team_email,
.contact_info_style2 i + * {
    font-size: 16px;
  }

  .social_style3 li a {
    font-size: 18px;
    height: 40px;
    width: 40px;
    line-height: 40px;
  }

  .order_review {
    padding: 20px;
  }

  .product-add-to-cart {
    white-space: nowrap;
  }

  .product-add-to-cart .btn {
    padding: 10px 24px;
  }

  .load_more_wrap {
    margin-top: 0px;
  }

  .header_btn {
    float: right;
    margin: 9px 0;
  }

  .instagram_icon i {
    font-size: 40px;
  }

  .contact_form {
    max-width: 400px;
  }

  #tabmenubar {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 999;
    display: block;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 15px;
    opacity: 0;
    visibility: hidden;
    height: auto !important;
  }

  #tabmenubar.show {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }

  #tabmenubar li a {
    text-align: left;
  }

  .tab-style2 .nav-tabs li.nav-item a {
    padding: 5px 0;
  }

  .icon_box_style1::before {
    content: normal;
  }

  .newsletter_form button {
    padding: 12px 25px;
    font-size: 16px;
  }

  .banner_content_inner {
    padding: 30px;
  }

  .product_search_form {
    max-width: 100%;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -146px;
    z-index: 9;
    right: 0;
    width: 100%;
    padding: 15px 15px;
    background-color: #fff;
  }

  .product_search_form.show {
    display: block;
  }

  .heading_tab_header .navbar-toggler {
    margin: 0;
  }

  #navCatContent {
    position: fixed;
    left: -280px;
    top: 0;
    bottom: 0;
    display: block;
    max-width: 270px;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
  }

  #navCatContent.show {
    left: 0;
  }

  #navCatContent ul li .dropdown-menu {
    left: 0;
    top: auto;
    min-width: auto;
    position: relative;
    box-shadow: none;
  }

  #navCatContent .mega-menu {
    padding: 0;
  }

  .follow_box {
    max-width: 350px;
    padding: 20px;
  }

  .furniture_banner .single_bn_title1 {
    font-size: 64px;
  }

  .countdown_box .countdown {
    font-size: 34px;
  }

  .contact_map2 {
    height: 500px;
  }

  .product-thumbnail img {
    max-width: 80px;
  }

  .contact_support {
    margin: 18px 0;
  }

  .deal_wrap {
    display: block;
    padding: 20px;
  }

  .deal_wrap .product_img {
    margin: 0 auto;
  }

  .deal_content .product_info {
    text-align: center;
  }

  .deal_content {
    padding: 10px 0 0px;
  }

  .newsletter_text::before {
    font-size: 50px;
  }

  .newsletter_text {
    padding-left: 60px;
  }

  .subscribe_popup .modal-dialog {
    max-width: 700px;
  }

  .popup_content {
    padding: 30px;
  }

  .toggle_info {
    margin-bottom: 10px;
    padding: 15px;
  }

  .login_form .panel-body,
.coupon_form .panel-body {
    margin-top: 0;
  }

  .login_form {
    margin-bottom: 10px;
  }
}
/* -------------------------------------------------
      min-width: 768px
    ---------------------------------------------------*/
@media only screen and (min-width: 768px) {
  .blog_thumbs .blog_img {
    float: left;
    width: 40%;
  }

  .blog_thumbs .blog_content {
    padding: 0 0 0 20px;
    overflow: hidden;
  }
}
/* -------------------------------------------------
      max-width: 767px
    ---------------------------------------------------*/
@media only screen and (max-width: 767px) {
  .h1,
h1 {
    font-size: 32px;
  }

  .h2,
h2 {
    font-size: 28px;
  }

  .h3,
h3 {
    font-size: 24px;
  }

  .h4,
h4 {
    font-size: 20px;
  }

  .section,
.newsletter_small {
    padding: 50px 0;
  }

  .section.small_pt,
.footer_top.small_pt {
    padding-top: 25px;
  }

  .section.small_pb,
.footer_top.small_pb {
    padding-bottom: 25px;
  }

  .section.pb_70,
.pb_70 {
    padding-bottom: 20px;
  }

  .section.pb_20,
.pb_20,
.footer_top.pb_20 {
    padding-bottom: 0px;
  }

  .large_divider {
    height: 50px;
  }

  .medium_divider {
    height: 25px;
  }

  .small_divider {
    height: 10px;
  }

  .section.pb_85,
.pb_85 {
    padding-bottom: 35px;
  }

  .sm-height-300 {
    height: 300px !important;
  }

  .banner_content h2 {
    font-size: 36px;
  }

  .full_screen,
.full_screen .carousel-item {
    min-height: 100%;
  }

  .banner_content_border {
    padding: 30px;
  }

  .breadcrumb_section.page-title-mini {
    padding: 50px 0;
  }

  .footer_top {
    padding: 50px 0 20px;
  }

  .grid_col4 > li,
.grid_col3 > li {
    width: 50%;
  }

  .banner_half_content {
    padding: 100px 0 50px;
  }

  .banner_section:not(.full_screen),
.banner_section:not(.full_screen) .carousel-item,
.banner_section:not(.full_screen) .banner_content_wrap,
.banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
    height: 500px;
  }

  .banner_section:not(.full_screen),
.banner_section:not(.full_screen) .carousel-item,
.banner_section:not(.full_screen) .banner_content_wrap,
.banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
    height: 400px;
  }

  .banner_section.slide_medium,
.banner_section.slide_medium .carousel-item,
.banner_section.slide_medium .banner_content_wrap,
.banner_section.slide_medium .banner_content_wrap .carousel-item {
    height: 350px;
  }

  .banner_content1 h2 {
    font-size: 44px;
  }

  .banner_content2 h2 {
    font-size: 40px;
  }

  .carousel-indicators.vertical_center_right {
    right: 25px;
  }

  .newsletter_box {
    padding: 40px;
  }

  .banner_half_content .background_shape {
    left: 55%;
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
  }

  .single_banner_info {
    width: 50%;
  }

  .error_txt {
    font-size: 130px;
  }

  .team_single_content {
    padding-left: 0;
    border-left: 0;
    border-top: 1px solid #eee;
    margin-top: 15px;
    padding-top: 15px;
  }

  #Additional-info table td:first-child {
    width: 35%;
  }

  .product_heaader_right {
    margin-top: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }

  .shop_container.list .product_img {
    float: none;
    width: 100%;
    max-width: 100%;
  }

  .compare_box table {
    width: 900px;
  }

  .row_title {
    width: 200px;
  }

  .shop_cart_table td,
.wishlist_table .table td {
    display: block;
    width: 100%;
    text-align: right;
  }

  .shop_cart_table thead,
.wishlist_table thead {
    display: none;
  }

  .shop_cart_table td::before,
.wishlist_table td::before {
    content: attr(data-title) " ";
    float: left;
    text-transform: capitalize;
    margin-right: 15px;
    font-weight: bold;
  }

  .shop_cart_table td.product-quantity::before {
    margin-top: 10px;
  }

  .shop_cart_table td.product-name,
.wishlist_table td.product-name {
    text-align: right;
  }

  .shop_cart_table td.product-thumbnail,
.shop_cart_table td.product-add-to-cart,
.wishlist_table td.product-thumbnail,
.wishlist_table td.product-add-to-cart {
    text-align: center;
  }

  .shop_cart_table .quantity {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .order_review {
    margin-top: 20px;
  }

  .payment_method {
    margin-bottom: 15px;
  }

  .post_nav span,
.post_nav .nav_meta {
    display: none;
  }

  .blog_thumbs .blog_img {
    float: none;
    width: 100%;
  }

  .single_post .blog_title {
    font-size: 24px;
  }

  blockquote {
    font-size: 18px;
    line-height: 32px;
  }

  blockquote p {
    line-height: 32px;
  }

  .author_img img {
    max-width: 80px;
  }

  .comment_user img {
    max-width: 70px;
  }

  .nav-tabs li.nav-item a {
    padding: 5px 15px;
    font-size: 14px;
  }

  .header_with_topbar.transparent_header + .banner_section .banner_slide_content {
    margin-top: 70px;
  }

  footer .widget_title {
    margin-top: 0px;
  }

  .testimonial_wrp .testimonial_desc {
    margin-top: 10px;
  }

  .contact_form {
    max-width: 100%;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 30px;
    float: none;
  }

  .contact_box {
    padding: 0;
  }

  .contact_box .map {
    position: relative;
  }

  .contact_box .map iframe {
    height: 400px;
  }

  .tab-content {
    margin-top: 20px;
  }

  .trading_img {
    margin-top: 0;
  }

  .header_topbar_info {
    -ms-flex-pack: center;
    justify-content: center;
  }

  .banner_content_inner {
    padding: 0px;
  }

  .shopping_info .icon_box {
    padding-top: 15px;
    margin-bottom: 15px;
  }

  .bottom_footer {
    padding: 15px 0;
  }

  .icon_box_style2 .icon {
    max-width: 68px;
    height: 68px;
    line-height: 68px;
    -ms-flex: 0 0 78px;
    flex: 0 0 68px;
  }

  .icon_box_style2 .icon i {
    font-size: 36px;
    line-height: 68px;
  }

  .nav_style4.owl-theme .owl-nav [class*=owl-] {
    top: -70px;
  }

  .cat_overlap {
    margin-top: -40px;
  }

  .furniture_banner .single_bn_title1 {
    font-size: 52px;
  }

  .furniture_banner .countdown_time .countdown_box {
    padding: 0 5px;
  }

  .furniture_banner .countdown_style3 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .testimonial_wrap {
    padding: 0px;
  }

  .testimonial_style1 .testimonial_desc {
    margin-bottom: 20px;
  }

  .page-title {
    margin-bottom: 15px;
  }

  .blog_thumbs .blog_content {
    padding: 15px 0 0 0;
  }

  .shop_container.list .product_info {
    padding: 15px;
  }

  .mfp-ajax-holder .mfp-content .ajax_quick_view {
    max-width: 600px;
  }

  .deal_wrap {
    padding: 15px;
  }

  .countdown_style4 .countdown_box .countdown-wrap {
    padding: 5px;
  }

  .deal_wrap .countdown_time .cd_text {
    font-size: 12px;
  }

  .newsletter_text {
    margin-bottom: 15px;
  }

  .subscribe_popup .modal-dialog {
    max-width: 95%;
  }

  .popup-text p {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 15px;
  }

  .popup-text .heading_s1 {
    margin-bottom: 15px;
  }

  .popup_content {
    padding: 25px;
  }
}
/* -------------------------------------------------
      max-width: 575px
    ---------------------------------------------------*/
@media only screen and (max-width: 575px) {
  .header_wrap .navbar-collapse {
    left: -15px;
    right: -15px;
  }

  .btn {
    padding: 10px 28px;
    font-size: 14px;
  }

  .btn + .btn {
    margin-left: 5px;
  }

  .btn-group-lg > .btn,
.btn-lg {
    padding: 14px 38px;
    font-size: 18px;
  }

  .btn.btn-lg i {
    font-size: 20px;
  }

  .btn-group-sm > .btn,
.btn-sm {
    padding: 6px 18px;
  }

  .btn-xs {
    padding: 2px 14px;
    font-size: 12px;
  }

  p {
    margin-bottom: 15px;
  }

  .section.pb_70,
.pb_70 {
    padding-bottom: 15px;
  }

  .section,
.newsletter_small {
    padding: 30px 0;
  }

  .section.small_pt,
.footer_top.small_pt {
    padding-top: 15px;
  }

  .section.small_pb,
.footer_top.small_pb {
    padding-bottom: 15px;
  }

  .large_divider {
    height: 30px;
  }

  .medium_divider {
    height: 15px;
  }

  .small_divider {
    height: 5px;
  }

  .section.pb_85,
.pb_85 {
    padding-bottom: 15px;
  }

  .breadcrumb_section {
    padding: 50px 0;
  }

  .breadcrumb_section.page-title-mini {
    padding: 30px 0;
  }

  .footer_top {
    padding: 30px 0 15px;
  }

  .navbar .attr-nav li.nav-btn {
    margin-top: 10px;
  }

  .heading_s1 {
    margin-bottom: 15px;
  }

  .banner_section.slide_medium,
.banner_section.slide_medium .carousel-item,
.banner_section.slide_medium .banner_content_wrap,
.banner_section.slide_medium .banner_content_wrap .carousel-item {
    height: 250px;
  }

  .banner_section:not(.full_screen),
.banner_section:not(.full_screen) .carousel-item,
.banner_section:not(.full_screen) .banner_content_wrap,
.banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
    height: 350px;
  }

  .banner_content h2 {
    font-size: 28px;
  }

  .banner_content1 h2 {
    font-size: 34px;
  }

  .banner_content2 h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .banner_content2 p {
    margin-bottom: 15px;
  }

  .banner_content3 h2 {
    font-size: 30px;
  }

  .banner_half_content {
    padding: 100px 0 30px;
  }

  div.banner_large_pad {
    padding: 200px 0 80px;
  }

  .leads {
    margin-bottom: 20px;
  }

  .grid_filter {
    margin-bottom: 25px;
  }

  .gutter_medium > li .portfolio_item {
    margin-bottom: 15px;
  }

  .icon_box,
.blog_post,
.pricing_box,
.team_box,
.heading_tab_header,
.sale_banner,
.contact_style3,
.product_list .product,
.product_list .product_wrap {
    margin-bottom: 15px;
  }

  .pr_title {
    padding: 20px 15px 20px;
  }

  .pr_footer {
    padding: 15px 20px 20px;
  }

  footer .widget_title {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  footer .widget {
    margin-bottom: 20px;
  }

  .widget_contact_form {
    padding: 20px;
    margin-top: 10px;
  }

  .newsletter_box {
    padding: 30px;
  }

  .map iframe {
    height: 300px;
  }

  footer .background_shape,
footer .background_shape::before {
    width: 120px;
  }

  footer .background_shape {
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
  }

  .error_txt {
    font-size: 100px;
    margin-bottom: 10px;
  }

  .sidebar .widget + .widget {
    margin-top: 20px;
    padding-top: 20px;
  }

  .widget_title {
    margin-bottom: 20px;
  }

  .tab-content.shop_info_tab {
    margin-top: 20px;
  }

  .blog_thumbs .blog_post {
    padding-bottom: 15px;
  }

  .blockquote_style1 {
    margin: 20px 0;
  }

  blockquote {
    font-size: 16px;
    line-height: 28px;
  }

  blockquote p {
    line-height: 28px;
  }

  .blockquote_style4 {
    padding: 15px;
    font-size: 16px;
  }

  .blog_post_footer {
    padding-bottom: 20px;
    padding-top: 5px;
  }

  .post_author {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .comment-area .comment_list {
    margin-bottom: 30px;
  }

  .content_title * {
    margin-bottom: 20px;
  }

  .banner_content_border {
    padding: 20px;
  }

  .testimonial_wrap {
    padding: 0;
  }

  .testimonial_wrap .testimonial_box::before {
    font-size: 40px;
    line-height: normal;
  }

  .instagram_icon i {
    font-size: 30px;
  }

  .testimonial_wrp {
    padding: 20px;
  }

  .contact_form {
    padding: 20px;
  }

  .single_banner {
    margin-bottom: 15px;
  }

  .categories_btn {
    width: auto;
  }

  .categories_btn span {
    display: none;
  }

  .categories_btn i {
    margin-right: 0;
  }

  .indicators_style1 {
    margin-bottom: 5px;
  }

  .indicators_style1 li {
    width: 7px;
    height: 7px;
    margin: 6px;
  }

  .indicators_style2 li {
    width: 6px;
    height: 6px;
  }

  .nav_style3.owl-theme .owl-nav [class*=owl-] {
    top: -66px;
  }

  .follow_box {
    max-width: 310px;
    padding: 15px;
  }

  .follow_box i {
    font-size: 70px;
  }

  .cat_overlap {
    padding: 20px;
  }

  .furniture_banner .single_bn_title1 {
    font-size: 44px;
  }

  .form-control,
.form-control:focus,
.custom_select select {
    height: 44px;
  }

  .product_header {
    display: block;
  }

  .product_header_right {
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 15px;
  }

  .newsletter_form2 input,
.newsletter_form2 input:focus {
    height: 52px;
  }

  .subscribe_popup .modal-dialog {
    max-width: 100%;
  }

  .newsletter_form button {
    padding: 9px 25px;
  }
}
/* -------------------------------------------------
      max-width: 480px
    ---------------------------------------------------*/
@media only screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }

  p {
    line-height: 24px;
  }

  .heading_s1 h1,
.heading_s1 h2,
.heading_s1 h3,
.heading_s1 h4,
.heading_s1 h5,
.heading_s1 h6 {
    line-height: 30px;
  }

  .h1,
h1 {
    font-size: 24px;
  }

  .h2,
h2 {
    font-size: 22px;
  }

  .h3,
h3 {
    font-size: 20px;
  }

  .h4,
h4 {
    font-size: 18px;
  }

  .h5,
h5 {
    font-size: 16px;
  }

  .h6,
h6 {
    font-size: 14px;
  }

  p {
    margin-bottom: 15px;
  }

  .sidebar_menu {
    padding: 30px 20px 20px;
    width: 320px;
    right: -320px;
  }

  .sidebar_left .sidebar_menu,
.sidebar_left_push .sidebar_menu {
    left: -320px;
  }

  .sidebar_left_push.sidetoggle_active {
    left: 320px;
  }

  .sidebar_left_push.sidetoggle_active .header_wrap.nav-fixed {
    left: 320px;
    right: -320px;
  }

  .sidebar_right_push.sidetoggle_active {
    right: 320px;
  }

  .navbar .attr-nav li .nav-link i[class*=linearicons-],
.navbar .navbar-nav.attr-nav li .nav-link.search_trigger [class*=linearicons-],
.navbar .navbar-nav.attr-nav li .nav-link.cart_trigger [class*=linearicons-] {
    font-size: 18px;
  }

  .banner_content1 h2 {
    font-size: 30px;
  }

  .banner_content p {
    margin-bottom: 25px;
  }

  .banner_content2 h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .carousel-control-next,
.carousel-control-prev {
    font-size: 16px;
    height: 38px;
    width: 38px;
  }

  .carousel-control-prev {
    left: 0;
  }

  .carousel-control-next {
    right: 0px;
  }

  .carousel_style1 .carousel-control-next,
.carousel_style2 .carousel-control-next {
    right: 10px;
  }

  .carousel_style1 .carousel-control-prev,
.carousel_style2 .carousel-control-prev {
    left: 10px;
  }

  .heading_s1 footer .widget_title,
.leads {
    margin-bottom: 15px;
  }

  .heading_icon {
    margin-bottom: -10px;
  }

  .highlight_text {
    font-size: 16px;
  }

  .grid_col2 > li,
.grid_col4 > li,
.grid_col3 > li {
    width: 100%;
  }

  .grid_filter li a {
    padding: 5px 10px;
  }

  .grid_filter.filter_style1 li a {
    padding: 5px 15px;
  }

  .product,
.product_wrap,
.product_box {
    margin-bottom: 15px;
  }

  .blog_title {
    margin-bottom: 10px;
  }

  .ripple {
    margin-right: 20px;
    margin-left: -10px;
  }

  .ripple::before,
.ripple::after {
    margin: -15px;
  }

  .newsletter_box {
    padding: 20px;
  }

  .newsletter_form input,
.newsletter_form input:focus {
    padding: 10px 140px 10px 20px;
  }

  .newsletter_form input.form-control-sm {
    padding: 10px 130px 10px 18px;
  }

  .newsletter_wrap {
    margin: 15px 0;
  }

  .newsletter_wrap::after {
    content: normal;
  }

  .profile_info > li {
    margin-bottom: 15px;
  }

  .count_pr {
    font-size: 14px;
  }

  .contact_icon {
    margin-right: 15px;
  }

  .contact_style3 {
    padding: 30px 20px;
  }

  .contact_style3 .contact_icon {
    height: 70px;
    width: 70px;
  }

  .contact_style3 .contact_icon i {
    line-height: 68px;
  }

  .contact_icon i {
    font-size: 30px;
  }

  .divider i {
    font-size: 26px;
    padding: 0 25px;
  }

  .page-title-mini .page-title h1 {
    font-size: 24px;
  }

  .countdown_box .countdown {
    font-size: 30px;
  }

  .cart_extra {
    display: block;
  }

  .pr_detail .rating_wrap {
    float: none;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .pr_detail .product_price {
    float: none;
  }

  .mfp-ajax-holder .ajax_quick_view {
    padding: 30px 20px;
  }

  .ajax_quick_view .social_icons li a {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }

  .ajax_quick_view .product_share > span {
    margin-right: 10px;
  }

  .shop_container.list .product .rating_wrap,
.shop_container.list .product_price {
    float: none;
  }

  .shop_container.list .product .rating_wrap {
    margin-bottom: 5px;
  }

  .shop_container.list .product .product_title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  #Additional-info table td:first-child {
    width: auto;
  }

  .comment_img img {
    max-width: 50px;
  }

  .comment_block {
    padding-left: 65px;
  }

  .compare_box {
    width: 95%;
    padding: 30px 10px 10px;
  }

  .order_complete i {
    font-size: 60px;
    margin-bottom: 10px;
  }

  .empty_icon {
    height: 70px;
    width: 70px;
    line-height: 70px;
    margin-bottom: 15px;
  }

  .empty_icon img {
    max-width: 35px;
  }

  .single_post .blog_title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .post_author .author_img {
    float: none;
    padding-right: 0;
    margin: 0 auto;
    display: table;
  }

  .author_info {
    text-align: center;
    margin-top: 15px;
  }

  .comment_user img {
    max-width: 60px;
  }

  .comment_content {
    padding-left: 15px;
  }

  .children {
    margin-left: 20px;
  }

  .process_step_content {
    margin-top: 20px;
  }

  .pr_list li {
    padding: 10px 0;
  }

  .pr_icon {
    width: 80px;
    height: 80px;
  }

  .pr_icon i {
    font-size: 40px;
    line-height: 80px;
  }

  .price_tage {
    padding-top: 5px;
  }

  .contact_box .map iframe {
    height: 300px;
  }

  .single_bn_title {
    font-size: 20px;
  }

  .single_banner_info {
    width: 55%;
  }

  .product_search_form select {
    width: 120px;
    padding: 8px 25px 8px 5px;
    overflow: hidden;
  }

  .search_btn {
    padding: 0px 10px;
    font-size: 18px;
  }

  .deal_timer::before {
    font-size: 14px;
  }

  .deal_timer {
    padding-left: 8px;
  }

  .deal_timer .countdown_style1 .countdown_box::before {
    font-size: 18px;
  }

  .deal_timer .countdown_box .countdown {
    font-size: 18px;
  }

  .follow_box {
    max-width: 250px;
  }

  .follow_box i {
    font-size: 60px;
  }

  .bg_strip {
    font-size: 14px;
    height: 34px;
  }

  .bg_strip::before,
.bg_strip::after {
    border-width: 17px;
  }

  .categories_box a {
    padding: 25px 15px;
  }

  .furniture_banner .single_bn_title1 {
    font-size: 34px;
  }

  .countdown_style3 .cd_text {
    font-size: 12px;
  }

  .countdown_style3 .countdown-wrap {
    padding: 10px;
  }

  .shop_container.list .pr_desc {
    min-height: 50px;
    max-height: 50px;
  }

  .shop_container.list .pr_action_btn li.add-to-cart a {
    padding: 12px 20px;
  }

  .tab-style3 .nav-tabs {
    display: block;
  }

  .shop_container .col-6 {
    max-width: 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .mfp-ajax-holder .mfp-content .ajax_quick_view {
    padding: 30px 15px 15px;
  }

  .cart_trigger .amount {
    display: none;
  }

  .popup_content {
    padding: 40px 20px;
  }

  .popup-text .heading_s1 {
    margin-bottom: 10px;
  }

  .popup-text .heading_s1 * {
    line-height: normal;
  }

  .login_form .panel-body,
.coupon_form .panel-body {
    padding: 15px;
  }

  .custome-radio input[type=radio] + .form-check-label::after {
    top: 7px;
  }
}
/* -------------------------------------------------
      max-width: 380px
    ---------------------------------------------------*/
@media only screen and (max-width: 380px) {
  .btn {
    padding: 8px 24px;
  }

  .btn-group-lg > .btn,
.btn-lg {
    padding: 12px 34px;
    font-size: 16px;
  }

  .btn.btn-lg i {
    font-size: 18px;
  }

  .btn-group-sm > .btn,
.btn-sm {
    padding: 4px 16px;
  }

  .btn-xs {
    padding: 2px 14px;
    font-size: 12px;
  }

  .sidebar_menu {
    padding: 30px 15px 15px;
    width: 290px;
    right: -290px;
  }

  .sidebar_left .sidebar_menu,
.sidebar_left_push .sidebar_menu {
    left: -290px;
  }

  .sidebar_left_push.sidetoggle_active {
    left: 290px;
  }

  .sidebar_left_push.sidetoggle_active .header_wrap.nav-fixed {
    left: 290px;
    right: -290px;
  }

  .sidebar_right_push.sidetoggle_active {
    right: 290px;
  }

  .navbar-brand img {
    max-width: 140px;
  }

  .fb_style1::before {
    width: 220px;
  }

  .banner_content h2 {
    margin-bottom: 15px;
  }

  .banner_content p {
    margin-bottom: 20px;
  }

  .newsletter_form input,
.newsletter_form input:focus {
    height: 44px;
  }

  .newsletter_form button {
    padding: 10px 15px;
    font-size: 14px;
  }

  .first_img,
.second_img {
    max-width: 230px;
  }

  .ripple {
    line-height: 32px;
    height: 32px;
    width: 32px;
  }

  .ripple i {
    font-size: 14px;
  }

  .header_wrap.transparent_header.header_with_topbar + .breadcrumb_section {
    padding-top: 220px;
  }

  .form-control,
.form-control:focus,
.custom_select select {
    height: 50px;
  }

  .form-control {
    font-size: 14px;
  }

  .input_icon,
.rounded_input .input_icon {
    top: 10px;
  }

  .custom-file-label,
.custom-file,
.custom-file-input {
    height: 40px;
    line-height: 28px;
  }

  .custom-file-label::after {
    height: 38px;
    line-height: 30px;
  }

  .error_txt {
    font-size: 80px;
  }

  .btn-login li a {
    padding: 8px 16px;
  }

  .login_footer a {
    font-size: 13px;
  }

  .countdown_box .countdown {
    font-size: 26px;
  }

  .cs_title {
    line-height: 28px;
    font-size: 16px;
  }

  .shop_container.list .list_product_action_box .pr_action_btn li.add-to-cart a {
    padding: 10px 17px;
    font-size: 14px;
  }

  .shop_container.list .list_product_action_box .pr_action_btn li a {
    width: 27px;
    height: 27px;
  }

  .shop_container.list .pr_action_btn li a i {
    line-height: 27px;
    font-size: 16px;
  }

  .order_complete i {
    font-size: 50px;
  }

  .comment_user img {
    max-width: 50px;
  }

  .heading_s2 .sub_heading {
    font-size: 30px;
    line-height: 30px;
  }

  .ddcommon .ddTitleText {
    padding: 5px 5px 5px 5px;
  }

  .contact_detail > li,
.header_list > li {
    padding: 2px 10px 2px 0;
  }

  .navbar-nav .dropdown-menu.cart_box.show {
    right: -50px;
    width: 310px;
  }

  .navbar-nav .dropdown-menu.cart_box.show.cart_right {
    right: -10px;
  }

  .post_content {
    margin-top: 0;
  }

  .contact_phone i {
    font-size: 20px;
    margin-right: 5px;
  }

  .categories_btn {
    padding: 20px 10px;
  }

  .fb_info {
    padding: 0px;
  }

  .fb_info2 {
    left: 0;
    width: 50%;
  }

  .newsletter_form .btn-send {
    font-size: 18px;
    width: 36px;
    height: 36px;
    line-height: 36px;
  }

  .product_search_form.rounded_input input {
    padding: 10px 15px;
  }

  .contact_support {
    margin: 20px 0;
  }

  .newsletter_form2 input,
.newsletter_form2 input:focus {
    height: 48px;
  }
}