.pagination .page-item a {
	color: #0E93D8;
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	padding: 0px;
	z-index: 1;
}
.page-link:focus {
	box-shadow: none;
}
.pagination_style1 li,
.pagination_style2 li,
.pagination_style3 li,
.pagination_style4 li {
	margin-right: 5px;
}
.pagination_style1 .page-item a {
	border: 2px solid #dee2e6;
	border-radius: 0 !important;
	color: #687188;
	margin-left: 0px;
	line-height: 36px;
}
.pagination_style1 .page-item a i {
	line-height: 36px;
}
.pagination_style1 .page-item.disabled .page-link,
.pagination_style3 .page-item.disabled .page-link {
	color: #c6c6c6;
	pointer-events: none;
	background-color: #fff;
	border-color: #f4f4f4;
}
.pagination_style1 .page-item.active .page-link, 
.pagination_style1 .page-item .page-link:hover {
	background-color: #FF324D;
	border-color: #FF324D;
	color: #fff;
}