

.nav-tabs li.nav-item a {
	background-color: transparent;
	border: 0;
	font-weight: 500;
	text-align: center;
	text-transform: capitalize;
	padding: 5px 20px;
}
.tab-content {
	margin-top: 25px;
}
.tab-style1 .nav-tabs,
.tab-style2 .nav-tabs {
	border-bottom: 0;
}
.tab-style1 .nav-tabs li.nav-item a.active, .tab-style1 .nav-tabs li.nav-item a.active:hover,
.tab-style2 .nav-tabs li.nav-item a.active, .tab-style2 .nav-tabs li.nav-item a.active:hover {
	color: #FF324D;
}
.tab-style2 .nav-tabs li.nav-item a {
	padding: 0px 15px;
}
.tab-style2 .nav-tabs li.nav-item:last-child a {
	padding-right: 0;
}
.tab-style3 .nav-tabs li.nav-item a {
	background-color: transparent;
	display: block;
	padding: .5rem 1rem;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 2px solid rgba(0,0,0,0);
	text-align: center;
	text-transform: uppercase;
}
.tab-style3 .nav-tabs .nav-item a.active {
	border-bottom-color: #FF324D;
	color: #FF324D;
}
.tab_slider > .tab-pane {
	display: block;
	height: 0;
}
.tab_slider .tab-pane.active {
	height: auto;
	display: block;
	-webkit-animation-name: moveUp;
	animation-name: moveUp;
	-webkit-animation-duration: .5s;
	animation-duration: .5s;
	-webkit-animation-timing-function: cubic-bezier(.26,.69,.37,.96);
	animation-timing-function: cubic-bezier(.26,.69,.37,.96);
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
@-webkit-keyframes moveUp {
	0% {
		opacity:0;
		-webkit-transform:translateY(25px);
		transform:translateY(25px)
	}
	100% {
		opacity:1;
		-webkit-transform:translateY(0);
		transform:translateY(0)
	}
}
@keyframes moveUp {
	0% {
		opacity:0;
		-webkit-transform:translateY(25px);
		transform:translateY(25px)
	}
	100% {
		opacity:1;
		-webkit-transform:translateY(0);
		transform:translateY(0)
	}
}
.heading_tab_header {
	padding-bottom: 0;
	border-bottom: 1px solid #ddd;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
    align-items: center;
	width: 100%;
	margin-bottom: 10px;
	position: relative;
	
}
.heading_tab_header [class*="heading_"] {
	float: left;
}
