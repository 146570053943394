


.dashboard_menu {
	box-shadow: 0 0px 4px 0 #e9e9e9;
}
.dashboard_menu .nav-tabs li.nav-item a {
	text-align: left;
	padding: 12px 20px;
	border-radius: 0;
	border-bottom: 1px solid #efefef;
	color: #2b2f4c;
}
.dashboard_menu .nav-tabs li.nav-item a.active {
	background-color: $red_c;
	color: #fff;
}
.dashboard_menu .nav-tabs li.nav-item a i {
	margin-right: 8px;
	vertical-align: middle;
}
.dashboard_content {
	margin: 0;
}
.dashboard_content .card-header {
	background-color: transparent;
	border-color: #f0f0f0;
}
.dashboard_content .card-header * {
	margin: 0;
}
.dashboard_content .card {
	border-radius: 0;
	border: 0;
	box-shadow: 0 0px 4px 0 #e9e9e9;
}
.dashboard_content .card-body p:last-child {
	margin: 0;
}
.dashboard_content .table {
	margin: 0;
}
.dashboard_content .table thead th {
	border: 0;
	padding-top: 0;
	font-weight: 600;
}
.dashboard_content .table tbody tr:last-child td {
	padding-bottom: 0;
}
.dashboard_content .table tbody tr td {
	vertical-align: middle;
	white-space: nowrap;
}
.dashboard_content label {
	color: #333;
}
.dashboard_content label .required {
	color: #ff0000;
}