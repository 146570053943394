.sale-banner__text {
  background-color: rgba($blue_c, 0.8);
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  position: absolute;
  left: 0 ;
  height: 70%;
  top: 15%;
  display: flex;
  justify-content:center;
  align-items:center;
  width: 50%;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all .35s ease-in-out;;
}
.borderd-sale-banner{

    a{
        position: relative;
        display: block;
        &:hover,
        &:focus{
            .sale-banner__text{
                width: 80%;
            } 
        }
        img{
            border: 20px solid #fff;
        }
    }
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.12);
}