
.btn:focus,.btn:hover,.btn.active {
	box-shadow: none;
	outline: medium none;
}
button:focus {
	outline:none;
}
.border-2 {
	border-width: 2px !important;
}
.btn {
	border-width: 1px;
	cursor: pointer;
	line-height: normal;
	padding: 12px 35px;
	text-transform: capitalize;
	transition: all 0.3s ease-in-out;
}
.btn.active:focus, .btn:active:focus {
	box-shadow: none !important;
}
.btn-fill-out {
	background-color: transparent;
	border: 1px solid $red_c;
	color: #fff;
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.btn-fill-out::before,
.btn-fill-out::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: $red_c;
	z-index: -1;
	transition: all 0.3s ease-in-out;
	width: 51%;
}
.btn-fill-out::after {
	right: 0;
	left: auto;
}
.btn-fill-out:hover:before,
.btn-fill-out:hover:after {
	width: 0;
}
.btn-fill-out:hover {
	color: $red_c !important;
}
.btn-fill-line {
	background-color: transparent;
	border: 1px solid #333;
	overflow: hidden;
	position: relative;
	color: #fff !important;
	transition: all 0.8s ease 0s;
	z-index: 1;
}
.btn-fill-line:before, .btn-fill-line:after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	bottom: 0;
	background-color: #333;
	z-index: -1;
	transition: all 0.3s ease-in-out;
	width: 50%;
}
.btn-fill-line::after {
	right: 0;
	left: auto;
}
.btn-fill-line:hover:before,
.btn-fill-line:hover:after {
	width: 0;
}
.btn-fill-line:hover {
	color: #333 !important;
}
.btn-border-fill {
	border: 1px solid $red_c;
	color: $red_c;
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.btn-border-fill::before,
.btn-border-fill::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: $red_c;
	z-index: -1;
	transition: all 0.3s ease-in-out;
	width: 0;
}
.btn-border-fill::after {
	right: 0;
	left: auto;
}
.btn-border-fill:hover:before,
.btn-border-fill:hover:after {
	width: 50%;
}
.btn-border-fill:hover {
	color: #fff !important;
}
.btn-white {
	background-color: transparent;
	border: 1px solid #fff;
	color: #292b2c !important;
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.btn-white::before,
.btn-white::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: #fff;
	z-index: -1;
	transition: all 0.5s ease-in-out;
	width: 50%;
}
.btn-white::after {
	right: 0;
	left: auto;
}
.btn-white:hover:before,
.btn-white:hover:after {
	width: 0;
}
.btn-white:hover {
	color: #fff !important;
}
.btn-fill-out-dark {
	background-color: transparent;
	border: 1px solid #333;
	color: #fff !important;
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.btn-fill-out-dark:hover {
	color: #333 !important;
}
.btn-fill-out-dark::before {
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	z-index: -1;
	content: '';
	background-color: #333;
	transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.btn-line-fill {
	border: 1px solid #333;
	overflow: hidden;
	position: relative;
	color: #333 !important;
	transition: all 0.8s ease 0s;
	z-index: 1;
}
.btn-line-fill:hover {
	color: #fff !important;
}
@keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: 450px;
    height: 450px;
  }
}
@keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: 450px;
    height: 450px;
  }
}
.btn-line-fill:before, .btn-line-fill:after {
	position: absolute;
	top: 50%;
	content: '';
	width: 20px;
	height: 20px;
	background-color: #333;
	border-radius: 50%;
	z-index: -1;
}
.btn-line-fill:before {
	left: -20px;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.btn-line-fill:after {
	right: -20px;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
}
.btn-line-fill:hover:before {
	-webkit-animation: criss-cross-left 0.7s both;
	animation: criss-cross-left 0.7s both;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
}
.btn-line-fill:hover:after {
	-webkit-animation: criss-cross-right 0.7s both;
	animation: criss-cross-right 0.7s both;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
}
.btn-line-white {
	border: 1px solid #fff;
	color: #fff !important;
}
.btn-line-white:hover {
	color: #333 !important;
}
.btn-line-fill.btn-line-white:before, .btn-line-fill.btn-line-white:after {
	background-color: #fff;
}
.btn-tran-light {
	background-color: rgba(255,255,255,0.2);
	color: #fff;
}
.btn-tran-light:hover {
	background-color: #fff;
}
.btn-radius {
	border-radius: 40px;
}
.btn + .btn {
	margin-left: 10px;
}
.btn i {
	font-size: 16px;
	margin-right: 5px;
	vertical-align: middle;
	line-height: 1;
}
.btn span {
	vertical-align: middle;
}
.btn-group-sm > .btn, .btn-sm {
    padding: 8px 25px;
}
.btn-group-lg > .btn, .btn-lg {
    padding: 16px 45px;
}
.btn-xs {
    padding: 4px 20px;
    font-size: 12px;
}
.btn-md {
    padding: 12px 35px;
    font-size: 16px;
}
.btn.btn-xs i {
    font-size: 10px;
}
.btn.btn-sm i {
    font-size: 12px;
}
.btn.btn-lg i {
    font-size: 22px;
}	
.btn-ripple {
	padding-right: 0 !important;
	border: 0;
}
.btn-link {
	position: relative;
	z-index: 2;
	text-decoration: none;
}
.btn-link:hover {
	text-decoration: none;
}
.btn-link::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0px;
	height: 1px;
	background-color: $red_c;
	z-index: -1;
}