
blockquote {
	font-size: 20px;
	line-height: 34px;
}
blockquote p {
	line-height: 34px;
}
blockquote p:last-child {
	margin: 0;
}
.blockquote_style1 {
	padding-left: 20px;
	border-left: 3px solid #ddd;
	margin: 30px 0;
}
.blockquote_style1 p {
	color: #292B2C;
}
.blockquote_style2 {
	background-color: #FF324D;
	padding: 20px;
	position: relative;
}
.blockquote_style2 * {
	color: #fff;
	font-size: 16px;
	line-height: 28px;
}
.blockquote_style2::before {
	content: "\f10e";
	position: absolute;
	left: 20px;
	top: 10px;
	color: #fff;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 70px;
	opacity: 0.3;
	line-height: normal;
}
.blockquote_style3 {
	background-color: #FAFAFA;
	padding: 20px;
	border-left: 2px solid #FF324D;
	margin-bottom: 25px;
	font-size: 16px;
	line-height: 28px;
	margin-top: 5px;
	display: inline-block;
	width: 100%;
	position: relative;
}
.blockquote_style3::before {
	content: "\f10e";
	position: absolute;
	left: 20px;
	top: 10px;
	color: #000;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 70px;
	opacity: 0.05;
	line-height: normal;
	pointer-events: none;
}
.blockquote_style3 p {
	line-height: 28px;
}
.blockquote_style4 {
	text-align: center;
	padding: 20px;
	background-color: #f7f7f7;
	margin-bottom: 20px;
	font-size: 18px;
	position: relative;
}
.blockquote_style4::before {
	content: "\f10d";
	position: absolute;
	left: 50px;
	top: 10px;
	color: #000;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 70px;
	opacity: 0.05;
	line-height: normal;
}
.blockquote_style4,
.blockquote_style4 p {
	color: #222;
}
.blog_post_footer {
	padding-bottom: 30px;
	padding-top: 15px;
}
.mdtp__wrapper {
	bottom: auto;
	top: 50%;
	-moz-transform: translateX(-50%) translateY(-50%) scale(1);
	-webkit-transform: translateX(-50%) translateY(-50%) scale(1);
	transform: translateX(-50%) translateY(-50%) scale(1);
}
.modal {
	padding-right: 0 !important;
}
.page_soon {
	font-size: 10px;
	color: #fff;
	padding: 0px 5px;
	border-radius: 20px;
	background-color: #28A745;
	text-transform: uppercase;
	font-weight: 600;
}
.alert .close {
	font-weight: normal;
	margin-left: 10px;
}	
.alert_style1 {
	border-radius: 0;
	border-left: 3px solid;
	padding: 20px;
	position: relative;
	border-top: 0;
	border-bottom: 0;
	border-right: 0;
}
.alert > i {
	margin-right: 5px;
	font-size: 18px;
	vertical-align: middle;
}
.alert_style2 {
	border-radius: 20px;
	padding: 20px 20px;
	background-color: #fff;
	border-left: 3px solid;
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	border-top: 0;
	border-bottom: 0;
	border-right: 3px solid;;
}
.button_group .btn {
	margin-bottom: 20px;
}
.trading_img {
	margin-top: -40px;
}
.term_conditions h6 {
	margin-bottom: 15px;
}
.term_conditions ul,
.term_conditions ol {
	padding-left: 15px;
}
.term_conditions p,
.term_conditions li {
	font-size: 14px;
	margin-bottom: 15px;
	line-height: 28px;
}