.login_wrap {
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.login_register_wrap {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
}
.login_footer {
	margin-bottom: 20px;
	margin-top: 5px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 100%;
}
.login_footer a {
	color: #687188;
}
.different_login {
	text-align: center;
	position: relative;
	margin: 20px 0;
}
.different_login span {
	background-color: #fff;
	padding: 0 15px;
	position: relative;
	text-transform: uppercase;
}
.different_login::before {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	right: 0;
	border-top: 1px solid #ddd;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.btn-login li {
	margin: 0px 5px 0;
	display: inline-block;
}
.btn-login li a {
	border-radius: 5px;
	padding: 10px 20px;
	color: #fff;
	display: block;
}
.btn-login li a i {
	margin-right: 10px;
	font-size: 18px;
	margin-left: 0;
}
.btn-facebook {
	background: #3b5998;
	border-color: #3b5998;
}
.btn-facebook:hover {
	color: #fff;
	background: #344e86;
	border-color: #344e86;
}
.btn-google {
	background: #d85040;
	border-color: #d85040;
}
.btn-google:hover {
	color: #fff;
	background: #d33d2b;
	border-color: #d33d2b;
}