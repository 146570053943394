.heading_s1 {
  margin-bottom: 25px;
}
.heading_s1 h1,
.heading_s1 h2,
.heading_s1 h3,
.heading_s1 h4,
.heading_s1 h5,
.heading_s1 h6 {
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
}
.heading_s2 {
  position: relative;
}
.heading_s2 h1,
.heading_s2 h2,
.heading_s2 h3,
.heading_s2 h4,
.heading_s2 h5,
.heading_s2 h6,
.heading_s3 h1,
.heading_s3 h2,
.heading_s3 h3,
.heading_s3 h4,
.heading_s3 h5,
.heading_s3 h6 {
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
}
.heading_s2 h1,
.heading_s2 h2,
.heading_s2 h3,
.heading_s2 h4,
.heading_s2 h5,
.heading_s2 h6 {
  position: relative;
  z-index: 9;
}
.heading_s2 .sub_heading {
  font-size: 100px;
  font-weight: bold;
  padding: 0;
  margin-bottom: -15px;
  z-index: 0;
  position: relative;
  line-height: 100px;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#1affffff',GradientType=0 );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.heading_s2.heading_light .sub_heading {
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(0, 0, 0, 0.01) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(0, 0, 0, 0.01) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(0, 0, 0, 0.01) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4dffffff', endColorstr='#1a000000',GradientType=0 );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.heading_s3 {
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}
.heading_s3::before {
  content: "";
  position: absolute;
  left: 0;
  width: 50px;
  height: 1px;
  background-color: #ff324d;
  bottom: 0;
  right: 0;
}
.heading_light.heading_s3::before {
  background-color: #fff;
}
.heading_s3.text-center::before {
  margin: 0 auto;
}
.heading_s3.text-right::before {
  left: auto;
  right: 0;
}
.heading_s4 {
  margin-bottom: 15px;
}
.heading_s4 h1,
.heading_s4 h2,
.heading_s4 h3,
.heading_s4 h4,
.heading_s4 h5,
.heading_s4 h6 {
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
  line-height: normal;
}
.heading_s4 .heading_icon i {
  color: #20e076;
  font-size: 30px;
  position: relative;
  z-index: 3;
  width: 50px;
  display: block;
  height: 50px;
  line-height: 50px;
}
.heading_icon {
  display: inline-block;
  position: relative;
  z-index: 2;
  padding: 0 30px;
  text-align: center;
}
.heading_icon::after {
  content: "";
  position: absolute;
  right: 0;
  width: 30px;
  top: 50%;
  border-top: 1px solid #999;
  z-index: -1;
  border-bottom: 1px solid #999;
  height: 5px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.heading_icon::before {
  content: "";
  position: absolute;
  left: 0;
  width: 30px;
  top: 50%;
  border-top: 1px solid #999;
  z-index: -1;
  border-bottom: 1px solid #999;
  height: 5px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.heading_light .heading_icon i {
  color: #fff !important;
}
.heading_uppercase h1,
.heading_uppercase h2,
.heading_uppercase h3,
.heading_uppercase h4,
.heading_uppercase h5,
.heading_uppercase h6 {
  text-transform: uppercase;
}
.heading_light * {
  color: #fff;
}
.sub_heading {
  color: #ff324d;
  margin-bottom: 10px;
  display: block;
}
.highlight_text {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  margin-bottom: 15px;
  display: block;
}
.leads {
  margin-bottom: 30px;
}

.heading_bg {
  display: block;
  background-color: $blue_c;
  color: #fff;
  font-weight: 500;
  padding: 5px 25px;
  h1, h2, h3, h4, h5, h6{
    font-weight: 500;
    font-size: 18px;
    color: #fff;
  }
}
a.heading_bg__link {
  display: block;
  background-color: $blue_c;
  color: #fff;
  font-weight: 500;
  padding: 5px 25px;
  &:hover,
  &:focus {
    background-color: $red_c;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    font-size: 18px;
    color: #fff;
  }
}
