.form-control {
  color: #000;
  box-shadow: none;
  height: 50px;
  padding: 8px 15px;
}
.form-control:focus,
.custom-file-input:focus ~ .custom-file-label {
  color: #000;
  box-shadow: none;
  height: 50px;
}
textarea.form-control,
textarea.form-control:focus {
  height: auto;
  padding: 12px 15px;
}
.form-control-sm,
.form-control-sm:focus,
.custom_select select.form-control-sm,
.custom_select select.form-control-sm:focus {
  height: 45px;
}
select.not_chosen,
select.first_null option:first-child,
select.not_chosen:focus,
select.form-control.not_chosen,
select.form-control.first_null option:first-child,
select.not_chosen.form-control:focus {
  color: #6c757d;
}
select option {
  color: #000;
}
.custom_select {
  position: relative;
}
.custom_select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #ced4da;
  padding: 8px 35px 8px 15px;
  width: 100%;
  height: 50px;
}
.custom_select::before {
  content: "\e64b";
  font-family: "themify";
  position: absolute;
  display: block;
  color: #848484;
  top: 50%;
  right: 15px;
  font-weight: 900;
  pointer-events: none;
  font-size: 12px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.custom-file-label::after {
  height: 48px;
  border: 0;
  line-height: 40px;
  border-radius: 0;
}
.custom-file-label,
.custom-file,
.custom-file-input {
  height: 50px;
  line-height: 38px;
}
.rounded_input .form-control,
.rounded_input input {
  border-radius: 30px;
  padding: 10px 20px;
}
.rounded_input .custom_select select {
  padding-right: 40px;
  font-size: 13px;
}
.rounded_input .custom_select::before {
  right: 20px;
}
.rounded_input textarea.form-control,
.rounded_input textarea.form-control:focus {
  padding: 15px 20px;
}
.input_group {
  position: relative;
}
.input_icon {
  position: absolute;
  right: 20px;
  top: 14px;
  pointer-events: none;
  color: #848484;
}
.rounded_input .input_icon {
  top: 12px;
}

.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
  color: #687188;
  padding: 0;
  vertical-align: middle;
}
.custome-checkbox .form-check-input {
  display: none;
}
.custome-checkbox .form-check-label span {
  vertical-align: middle;
}
.custome-checkbox .form-check-label::before {
  content: "";
  border: 2px solid #ced4da;
  height: 17px;
  width: 17px;
  margin: 0px 8px 0 0;
  display: inline-block;
  vertical-align: middle;
}
.custome-checkbox input[type="checkbox"]:checked + .form-check-label::after {
  opacity: 1;
}
.custome-checkbox input[type="checkbox"] + .form-check-label::after {
  content: "";
  width: 11px;
  position: absolute;
  top: 50%;
  left: 3px;
  opacity: 0;
  height: 6px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -moz-transform: translateY(-65%) rotate(-45deg);
  -webkit-transform: translateY(-65%) rotate(-45deg);
  transform: translateY(-65%) rotate(-45deg);
}
.custome-checkbox input[type="checkbox"]:checked + .form-check-label::before {
  background-color: $red_c;
  border-color: $red_c;
}
.custome-radio .form-check-input,
.custome-checkbox .form-check-input {
  display: none;
}
.custome-radio .form-check-label::before {
  content: "";
  border: 1px solid #908f8f;
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 8px;
}
.custome-radio input[type="radio"] + .form-check-label::after {
  content: "";
  background-color: $red_c;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  left: 3px;
  opacity: 0;
}
.custome-radio .form-check-label,
.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
}
.custome-radio input[type="radio"]:checked + .form-check-label::before {
  border-color: $red_c;
}
.custome-radio input[type="radio"]:checked + .form-check-label::after {
  opacity: 1;
}
.custome-checkbox input[disabled] + .form-check-label,
.custome-radio input[disabled] + .form-check-label {
  color: #d0d0d0;
}
.custome-checkbox input[disabled] + .form-check-label::before,
.custome-radio input[disabled] + .form-check-label::before {
  border-color: #e8e8e8;
}
