/*Loader Css*/
.preloader {
	background-color: #fff;  
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
}
.lds-ellipsis {
	margin: 0 auto;
	position: relative;
	top: 50%;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 64px;
	text-align: center;
	z-index: 9999;
}
.lds-ellipsis span {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #FF324D;
	-webkit-animation: ball-pulse-sync .6s 0s infinite ease-in-out;
	animation: ball-pulse-sync .6s 0s infinite ease-in-out;
}
.lds-ellipsis span:nth-child(1) {
	-webkit-animation:ball-pulse-sync .6s -.14s infinite ease-in-out;
	animation:ball-pulse-sync .6s -.14s infinite ease-in-out
}
.lds-ellipsis span:nth-child(2) {
	-webkit-animation:ball-pulse-sync .6s -70ms infinite ease-in-out;
	animation:ball-pulse-sync .6s -70ms infinite ease-in-out
}
@-webkit-keyframes ball-pulse-sync {
	33% {
		-webkit-transform:translateY(10px);
		transform:translateY(10px)
 }
	66% {
		-webkit-transform:translateY(-10px);
		transform:translateY(-10px)
	}
	100% {
		-webkit-transform:translateY(0);
		transform:translateY(0)
	}
}
@keyframes ball-pulse-sync {
	33% {
		-webkit-transform:translateY(10px);
		transform:translateY(10px)
	}
	66% {
		-webkit-transform:translateY(-10px);
		transform:translateY(-10px)
	}
	100% {
		-webkit-transform:translateY(0);
		transform:translateY(0)
	}
}
.custom-container {
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.mfp-preloader {
	color: #ccc;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 24px;
	height: 24px;
	background: url("../../img/loading.gif") center center no-repeat;
	text-align: center;
	margin-top: -12px;
	margin-left: -12px;
	z-index: 1044;
	text-indent: -9999px;
	background-color: #000;
	padding: 25px;
	border-radius: 5px;
}